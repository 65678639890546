@import "variables";
@import "mixins";

.welcome-tour-container {
  //width: fit-content;
  background-color: $white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba($grey, 0.3);

  & > div {
    &:nth-child(1) {
      width: 46%;
      img {
        width: 100%;
        height: auto;
      }
      @include mQ(768px) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 54%;
      padding: 50px;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 0.2fr 0.6fr 0.2fr;
      gap: 35px;
      @include mQ(768px) {
        width: 100%;
      }
      .slide-count {
        color: $green;
        font-size: $step-1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        button {
          width: fit-content;
          min-width: 150px;
          padding: 5px 30px;
          &:focus {
            outline: none;
          }
          &.skip {
            background: none;
            color: $green;
            margin-right: 15px;
            &:hover {
              background-color: rgba($green, 0.1);
            }
          }
        }
      }
    }
  }
}
