// Core variables and mixins
@import "variables";
@import "mixins";
// Global CSS
@import "global";
// Components
@import "masthead";
// @import "icons";
@import "showcase";
@import "tracking-page";
@import "testimonials";

.landing {
  font-size: 16px;
  background-color: $white;
  scroll-behavior: smooth;
  transition: top 300ms;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $dark-blue-2;
  }
  h1 {
    font-weight: bold;
    font-size: $step-5;
  }
  h2 {
    font-weight: bold;
    font-size: $step-4;
  }
  h3 {
    font-weight: bold;
    font-size: $step-3;
  }
  h4 {
    font-weight: 500;
    font-size: $step-2;
  }
  h5 {
    font-weight: 500;
    font-size: $step-1;
  }
  p {
    font-size: $step-0;
    color: $dark-blue-2;
    &.bold {
      font-weight: 600;
      color: $dark-blue-2;
    }
    &.info {
      color: rgba($dark-blue-2, 0.56);
    }
  }
  button {
    border-radius: 10px;
  }

  .dots {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $light-grey;
      transition: all 200ms;
      &.active {
        background-color: $green;
      }
    }
  }

  .marquee-banner {
    width: 100%;
    overflow: hidden;
    padding: 0px 35px;
    background-color: $dark-blue-2;

    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    p {
      padding: 10px 0px;
      margin-right: 20px;
      margin-bottom: 0px;
      color: $white;
      font-size: 14px;
    }
    .overlay {
    }
  }
  .review {
    svg {
      margin-right: 1px;
      width: 19px;
      height: auto;
      path {
        width: 19px;
        height: auto;
      }
    }
  }
  .scrollTop {
    position: fixed;
    bottom: 30px;
    right: 180px;
    z-index: 3000;
    background-color: $dark-green;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 10px;
    box-shadow: 0 0 15px -5px $blue;
    animation: fadeIn 300ms;
    &:hover {
      background-color: $hover-green;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }
  // overflow-y: scroll;
  .padded {
    padding: 50px 0;
    @include mQ-min(1200px) {
      padding: 100px 0;
    }
  }

  section.partners {
    background-color: rgba($dark-blue-2, 0.05);
    padding: 15px 0px;
    img {
      width: clamp(3.94rem, calc(2.88rem + 5.27vw), 7.63rem);
      max-height: 50px;
    }
    div {
    }
  }

  section.shops {
    padding: 0px;
    padding-top: $space-3xl-7xl;
    margin-bottom: $space-3xl-7xl;
    .content {
      padding-left: $space-m-3xl;
      margin-bottom: $space-m-4xl;
      @include mQ(992px) {
        text-align: center;
      }
      h1 {
        margin-bottom: 16px;
      }
      button,
      a.button {
        @include buttonMain;
        &:hover {
          text-decoration: none;
        }
        width: clamp(9.38rem, calc(6.75rem + 13.13vw), 18.56rem);
        @include mQ(768px) {
          width: 90%;
        }
      }
      p {
        width: 30rem;
        max-width: 90%;
        margin-bottom: $space-m-2xl;
      }
    }
    svg,
    img {
      width: 100%;
      max-width: 750px;
    }
  }

  section.what-you-get {
    padding: 0px;
    padding-top: $space-3xl-7xl;
    .image {
      position: relative;
      img {
        width: 100%;
        margin: auto;
        @include mQ-min(992px) {
          width: 90%;
          height: auto;
        }
        @include mQ-min(1200px) {
          width: 70%;
          height: auto;
        }
      }
      .text {
        position: absolute;
        background-color: $orange;
        color: $white;
        left: 25px;
        bottom: 15px;
        border-radius: 5px;
        padding: 10px;
        font-size: $step-0;
        @include mQ-min(992px) {
          padding: 20px;
          bottom: 5%;
        }
      }
    }
    .content {
      padding-left: $space-m-3xl;
      @include mQ(992px) {
        text-align: center;
        padding-top: $space-3xl-7xl;
      }
      h1 {
        margin-bottom: $space-m-2xl;
      }
      & > div > p {
        margin-bottom: $space-3xl-7xl;
      }
      .list {
        padding: 0px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: 16px;
        text-align: start;
        @include mQ(768px) {
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(4, auto);
        }
        .list-item {
          background-color: $light-blue-2;
          padding: 20px;
          border-radius: 10px;
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 0px;
          width: 100%;
          p.head {
            font-weight: 600;
            font-size: $step-1;
          }
          p.info {
            margin-bottom: 0px;
          }
          svg {
            width: 64px;
            height: 64px;
            padding: 14px;
            background-color: $dark-blue-2;
            border-radius: 16px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  section.mobile-cta {
    padding: 0px;
    padding-top: $space-6xl-11xl;
    padding-bottom: $space-6xl-11xl;
    background: linear-gradient(
      0.6deg,
      rgba(19, 44, 86, 0.03) 9.21%,
      rgba(19, 44, 86, 0.0012) 122.37%
    );

    .image {
      img {
        display: flex;
        width: 0%;
        height: auto;
        transition: width 200ms;
        &.active {
          width: 100%;

          @include mQ(992px) {
            width: 100%;
            margin: auto;
          }
        }
      }
    }
    .content {
      padding-left: $space-m-3xl;
      padding-right: $space-m-3xl;
      padding-top: $space-3xl-7xl;
      // padding-bottom: $space-6xl-11xl;
      @include mQ(992px) {
        text-align: center;
      }
      h1 {
        margin-bottom: $space-5xl-7xl;
      }
      .steps {
        li {
          width: 70%;
          border-top: rgba($dark-blue-2, 0.1) 1px solid;
          padding: 15px;
          padding-left: 0px;
          flex-direction: column;
          transition: all 200ms;
          .title {
            transition: all 200ms;
            color: rgba($dark-blue-2, 0.56);
            font-size: $step-1;
            margin-bottom: 0px;
          }
          .desc {
            transition: all 200ms;
            display: none;
            height: 0px;
            overflow: hidden;
          }
          &.active {
            .title {
              font-size: $step-2;
              color: $green;
              font-weight: bold;
            }
            .desc {
              display: flex;
              height: fit-content;
              color: rgba($dark-blue-2, 0.56);
            }
          }
        }
        li:first-child {
          border: none;
        }

        @include mQ(992px) {
          text-align: center;
          li {
            display: none;
            border: none;
            width: 100%;
            padding: $space-s-m $space-m-3xl;
            &.active {
              display: flex;
              .title {
                font-size: $step-3;
              }
            }
          }
        }
      }
    }
    .buttons {
      margin-top: $space-5xl-9xl;
      width: 100%;
      a {
        width: 48%;
        img {
          width: 100%;
          height: auto;
        }
        @include mQ-min(992px) {
          max-width: 200px;
        }
      }
    }
  }
  section.integration-cta {
    background-color: $white;
    padding: 0px;
    padding-top: $space-6xl-11xl;
    text-align: center;
    overflow: hidden;

    @include mQ(992px) {
      padding-left: $space-m-3xl;
      padding-right: $space-m-3xl;
    }

    & > h1 {
      padding-bottom: $space-s-m;
    }

    & > p {
      padding-bottom: 21px;
      width: clamp(18.38rem, calc(15.91rem + 12.32vw), 27rem);
    }

    .integrations {
      padding: 0px;
      &:first-child {
        margin-right: 20px;
      }
      & > div {
        background-color: $light-beige;
        width: calc(50% - 20px);

        border-radius: 32px;
        padding: clamp(2.63rem, calc(2.48rem + 0.71vw), 3.13rem)
          clamp(1.31rem, calc(0.08rem + 6.16vw), 5.63rem);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        @include mQ(992px) {
          width: 100%;
        }

        img {
          margin-top: 25px;
          margin-bottom: 32px;
          width: 120px;
          height: auto;
        }
        p {
          color: rgba($dark-blue-2, 0.8);
          margin-bottom: 32px;
          line-height: 160%;
        }
        a.action {
          background: none;
          color: $orange;
          stroke: $orange;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 5px 15px;
          border-radius: 6px;
          svg {
            margin-left: 5px;
          }
          &:hover {
            background-color: rgba($orange, 0.2);
          }
        }
        a.soon {
          background-color: $beige-2;
          color: darken($beige-2, 50%);
          padding: 5px 15px;
          border-radius: 6px;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .mail {
      background-color: rgba($light-grey, 0.3);
      text-align: start;
      outline: none;
      border-radius: 32px;
      padding: 44px clamp(2.63rem, calc(2.41rem + 1.07vw), 3.38rem);

      p {
        color: rgba($dark-blue-2, 0.8);
        width: 70%;
        margin-bottom: 35px;
      }
      input {
        background: none;
        width: 80%;
        border-radius: 6px 0px 0px 6px;
        border: 0.5px solid $dark-blue-2;
        border-right: none;
        outline: none;
        &:focus {
          outline: none;
        }
        padding-left: 25px;
        font-weight: normal;
        font-size: $step-0;
        color: $dark-blue-2;
        &::placeholder {
          color: rgba($dark-blue-2, 0.56);
        }
      }
      button {
        width: 20%;
        border-radius: 0px 6px 6px 0px;
        font-weight: 500;
        font-size: $step-0;
      }
      @include mQ(992px) {
        p {
          width: 100%;
        }
        input {
          width: 100%;
          border-radius: 6px;
          border: 0.5px solid $dark-blue-2;
          height: 40px;
          margin-bottom: 15px;
        }
        button {
          width: 100%;
          border-radius: 6px;
          height: 40px;
        }
      }

      &.success {
        p {
          width: 100%;
        }
        svg {
          border: 1px solid $green;
          padding: 10px;
          width: 50px;
          height: 50px;
          stroke: $green;
          border-radius: 50%;
        }
      }
    }
  }
  .get-started {
    padding: $space-6xl-11xl 0px 0px;
    text-align: center;

    background: linear-gradient(
      0.6deg,
      rgba(19, 44, 86, 0.03) 9.21%,
      rgba(19, 44, 86, 0.0012) 122.37%
    );
    @include mQ(992px) {
      background: $white;
    }

    & > h1 {
      margin-bottom: $space-3xl-4xl;
    }

    & > p {
      margin-bottom: 40px;
      width: 60%;
    }

    @include mQ(992px) {
      padding-left: $space-m-3xl;
      padding-right: $space-m-3xl;
      & > p {
        width: 100%;
      }
    }

    .plans {
      padding: 0px;
      margin-bottom: $space-s-m;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include mQ(992px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
      .plan-card {
        position: relative;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 20px 40px rgba($black, 0.03);
        border-radius: 24px;
        z-index: 1000;
        margin-bottom: 25px;

        @include mQ(992px) {
          width: 100%;
        }

        .icon {
          z-index: 1001;
          position: absolute;
          top: 5%;
          left: 5%;
          border: $white 4px solid;
          border-radius: 50%;
          padding: 24px;
          width: 80px;
          height: 80px;
          svg {
            stroke-width: 3.5px;
            stroke: $white;
            width: 100%;
            height: 100%;
          }
        }

        .banner {
          height: 76px;
          border-radius: 24px 24px 0px 0px;
          background-color: $dark-blue;
          margin-bottom: 80px;
        }
        .content {
          padding: 0px 25px;
          padding-bottom: 25px;
          text-align: start;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          justify-content: space-between;
          h3 {
            margin-bottom: 34px;
          }
          li {
            color: $dark-blue-2;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            line-height: 24px;
            margin-bottom: 24px;
            font-weight: normal;
            div {
              width: calc(100% - 20px);
            }
            svg {
              width: 20px;
              height: auto;
              padding: 0px;
              stroke: $orange;
              stroke-width: 2.5px;
              margin-right: 15px;
            }
          }
          ul {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }

          a {
            margin-top: auto;
          }
        }

        @include mQ-min(992px) {
          margin-right: 25px;
        }

        &:nth-child(1) {
          .banner {
            background-color: $orange;
          }
          .icon {
            background-color: $orange;
          }
        }
        &:nth-child(2) {
          .banner {
            background-color: $camouflage;
          }
          .icon {
            background-color: $camouflage;
          }
          margin-right: 0px;
        }
      }
    }
  }
  section.map {
    background-color: $white;
    padding: 0px;
    padding-top: $space-6xl-11xl;
    text-align: center;
    position: relative;
    overflow: hidden;

    & > * {
      z-index: 101;
    }

    & > h1 {
      margin-bottom: 24px;
    }

    & > p {
      margin-bottom: $space-m-3xl;
      width: clamp(18.38rem, calc(15.91rem + 12.32vw), 27rem);
    }

    .bg-dots {
      position: absolute;
      top: 20%;
      left: 0px;
      width: 100%;
      height: auto;
      z-index: 100;
      path {
        animation: dots 200ms linear;

        @keyframes dots {
          from {
            scale: 2;
            margin-bottom: -5px;
          }
          to {
            scale: 1;
            margin-bottom: 0px;
          }
        }
      }
    }

    .world-map {
      width: 80%;
      margin-bottom: $space-m-2xl;
      overflow: none;
      svg {
        width: 100%;
        margin-right: -50px;
        margin-bottom: $space-m-2xl;
        height: auto;
        @include mQ(992px) {
          width: 200%;
          margin-left: -25%;
        }
      }

      .stats {
        h4 {
          font-weight: bold;
          color: $green;
        }
      }
    }

    .cta-container {
      padding: 0px $space-m-3xl;
    }
    .call-to-action {
      background-color: $green;
      text-align: start;
      border-radius: 24px;
      padding: 0px;
      margin-bottom: $space-m-2xl;
      z-index: 102;
      * {
        color: $white;
      }
      .content {
        padding: $space-m-2xl $space-m-3xl;
        text-align: center;
        h1,
        p {
          margin-bottom: 24px;
          @include mQ-min(992px) {
            text-align: start;
          }
        }
        p {
          color: rgba($white, 0.8);
        }

        .buttons {
          width: 100%;
          a {
            width: calc(50% - 15px);
            margin-right: 15px;
            img {
              width: 100%;
              height: auto;
            }

            @include mQ(992px) {
              margin: 0px;
              width: 175px;
              height: auto;
              margin-bottom: 15px;
            }

            @include mQ-min(992px) {
              max-width: 200px;
            }
          }
        }
      }
      .image {
        overflow: hidden;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mQ(992px) {
          margin-bottom: $space-s-m;
        }
        img {
          width: 120%;
          height: auto;
        }
      }
    }
  }
}
