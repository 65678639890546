@import "variables";
@import "mixins";

.status {
  border-radius: 80px;
  padding: 2px 10px 2px 12px;
  gap: 10px;
}

.created,
.transit {
  color: rgba(144, 112, 49, 1);
  background-color: rgba(255, 244, 216, 1);
  .manifest-status-circle {
    background-color: rgba(144, 112, 49, 1);
  }
}

.scanned,
.pre_transit {
  color: rgba(54, 90, 145, 1);
  background-color: rgba(235, 241, 249, 1);
  .manifest-status-circle {
    background-color: rgba(54, 90, 145, 1);
  }
}

.delivered {
  color: rgba(38, 91, 43, 1);
  background-color: rgba(232, 247, 227, 1);
  .manifest-status-circle {
    background-color: rgba(38, 91, 43, 1);
  }
}

.partially_delivered,
.returned {
  color: rgba(152, 88, 28, 1);
  background-color: rgba(255, 243, 226, 1);
  .manifest-status-circle {
    background-color: rgba(152, 88, 28, 1);
  }
}

.failed {
  color: $red;
  background-color: rgba($red, 0.1);
  .manifest-status-circle {
    background-color: $red;
  }
}

.empty-manifests {
  color: rgba(130, 130, 130, 1);
}

.manifest-status-circle {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.required {
  color: $red;
  margin-left: 3px;
}

.label {
  padding-left: 0;
  margin: 0;
  p {
    font-size: $step--1;
    color: $dark-grey;
  }
  input {
    border-width: 1px;
  }

  .dropdown {
    button {
      @include textBox;
      background: none;
      width: 100%;
    }
  }

  .dropdown-toggle {
    width: fit-content;
    min-width: 200px;
    border: 1px solid $grey;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &.placeholder {
      color: $grey;
    }
    &::after {
      margin-left: 24px;
    }
  }

  .dropdown-menu.show {
    width: 100%;
    background-color: $white;
    .dropdown-item {
      color: $black;
    }
  }

  .dropdown-item {
    &:hover {
      background-color: rgba($green, 0.3);
      color: $black;
    }
  }
  .dropdown.show {
    .btn-primary.dropdown-toggle {
      background-color: rgba($green, 0.3);
      color: $black;
    }
  }
}

.cancel-button {
  @include buttonMain;
  background: none;
  color: #dc5e40;
  border: 2px solid #dc5e40;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  &:hover {
    background-color: #dc5e40;
    text-decoration: none;
    color: $white;
  }

  &:active {
    background-color: #dc5e40;
  }
  &:focus {
    border: none;
    outline: 0;
  }
}

.unknown,
.temporary_failure {
  color: rgba(255, 96, 96, 1);
  background-color: rgba(255, 236, 236, 1);
  .manifest-status-circle {
    background-color: rgba(255, 96, 96, 1);
  }
}

.icon {
  & :hover {
    cursor: pointer;
  }
}

.expanded-row-details-item {
  &.header div p {
    color: #828282 !important;
  }
}

.manifests .tracking-number {
  margin-left: 5px;
  color: $green;
  font-size: 12px;
  .copy-icon {
    color: #828282;
    margin-left: 5px;
    margin-top: 0 !important;
    & :hover {
      cursor: pointer;
    }
  }
}

.manifest-details {
  color: $green;
  font-weight: 400;
}

.card {
  background-color: #edf0f666;
  border-radius: 8px;
  width: 100%;
  margin-right: 16px;
  padding: 16px;
  border: none;
  p {
    font-size: 16px;
    font-weight: 600;
  }
}

.gap-20 {
  gap: 20px;
}

.gap-16 {
  gap: 16px;
}

.gap-8 {
  gap: 8px;
}

.manifest-link {
  color: $green;
  padding: 0;
  font-weight: 400;
}

.grey-small-text {
  font-size: 14px;
  color: #828282;
}

.header {
  font-size: 20px;
  font-weight: 600;
}

.close-button {
  @include buttonMain;
  background: none;
  color: $green;
  border: 2px solid $green;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: $green;
    text-decoration: none;
    color: $white;
  }

  &:active {
    background-color: $green;
  }
  &:focus {
    border: none;
    outline: 0;
  }
}

.filled-button {
  @include buttonMain;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    background-color: $hover-green;
    color: $white;
    text-decoration: none;
  }

  &:active {
    background-color: $active-green;
    color: $white;
  }
}

.manifest-list {
  .react-bootstrap-table {
    overflow: auto;
    td,
    th {
      &:nth-child(1) {
        width: 200px;
      }
      &:nth-child(2) {
        width: 150px;
      }
      &:nth-child(3) {
        width: 150px;
      }
      &:nth-child(4) {
        width: 150px;
      }
      &:nth-child(5) {
        width: 150px;
      }
      &:nth-child(6) {
        width: 180px;
      }
      &:nth-child(7) {
        width: 130px;
      }
    }
  }
  .manifest-actions {
    gap: 5px;
    button {
      width: 100%;
      padding: 3px 5px;
      height: fit-content;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.manifests {
  .card-section {
    overflow-x: auto;
  }
  .react-bootstrap-table {
    min-width: 992px;
    * {
      font-size: 14px;
    }
  }
  .search-action {
    margin: 0 !important;
    width: 50%;
    * {
      margin-top: 0;
    }
  }
}

.manifest-detail {
  .react-bootstrap-table {
    a {
      color: $green;
      font-weight: normal;
      &:hover {
        text-decoration: none;
        color: $hover-green;
        font-weight: 500;
      }
    }
  }
}
