@import "variables";
@import "mixins";

.santaPage {
  h1 {
    font-weight: bold;
    font-size: 68px;
    @include mQ(992px) {
      font-size: 62px;
    }
  }
  a {
    border-radius: 12px;
    &:hover {
      text-decoration: none;
      background-color: $dark-green;
    }
  }
  button {
    border-radius: 12px;
  }
  .section-1 {
    padding-top: 50px;
    background-color: $beige;

    .content {
      & > * {
        margin: 15px;
      }
      @include mQ(992px) {
        padding-bottom: 50px;
      }
    }
    .image {
      @include mQ(992px) {
        background-color: $mustard;
      }
    }
    a {
      @include buttonMain;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      &:hover {
        text-decoration: none;
        background-color: $dark-green;
      }
    }
  }
  .section-2 {
    background-color: $mustard;
    padding: 100px 0;
    color: $white;
    position: relative;
    h1 {
      color: $white;
    }
    h4 {
      font-size: 32px;
      text-transform: uppercase;
    }
    p {
      font-size: 18px;
    }
    a {
      @include buttonMain;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      &:hover {
        text-decoration: none;
        background-color: $dark-green;
      }
    }
    .container {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      img {
        margin-left: auto;
      }
      h4 {
        position: absolute;
        left: 0%;
      }
    }
    .list {
      & > div {
        @include mQ(992px) {
          margin: 50px 0;
        }
      }
      @include mQ(992px) {
        div:nth-child(2) {
          .container {
            img {
              margin-left: 0;
            }
            h4 {
              position: absolute;
              right: 0%;
              text-align: right;
            }
          }
        }
      }
    }
    .scribble-bg {
      position: absolute;
      height: 90%;
      right: 30px;
      top: 20px;
      margin-right: 10px;

      //z-index: -100;
    }
  }
  .section-3 {
    background-color: $dark-green;
    color: $white;
    //padding: 100px 0;
    padding-top: 250px;
    padding-bottom: 100px;
    position: relative;
    @include mQ(992px) {
      padding-top: 100px;
    }
    h1 {
      font-size: 108px;
      margin-bottom: 30px;
      @include mQ(992px) {
        font-size: 62px;
      }
    }
    h3 {
      font-size: 48px;
      margin-bottom: 30px;
      @include mQ(992px) {
        font-size: 36px;
      }
    }
    p {
      font-size: 17px;
      font-weight: normal;
    }
    .item {
      @include mQ-min(992px) {
        margin-top: -30px;
      }
      .image {
      }
    }
    .scribble-bg {
      height: 15vw;
      &.top {
        position: absolute;
        top: 75px;
        left: -40px;
      }
    }
    .scribbles {
      position: absolute;
      @include mQ-min(992px) {
        top: 29%;
        height: 53%;
        left: 45%;
        .scribble-bg {
          height: 12vw;
        }
      }
      @include mQ-min(1200px) {
        top: 28%;
        height: 54%;
        left: 45%;
        .scribble-bg {
          height: 13vw;
        }
      }
      @include mQ-min(1440px) {
        top: 27%;
        height: 55%;
        left: 45%;
        .scribble-bg {
          height: 13vw;
        }
      }

      display: flex;
      justify-content: space-between;

      .scribble-bg:nth-child(2n) {
        transform: rotateY(180deg);
      }
    }
  }
  .section-4 {
    background-color: $mustard;
    color: $white;
    padding-top: 100px;

    .container {
      position: relative;
      padding: 0;
      @include mQ(1200px) {
        padding-top: 35%;
      }
      p {
        font-size: 18px;
        margin-bottom: 50px;
        line-height: clamp(0px, 2vh, 38px);
      }
      h1 {
        font-size: clamp(40px, 7.3vw, 200px);
        @include mQ(1200px) {
          font-size: clamp(40px, 17.5vw, 150px);
          padding-left: 20px;
        }
        position: absolute;
        width: fit-content;
        left: 0%;
        z-index: 1000;
        top: 0%;
      }
      img {
        padding-left: 20px;
      }
      .content {
        padding-bottom: 4rem !important;
        @include mQ-min(1440px) {
          padding-bottom: 10% !important;
        }
      }
    }
  }

  .section-5 {
    background-color: $beige;
    padding: 100px 0;
    h1 {
      font-size: 64px;
      margin: 50px 0;
      @include mQ(992px) {
        margin: 0;
      }
    }
    p {
      font-size: 16px;
    }
    .accordion > div {
      border-bottom: 1px solid $black;
      cursor: pointer;
      margin: 15px 0;
      img.active {
        display: none;
      }
      img.inactive {
        display: flex;
      }
      .show {
        img.active {
          display: flex;
        }
        img.inactive {
          display: none;
        }
      }
      .answer {
        color: $dark-grey;
      }
    }
  }

  .section-6 {
    background-color: $dark-beige;
    padding-top: 50px;
    overflow: hidden;
    z-index: -1;
    position: relative;
    a {
      background-color: #fff0;
      padding: 0;
    }
    .content {
      & > * {
        margin-bottom: 25px;
      }
    }
    .scribble-bg {
      position: absolute;

      height: 110%;
      right: 200px;
      top: -150px;
      //margin-right: 10px;

      z-index: 0;
    }
  }
}
