@import "variables";
@import "mixins";

.csv-detail-container {
  a.back-action {
    color: $text-grey;
    &:hover {
      text-decoration: none;
      color: $green;
    }
  }

  .csv-imports {
    .csv-import-content {
      width: 71%;
      margin-left: auto;
    }
    .menu-container {
      .menu-list {
        p {
          color: grey;
        }
        .download-action {
          color: $green;
          &:hover {
            text-decoration: none;
            color: $hover-green;
          }
        }
      }
    }
  }

  //.menu-container {
  //  width: 20%;
  //  height: fit-content;
  //  position: fixed;
  //  &>div {
  //    width: 100%;
  //    padding: 25px;
  //    background-color: $white;
  //    border-radius: 16px;
  //    margin-bottom: 25px;
  //    display: flex;
  //    flex-direction: column;
  //  }
  //  .menu-list {
  //
  //  }
  //  h4 {
  //    margin-bottom: 20px;
  //    font-size: 22px;
  //  }
  //  a {
  //    text-decoration: none;
  //    color: $grey;
  //    font-weight: 400;
  //    margin-bottom: 15px;
  //    transition: color 300ms;
  //    &:hover,&.active {
  //      color: $green;
  //    }
  //  }
  //
  //  @include mQ(992px) {
  //    width: 100%;
  //    position: relative;
  //  }
  //  span {}
  //  .sub-menu {
  //    display: flex;
  //    width: 100%;
  //  }
  //}
}
.upload-csv-container {
  .store-details {
    .row {
      .store-name {
        a {
          color: $green;
          &:hover {
            text-decoration: none;
            color: $hover-green;
          }
        }
      }
    }
  }
  .submit-cancel-buttons {
    .no-bg {
      background: none;
      color: $green;
      border: 2px solid $green;
    }
  }
}
