@import "variables";
@import "mixins";
.header {
  z-index: 5000;

  &.landing {
    background-color: $white;
    padding: 5px $space-m-3xl;
    .navbar {
      padding-left: 0px;
      padding-right: 0px;
    }
    a.navbar-brand {
      height: max-content;
      text-decoration: none;
      margin-right: 40px;
      // margin-bottom: $space-s-m;
      // width: fit-content;
      & > div {
        border-radius: 50%;
        background-color: $green;
        width: $space-3xl-4xl;
        height: $space-3xl-4xl;
        padding: $space-2xs-xs;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: auto;
        }
      }

      h2 {
        color: $green;
        font-weight: 600;
        font-size: $step-2;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .nav-link {
      color: rgba($dark-blue-2, 0.7);
      @include mQ-min(992px) {
        margin-right: 8px;
      }
    }
  }

  .account-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    min-height: 30px;
    background-color: $light-blue;
    border-radius: 50%;
    border: 2px solid $green;
    color: $green;
    padding: 5px;
    &:hover {
      background-color: $green;
      color: $light-blue;
    }
  }
  &.home a {
    color: rgb(124, 124, 124);
    // font-weight: normal;
  }
  &.global {
    .headerButton {
      padding: 10px 20px;
      background-color: $green;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      text-decoration: none;
      &:hover {
        background-color: $hover-green;
      }
    }
  }
  img {
    max-width: 250px;
  }
  ul {
    & * {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include mQ-min(1200px) {
        font-size: clamp(14px, 1vw, 18px);
      }
      user-select: none; /* supported by Chrome and Opera */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
    }

    a {
      color: $dark-grey;
      stroke: $dark-grey;
      font-weight: normal;
      text-decoration: none;
      @include mQ(1200px) {
        width: 100%;
        margin: 5px 0;
      }
    }
    li {
      display: flex;
      align-items: center;
      flex-direction: row;
      &:hover,
      &.active {
        color: $green;

        svg {
          stroke: $green;
        }
      }
      svg {
        margin-left: 10px;
      }
    }
    .headerButton {
      border-radius: 10px;
      font-weight: normal;
      margin: 0px 10px;
      height: 48px;
      // @include buttonMain;
      @include mQ-min(1200px) {
        width: 10vw;
        max-width: 150px;
      }
    }
    .download {
      background-color: $green;
      color: $white;
      &:hover {
        background-color: $dark-green;
        color: $white;
        text-decoration: none;
      }
    }
    .logIn {
      border: 2px solid $green;
      color: $green;
      &:hover {
        background-color: rgba($green, 0.3);
      }
    }
    .sign-up {
      background-color: $green;
      color: $white;
      &:hover {
        background-color: $hover-green;
        color: $white;
      }
    }

    .drop {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .drop-list {
        @include mQ-min(992px) {
          position: absolute;
          top: 100%;
          display: none;
          padding: 15px;
          z-index: 1100;
          background-color: $white;
          width: 100%;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
          border-top: 1px solid $green;
        }
      }
      &:hover,
      &:active,
      .drop-list:hover {
        .drop-list {
          display: flex;
        }
      }
    }
  }
  .show {
    display: flex;
    width: 50%;
    float: right;
  }
  .collapse {
    // background-color: $green;
    padding: 0px;
    padding-bottom: 50px;
    @include mQ-min(1200px) {
      padding-bottom: 0px;
    }
    // width: auto;
    // @include mQ-min(1200px){
    // 	max-width: 1000px ;
    // }
  }

  button.navbar-toggler {
    svg {
      fill: $dark-blue-2;
    }
    &:hover {
      background-color: rgba($dark-blue-2, 0.3);
    }
  }
}
