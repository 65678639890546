@import "variables";
@import "mixins";

.checkouts-main {
  .modal-content.store-name {
    background-color: $bg-grey;
    .last {
      margin-bottom: 0px;
    }
  }
  input,
  select {
    @include textBox;
    margin-bottom: 15px;
  }
  .checkouts-empty {
    width: 80%;
    margin: auto;
    padding: 4rem;
    background-color: $white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      margin-bottom: 0;
      line-height: 30px;
      color: $text-grey;
    }
    a {
      @include buttonMain;
      width: fit-content;
      padding: 13px 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      border-radius: 5px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .settings-card.checkouts-list {
    .head {
      a {
        color: $green;
        &:hover {
          text-decoration: none;
          color: $dark-grey;
        }
      }
    }

    .checkouts-item {
      width: 100%;
      img {
        width: 65px;
        height: auto;
        border-radius: 5px;
        @include mQ-min(922px) {
          margin-right: 20px;
        }
      }

      button:last-child {
        background-color: $white;
        border: $green 1px solid;
        color: $green;
        max-width: none !important;
        padding: 8px 9px;
        &:hover {
          background-color: rgba($green, 0.2);
        }
        &.active {
          color: $red;
          border-color: $red;
          &:hover {
            background-color: rgba($red, 0.2);
          }
        }
      }
    }
  }

  .checkouts-detail-container {
    a.back-action {
      color: $text-grey;
      &:hover {
        text-decoration: none;
        color: $green;
      }
    }

    .checkouts-details {
      margin-top: 35px;
      .checkout-details-content {
        width: 71%;
        margin-left: auto;
        &.left-align {
          margin-right: auto;
          margin-left: 0;
          width: 100%;
          position: relative;
          & > div {
            //width: 100%;
          }
        }
        form {
          label {
            span {
              margin: 0 5px;
            }
          }
          p,
          a,
          div,
          svg {
            margin-top: 0;
          }
          .col-3,
          .col-9 {
            padding: 0;
            gap: 10px;
            margin-top: auto;
          }
          .col-3 {
            flex: 0 0 calc(calc(100% * calc(3 / 12)) - 7.5px);
            max-width: calc(calc(100% * calc(3 / 12)) - 7.5px);
          }
          .col-9 {
            flex: 0 0 calc(calc(100% * calc(9 / 12)) - 7.5px);
            max-width: calc(calc(100% * calc(9 / 12)) - 7.5px);
          }
        }

        .checkouts-create-form {
          p.info {
            margin-top: 0;
          }
          .address-select-item {
            margin-top: 25px;
          }

          .payments-section {
            margin-bottom: 25px;
            .accepted-payments {
              margin-bottom: 15px;
              li {
                display: flex;
                flex-direction: row;
                padding: 8px 12px;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                background-color: rgba($bg-grey, 0.4);
                width: fit-content;
                height: 40px;
                margin-right: 8px;
                img {
                  width: 24px;
                  height: auto;
                  margin-top: 0;
                  margin-right: 8px;
                }
                p {
                  font-size: 12px;
                  font-weight: 500;
                  margin-bottom: 0;
                }
                &.disabled {
                  background-color: rgba($grey, 0.4);
                  color: $text-grey;
                }
              }
              p.info {
                font-size: 14px;
              }
            }

            .mpesa-cod-section {
              padding: 24px;
              border-radius: 8px;
              background-color: rgba($bg-grey, 0.4);
              .percent-field {
                position: relative;
                width: fit-content;
                min-width: 70px;
                max-width: 100px;

                input {
                  height: 40px;
                  margin-top: 0px;
                }
                .invalid-feedback {
                  position: absolute;
                  top: 100%;
                  left: 0;
                  width: 300px;
                }
              }
              & > div {
                &.checkbox {
                  width: fit-content;
                }
                &:last-child {
                  padding-left: 28px;
                }
              }
            }
          }
          .shipping-config-section {
            margin-bottom: 25px;
            .shipping-fee-radio {
              margin-top: 15px;
              margin-bottom: 15px;
              .radio-item {
                margin-right: 8px;
                width: auto;
                margin-bottom: 15px;
                padding: 16px;
                border-radius: 8px;
                background-color: rgba($bg-grey, 0.4);
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                .check {
                  margin-right: 16px;
                }
              }
            }
          }
        }

        .checkout_url {
          width: 25%;
          margin-left: 2rem;
          & > div {
            padding: 25px;
            background-color: $white;
            border-radius: 16px;
            margin-bottom: 2rem;
            overflow: hidden;
            .url-text {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              max-width: 100%;
              text-overflow: ellipsis;
              color: $text-grey;
              word-break: break-all !important;
              word-wrap: break-word !important;
            }
            a {
              color: $green;
              &:hover {
                color: $text-grey;
              }
            }
          }
          button {
            background: none;
            border: 1px solid $red;
            color: red;
            width: 100%;
            &:hover {
              background-color: rgba($red, 0.1);
            }
          }
          @include mQ(992px) {
            width: 100%;
            margin-left: 0;
          }
        }
        @include mQ(992px) {
          width: 100%;
        }

        .products-create-form {
          flex-grow: 1;

          .show-error {
            border: $red 1px solid;
            border-radius: 8px;
            padding: 15px;
          }

          .field-group {
            gap: 20px;

            & > * {
              margin-top: 0;
              width: calc(50% - 10px);
            }
            select {
              margin-top: 0;
            }
          }
          p.info {
            font-size: 14px;
            margin: 0;
          }
          .last {
            .images-preview {
              p {
                margin-top: 10px;
                margin-bottom: 0.5rem;
              }
              .images-list {
                width: 100%;
                min-width: fit-content;
                height: fit-content;
                padding: 15px;
                border-radius: 8px;
                border: 1px $grey solid;
                transition: 0.4s all;
                img {
                  padding: 8px;
                  border-radius: 8px;
                  //width: fit-content;
                  width: 100px;
                  height: 100px;
                  position: relative;
                  object-position: center;
                  object-fit: contain;
                }
              }
            }
          }
        }

        .checkout-item-detail {
          .cart-store-header {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 50px;
            img {
              height: 125px;
              margin-bottom: 20px;
            }
            h2 {
              font-weight: 500;
            }
          }
          .cart-config-details {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .config-detail-item {
              margin-bottom: 15px;
              display: flex;
              width: 100%;
              li {
                display: flex;
                flex-direction: row;
                padding: 8px 12px;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                background-color: rgba($bg-grey, 0.4);
                width: fit-content;
                height: 40px;
                margin-right: 8px;
                img {
                  width: 24px;
                  height: auto;
                  margin-top: 0;
                  margin-right: 8px;
                }
                p {
                  font-size: 12px;
                  font-weight: 500;
                  margin-bottom: 0;
                }
                &.disabled {
                  background-color: rgba($grey, 0.4);
                  color: $text-grey;
                }
              }
              p.info {
                margin-bottom: 10px;
                span {
                  margin: 0 5px;
                }
              }
              &:has(.info + p) {
                width: auto;
                display: flex;
                flex-grow: 1;
                //flex-direction: row;
              }
              &:has(.info + *) {
                flex-direction: column;
              }
            }
            .payment-details {
              width: 100%;
              li {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 40px;
                margin-right: 15px;
                img {
                  width: 24px;
                  height: auto;
                  margin-right: 8px;
                }
              }
              .config-detail-item {
                width: fit-content;
              }
            }
          }
        }
        .checkout-product-list {
          .last {
            overflow-x: auto;
          }
          table {
            display: table;
            width: 100%;
            thead {
              width: 100%;
              background-color: rgba($bg-grey, 0.4);
              th:nth-child(1) {
                border-radius: 8px 0 0 8px;
              }

              th:nth-last-child(1) {
                border-radius: 0 8px 8px 0;
              }
              th {
                padding: 16px;
                font-weight: 400;
                color: $text-grey;
              }
            }
            tr {
              display: table-row;
              img {
                width: 100px;
                height: 100px;
                object-fit: contain;
                object-position: center;
              }
            }
            tbody {
            }
          }
          .checkout-product-list-item {
            tr {
              padding-bottom: 5px;
            }
            td {
              padding: 8px;
              height: 100px;
              object-position: center;
              object-fit: contain;
              p {
                color: $text-grey;
              }
              svg {
                width: 30px;
                height: auto;
              }
              a {
                color: $green;
                padding: 8px;
                display: flex;
                flex-grow: 1;
                justify-content: center;
                align-items: center;
                &:hover {
                  background-color: rgba($green, 0.2);
                  border-radius: 5px;
                }
              }
            }
          }
          .empty-product-list {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px;
            flex-direction: column;
            p {
              color: $text-grey;
              margin-bottom: 15px;
            }
            a {
              @include buttonMain;
              display: flex;
              justify-content: center;
              align-items: center;
              width: fit-content;
              padding: 0 5%;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
