@import "variables";
@import "mixins";

.loading-screen {
  position: absolute;
  z-index: 5000;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;

  background-color: $green;
  justify-content: center;
  align-items: center;
  .header {
    height: max-content;
    text-decoration: none;
    margin-bottom: $space-m-2xl;
    animation-name: loading;
    animation-duration: 3000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    & > div {
      border-radius: 50%;
      background-color: $white;
      width: 50px;
      height: 50px;
      padding: 12px;
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 100%;
        height: auto;
        stroke: $green;
      }
    }

    h2 {
      color: $white;
      font-weight: 600;
      font-size: 24px;
    }
  }
  @keyframes loading {
    0% {
      opacity: 0.1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
}

.home-body {
  overflow: hidden;

  .test-mode-banner {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $yellow;
    margin-bottom: 35px;
    padding: 25px;
    border-radius: 16px;
    * {
      color: $black;
    }

    button {
      color: $white;
      background-color: $yellow-2;
      justify-content: center;
      align-items: center;
      width: 20%;
      @include mQ(992px) {
        width: 30%;
      }

      &:hover {
        background-color: rgba($yellow-2, 0.7);
      }
    }

    svg {
      fill: #63573a;
    }
  }

  min-height: 100vh;
  height: fit-content;
  padding: 0px;
  background-color: $bg-grey;
  position: relative;

  @include mQ-min(1200px) {
    padding: 0px;
    height: auto;
    overflow-y: auto;
  }

  * {
    font-family: "Public Sans", "Roboto", sans-serif;
  }

  @include mQ(992px) {
    padding: 2rem 15px;
  }

  .dropdown-toggle {
    border: none;
    background-color: rgba($green, 0.1);
    color: $green;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    width: 140px;
    border-radius: 9px;
    &:focus {
      box-shadow: none;
      border: 1px solid $green;
    }
    &:hover {
      background-color: rgba($green, 0.3);
    }
  }

  .popup-modal {
    position: absolute;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 20px -10px $dark-grey;
    width: fit-content;
    display: flex;
    flex-direction: column;
    height: 0px;
    padding: 0px;
    overflow: hidden;
    z-index: 5000;
    transition:
      height 200ms,
      padding 100ms;
    .popup-item {
      padding: 10px;
      border-radius: 8px;
      color: $grey;
      stroke: $grey;
      cursor: pointer;
      svg {
        width: 25px;
        height: auto;
        margin-right: 1.5rem;
        stroke-width: 1.5px;
        stroke: $grey;
      }
      &:hover {
        background-color: rgba($light-grey, 0.5);
      }
    }
    &.active {
      height: fit-content;
      padding: 15px;
    }
    p {
      margin-bottom: 0px;
    }
    .logout-action {
      color: $red;
      svg {
        stroke: $red;
      }
    }

    .header {
      h2 {
        color: $green;
        font-size: 16px;
        font-weight: 600;
      }
      div {
        border-radius: 50%;
        background-color: $green;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        margin-right: 15px;
        padding: 7px;

        svg {
          width: 100%;
          height: auto;
          stroke: $white;
          stroke-width: 0.5px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  .date-range-filter {
    position: relative;

    .date-filter-selection {
      gap: 8px;
      &.active {
      }
    }
  }

  .sidebar {
    background-color: #ffffff;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    margin-right: 10px;

    .header {
      height: max-content;
      padding-top: 30px;
      padding-bottom: 50px;

      text-decoration: none;
      div {
        border-radius: 50%;
        background-color: $green;
        width: 45px;
        height: 45px;
        padding: 10px;
        margin-right: 15px;

        svg {
          width: 100%;
          height: auto;
        }
      }

      h2 {
        color: $green;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .nav-list,
    .bottom {
      flex: auto;

      li {
        list-style: none;
        text-decoration: none;
      }

      .nav-item {
        text-decoration: none;
        color: $grey;
        font-weight: 400;
        padding-left: 30px;
        margin-bottom: 30px;
        position: relative;

        svg {
          stroke: $grey;
          stroke-width: 1.5px;
          width: 30px;
          height: auto;
          margin-right: 15px;
        }

        .counter {
          color: $white;
          background-color: $orange;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          padding: 2px;
          font-size: 14px;
        }

        &:not(&.active):hover::after {
          position: absolute;
          content: "";
          right: 0px;
          width: 1.5px;
          background-color: $hover-green;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          height: 100%;
          animation: hoverAnim 500ms;

          @keyframes hoverAnim {
            0% {
              height: 0%;
              width: 0px;
            }

            100% {
              height: 100%;
              width: 1.5px;
            }
          }
        }

        &.active::after {
          position: absolute;
          content: "";
          right: 0px;
          width: 3.75px;
          background-color: $green;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          height: 100%;
          animation: activeAnim 500ms;

          @keyframes activeAnim {
            0% {
              height: 0%;
              width: 2px;
            }

            100% {
              height: 100%;
              width: 3.75px;
            }
          }
        }

        &.toggler {
          cursor: pointer;
          * {
            transition: all 50ms linear;
          }
          svg {
            stroke: none;
            fill: $grey;
          }
          .gutter {
            border-radius: 40%;
            padding: 2px;
            background-color: $grey;
            width: 24px;
            height: 16px;
            position: relative;
          }
          .slider {
            background-color: $white;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            position: absolute;
            top: 2px;
            left: 2px;
          }

          &:hover {
            animation: none;
            color: $green;
            svg {
              fill: $green;
              stroke: none;
            }
            .gutter {
              background-color: $green;
            }
            &:after {
              display: none;
            }
          }
        }
      }

      .nav-item:hover {
        color: $active-green;

        svg {
          stroke: $active-green;
        }
      }

      .nav-item.active {
        color: $green;

        svg {
          stroke: $green;
        }
      }
    }

    .bottom {
      flex: content;
      position: absolute;
      bottom: 0px;

      & > div {
        padding-top: 25px;
        border-top: 2px solid $grey;
      }

      .store {
        padding: 25px;
        margin-bottom: 20px;

        svg {
          margin: 15px;
          width: 60px;
          height: auto;
        }

        button {
          height: 40px;
          padding: 0px 35px;
          white-space: nowrap;
        }
      }
    }

    &.test-mode {
      .nav-item {
        &:not(&.active):hover::after,
        &.active::after {
          background-color: $yellow;
        }

        &.toggler {
          .gutter {
            background-color: $yellow;
          }
          .slider {
            left: auto;
            right: 2px;
          }
          &:hover {
            animation: none;
            color: $yellow;
            svg {
              fill: $yellow;
              stroke: none;
            }
            .gutter {
              background-color: $yellow;
            }
            &:after {
              display: none;
            }
          }
        }
      }

      .nav-item:hover {
        color: $yellow;

        svg {
          stroke: $yellow;
        }
      }

      .nav-item.active {
        color: $yellow-2;

        svg {
          stroke: $yellow-2;
        }
      }

      .bottom {
        .store {
          button {
            background-color: $yellow-2;
            &:hover {
              color: $black;
              background-color: rgba($yellow-2, 0.2);
            }
          }
        }
      }
    }

    .bottom {
      a.nav-item,
      a.nav-item.active {
        svg.warning {
          stroke: $red;
          width: 30px;
          height: auto;
          padding: 5px;
          border-radius: 8px;
          stroke-width: 2px;
          margin-left: 15px;
        }
      }
    }
  }

  .home-content {
    padding: 2rem;
    padding-bottom: 4rem;
    height: fit-content;
    min-height: 100vh;
    overflow-y: auto;

    @include mQ(992px) {
      padding: 0;
      padding-bottom: 4rem;
    }

    .impersonation-warning {
      width: 100%;
      padding: 25px;
      border-radius: 16px;
      background-color: rgba($red, 0.1);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      svg {
        stroke: $red;
        width: 50px;
        height: 50px;
        margin-right: 25px;
      }
      p {
        color: $red;
        margin-bottom: 0;
      }
      button {
        width: fit-content;
        padding: 5px 30px;
        background-color: rgba($red, 1);
        transition: all 0.3s;
        &:hover {
          background-color: rgba($red, 0.2);
          color: $red;
        }
      }
    }

    .dash-card {
      border-radius: 16px;
      box-shadow: 0px 0px 15px 0px rgba($black, 0.1);
      padding: 30px;
      background-color: $white;
      margin-bottom: 30px;
    }
    .error-block,
    .warning-block,
    .message-block,
    .success-block {
      width: 100%;
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      text-align: start;
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
      }
      svg {
        background: none !important;
        min-width: 35px;
        height: auto;
        &:not(.close-message-action) {
          margin-right: 20px;
          padding: 8px;
        }
      }
      p {
        width: 100%;
        margin-bottom: 0;
        word-break: break-word;
        white-space: break-spaces;
      }
      .close-message-action {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-left: auto;
      }
    }
    .error-block {
      background-color: rgba($red, 0.1);
      color: $red;
      svg {
        stroke: $red;
        transition: all 0.3s;
        &.close-message-action {
          &:hover {
            background-color: rgba($red, 0.3) !important;
          }
        }
      }
    }
    .warning-block {
      background-color: rgba($yellow-2, 0.1);
      color: $yellow;
      svg {
        stroke: $yellow-2;
        transition: all 0.3s;
        &.close-message-action {
          &:hover {
            background-color: rgba($yellow-2, 0.3) !important;
          }
        }
      }
    }
    .message-block {
      background-color: rgba($bg-grey, 0.4);
      color: $dark-grey;
      svg {
        stroke: $dark-grey;
        transition: all 0.3s;
        &.close-message-action {
          &:hover {
            background-color: rgba($dark-grey, 0.3) !important;
          }
        }
      }
    }
    .success-block {
      background-color: rgba($green, 0.1);
      color: $green;
      svg {
        stroke: $green;
        transition: all 0.3s;
        &.close-message-action {
          &:hover {
            background-color: rgba($green, 0.3) !important;
          }
        }
      }
    }

    .menu-container-collapse {
      margin-bottom: 25px;
      background: none;
      border: 1px solid $green;
      color: $green;
      stroke: $green;
      svg {
        margin-right: 5px;
      }
    }
    .menu-container {
      width: 20%;
      height: fit-content;
      position: fixed;
      & > div {
        width: 100%;
        padding: 25px;
        background-color: $white;
        border-radius: 16px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
      }
      .menu-list {
      }
      h4 {
        margin-bottom: 20px;
        font-size: 22px;
      }
      a {
        text-decoration: none;
        color: $grey;
        font-weight: 400;
        margin-bottom: 15px;
        transition: color 300ms;
        &:hover,
        &.active {
          color: $green;
        }
      }

      @include mQ(992px) {
        width: 100%;
        position: relative;
      }
      span {
      }
      .tooltip-block {
        background-color: blue !important;
        & > .tooltip-inner {
          background-color: red !important;
        }
      }
      #business-details-missing-tooltip > .tooltip-inner {
        background: red !important;
      }
      svg.warning {
        stroke: $red;
        width: 25px;
        height: auto;
        padding: 5px;
        border-radius: 8px;
        stroke-width: 2px;
        margin-left: 15px;
      }
      .sub-menu {
        display: flex;
        width: 100%;
      }
    }

    .file-upload {
      width: 100%;
      min-width: fit-content;
      height: fit-content;
      padding: 15px;
      border-radius: 8px;
      border: 1px $grey solid;
      transition: 0.4s all;
      &.error {
        border-color: $red;
      }
      * {
        font-family: "Work Sans", "Public Sans", "Roboto", sans-serif;
        font-weight: 400;
      }
      input {
        display: none;
      }

      .file-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .content {
        &.empty,
        &.drag {
          width: 100%;
          .icon {
            background-color: rgba($grey, 0.1);
            padding: 8px;
            border-radius: 50%;
            margin-bottom: 15px;
            svg {
              padding: 8px;
              background-color: rgba($grey, 0.3);
              border-radius: 50%;
              transition: 0.4s all;
            }
          }
          p {
            span {
              color: $green;
              font-weight: 500;
            }
          }
        }
        &.drag {
          .icon {
            background-color: rgba($grey, 0.3);
            svg {
              background-color: rgba($grey, 0.5);
            }
          }
        }
        .actions {
          z-index: 1200;
          position: absolute;
          top: 0;
          right: 0;
          padding: 3px;
          border-radius: 5px;
          background-color: $white;
          svg {
            width: 15px;
            height: 15px;
          }

          &:hover {
            svg {
              stroke: $red;
            }
          }
        }
        &.image {
          padding: 8px;
          border-radius: 8px;
          //width: fit-content;
          width: 100px;
          height: 100px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-position: center;

            object-fit: contain;
          }
        }
        &.file {
          width: 49%;
          margin-right: 8px;
          position: relative;
          display: grid;
          grid-template-columns: 0.1fr auto;
          grid-template-rows: auto;
          gap: 10px;
          & > *:nth-child(2) {
            p {
              margin-bottom: 0px;
              &.doc-name {
                //width: calc( (((100vw * 0.6) * 7 / 12) - 80px) * 0.8 - 25px );
                text-overflow: ellipsis;
                overflow: hidden;

                font-weight: 500;
                @include mQ(992px) {
                  //width: calc( ((100vw) - 80px) * 0.8 - 25px );
                }
              }
              &.doc-size {
                font-weight: 400;
                color: rgba($dark-grey, 0.6);
              }
            }
          }
          svg {
            width: 35px;
            height: auto;
            padding: 8px;
            background-color: rgba($light-blue, 1);
            stroke: $green;
            border-radius: 50%;
            &.uploaded {
              margin-top: 5px;
              width: 16px;
              height: 16px;
              background-color: $green;
              stroke: $white;
              padding: 2.5px;
              stroke-width: 4px;
            }
          }
          .spinner-border {
            margin-top: 5px;
            width: 16px;
            height: 16px;
            border: 0.15em solid $green;
            border-right-color: transparent;
          }
          .progress-bar {
            background-color: rgba($green, 0.2);
            height: 10px;
            width: 100%;
            margin-right: 20px;
            border-radius: 5px;
            .progress-bar-filler {
              transition: all 0.5s;
              background-color: $green;
              border-radius: 5px;
              height: 10px;
            }
          }
        }
      }

      &:hover:not(.error) {
        border-color: $green;
        .content.empty {
          svg {
            width: 48px;
            height: 48px;
          }
        }
      }

      &.active {
        background-color: rgba($grey, 0.2);
        border-width: 2px;
      }
    }

    .settings-card {
      margin-bottom: 30px;
      text-decoration-color: red;
      padding: 0px;
      background-color: $bg-grey;
      .card-section {
        width: 100%;
        padding: 30px;
        background-color: $white;
        margin-bottom: 5px;
        @include mQ(992px) {
          padding: 30px 15px;
        }

        p {
          margin-bottom: 0px;
          &.title {
            margin-bottom: 5px;
          }
        }

        h3 {
          font-size: 20px;
          font-weight: 600;
        }

        &.head {
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          p,
          h3,
          h4 {
            margin-bottom: 0px;
          }
          h3 {
            font-size: 20px;
            font-weight: 600;
          }
          a.action {
            color: $green;
            font-weight: normal;
            padding: 5px 8px;
            border-radius: 5px;
            transition: background-color 200ms;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover {
              background-color: rgba($green, 0.1);
              text-decoration: none;
            }
            &.red {
              color: $red;
              &:hover {
                background-color: rgba($red, 0.1);
              }
            }
          }
        }
        &.last {
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          margin-bottom: 0px;
        }
        .h-1 {
          color: $dark-grey;
        }
        .info {
          color: $text-grey;
        }

        button {
          &:disabled,
          &[disabled] {
            background-color: $green-10 !important;
            color: $green;
            padding: 5px 15px;
          }
        }
      }
    }

    .home-header {
      margin-bottom: 50px;
      .popup-modal {
        top: 100%;
        right: 0px;
      }
      .header-info {
        h2 {
          font-weight: 600;
        }

        p {
          color: $grey;
          font-weight: 400;
          @include mQ(992px) {
            display: none;
          }
        }
      }

      .header-actions {
        & > div {
          margin: auto 15px;
        }

        .search-action {
          background: #ffffff;
        }

        .notif-aciton {
          position: relative;
          cursor: pointer;
          transition: all 200ms;
          div {
            position: absolute;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background-color: $orange;
            border: 2px solid $bg-grey;
            z-index: 1000;
            top: 3px;
            right: 3px;
          }

          svg {
            fill: $green;
            border-radius: 50%;
            &:hover {
              padding: 3px;
              background-color: rgba($green, 0.3);
            }
          }
        }
        .account-action {
          border-radius: 50%;
          min-width: 35px;
          min-height: 35px;
          background-color: rgba($green, 0.3);
          border: 1px solid $green;
          color: $green;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 200ms;
          overflow: hidden;
          z-index: 100;
          margin-right: 0px;

          a {
            text-decoration: none;
            color: $green;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 200ms;
          }
          img {
            width: 115%;
            height: auto;
            z-index: 50;
            transition: all 200ms;
          }

          &:hover {
            border-width: 2px;
            a {
              font-size: 14px;
            }
            img {
              filter: brightness(85%);
              width: 135%;
              background-color: $black;
            }
          }
        }
      }
    }
    .address-select-item {
      width: 100%;
      .address-header {
        h3 {
          font-size: $step-1;
          font-weight: 500;
          width: fit-content;
          margin-right: 10px;
        }
        * {
          margin-top: 0;
        }
        .checkout-address-info {
          svg {
            margin-left: 5px;
            stroke: $text-grey;
          }
        }
      }

      .address-actions {
        * {
          display: inline;
          height: fit-content;
        }
        a {
          font-size: $step-0;
          color: $green;
          &:hover {
            color: $hover-green;
          }
        }
        p {
          font-size: $step--1;
          margin: 0 10px;
          color: $grey;
        }
      }
    }

    .card-section.address-item {
      border: 0 $grey;
      border-radius: 8px;
      background-color: rgba($bg-grey, 0.4);
      padding: 25px;
      * {
        margin-top: 0px;
      }

      .address-header {
        h3 {
          margin-bottom: 25px;
        }
      }

      .address-info {
        // background-color: red;
        // height: 150px;
        h3 {
          font-weight: normal;
          font-size: 18px;
        }

        p {
          color: $dark-grey;
          font-size: 14px;
          margin: 0px;
        }
      }

      a {
        margin: 5px;
        font-size: 16px;
        cursor: pointer;
        color: $dark-grey;
        text-decoration: underline;
        font-weight: normal;
        &:hover {
          color: $green;
          svg {
            &:hover {
              stroke: $green;
            }
          }
        }
      }
    }

    .address-list-modal {
      .address-list-item-container {
        .address-list-item {
          // background-color: red;
          // height: 150px;
          //  border-radius: 8px;
          padding: 15px;
          margin: 25px 0;
          cursor: pointer;
          width: auto;

          h3 {
            font-weight: normal;
            font-size: 18px;
            margin-bottom: 0px;
          }

          p {
            color: $dark-grey;
            font-size: 14px;
            margin: 0px;
          }
        }
        .address-list-item-actions {
          a {
            color: $grey;
            &:hover {
              svg {
                stroke: $green;
              }
              color: $green;
            }

            margin-left: 5px;
          }
          .delete-address-confirmations {
            .deny {
              &:hover {
                svg {
                  stroke: $red;
                }
              }
            }
          }
        }
        &:hover {
          .address-list-item {
            * {
              color: $green;
            }
          }
        }
        svg {
          margin-right: 15px;
          background-color: $off_white;
          border-radius: 9px;
          padding: 8px;
          width: 35px;
          height: auto;
        }
      }

      .card-section.head {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .card-section {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .refresh-btn {
        color: $green;
        text-decoration: none;
      }

      a {
        cursor: pointer;

        &:hover {
          color: $green;
        }
      }

      .list-pagination {
        position: sticky;
        bottom: 0;
      }
    }

    * {
      font-family: "Public Sans", "Roboto", sans-serif;
    }
  }
}

.create-label {
  i {
    font-size: 1.5rem;
  }
}

.modal {
  position: fixed;
  margin: auto;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  border-radius: 8px;
  background-color: #3333;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;

  .backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1500;
  }

  .modal-content {
    background-color: $white;
    border-radius: 16px;
    box-shadow: 0px 0px 35px -10px $dark-grey;
    max-width: 700px;
    max-height: 90vh;
    z-index: 1501;
    padding: 25px;
    overflow-y: auto;
  }

  .modal-content.confirmation {
    width: 900px;
    padding: 35px;
    height: auto;
    align-items: center;
    border-radius: 16px;
    border: none;
    outline: none;
    box-shadow: none;
    & > div {
      text-align: center;
      width: 80%;
      button {
        width: 49%;
      }
    }
    svg {
      background-color: rgba($green, 0.1);
      stroke: $green;
      width: 100px;
      height: 100px;
      padding: 20px;
      border-radius: 50%;
      margin-bottom: 30px;
    }

    .delete-button {
      background: none;
      color: $red;
      transition: background-color 200ms;
      &:hover {
        background-color: rgba($red, 0.1);
      }
    }

    @include mQ(1200px) {
      width: 40vw;
      & > div {
        width: 80%;
        text-align: center;
        button {
          width: 100%;
        }
      }
    }
    @include mQ(768px) {
      width: 90vw;
      padding: 25px;
      & > div {
        width: 90%;
        text-align: center;
        button {
          width: 100%;
        }
      }
    }
  }
}

.refresh-btn {
  cursor: pointer;

  &:hover {
    color: $green;
  }
}

.default-tag {
  color: $green;
  font-size: 12px;
  font-weight: bold;
}

.card-section {
  &.last {
    &:has(.orders-list, .labels-table) {
      //overflow-x: auto;
    }
  }
}

.react-bootstrap-table {
  overflow: auto;

  button {
    min-height: 40px;
  }

  table {
    border: 0;
    table-layout: auto;
    color: $dark-grey;

    td,
    th {
      border: 0;
      border-top: 1px solid $light-grey;
      padding: 10px;
      font-size: 14px;
      width: fit-content;
      min-width: fit-content;
      overflow: hidden;
      vertical-align: middle;
    }

    td:first-child,
    th:first-child {
      padding: 20px 20px 20px 0px;
    }

    td:last-child,
    th:last-child {
      padding: 20px 0px 20px 20px;
    }

    th {
      font-weight: normal;
      color: $grey;
      border-top: none;
    }

    a.download-icon {
      width: fit-content;
      min-width: 150px;
      color: $green;
      font-weight: normal;
      display: flex;
      text-decoration: none;
      border-radius: 8px;
      cursor: pointer;

      img {
        width: 15px;
        height: auto;
        margin: 5px;
        margin-right: 10px;
      }
    }
    a.download-button {
      width: 100%;
      max-width: 150px;
      padding: 5px;
      border: 1px dashed $green;
      border-radius: 5px;
      font-size: $step--1;
      background-color: rgba($green, 0.1);
      white-space: nowrap;
      color: $green;
      font-weight: normal;
      margin: auto;
      &:hover {
        background-color: rgba($green, 0.6);
        text-decoration: none;
      }
    }

    .tracking-number {
      width: 100%;
      color: $green;
      font-weight: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
    }
  }

  tr {
    .table-expanded-row {
      padding: 25px 25px 0;

      & > div {
        border-top: 0;
        padding: 25px 25px 0;
        border-radius: 8px;
        background-color: $bg-grey;
        margin-bottom: 25px;
      }
      .expanded-row-details {
        .expanded-row-details-item {
          margin-bottom: 16px;
          & > div {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: auto;
            text-align: start;
          }
          p {
            color: $dark-grey;
            margin-bottom: 0;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.react-bootstrap-table-pagination {
  button,
  .btn-secondary {
    width: fit-content;
    background-color: $green;
    border: 0;

    &:hover,
    &:active,
    &.active {
      background-color: $hover-green;
    }
  }

  .react-bootstrap-table-pagination-list li {
    a {
      color: $green;
    }

    &.active a {
      color: white;
      background-color: $green;
    }
  }
}
.labels {
  .react-bootstrap-table {
    td,
    th {
      &:nth-child(1) {
      }
      &:nth-child(2) {
        @include mQ-min(1200px) {
          width: 16%;
        }
      }
      &:nth-child(3) {
      }
      &:nth-child(4) {
      }
      &:nth-child(5) {
        @include mQ-min(1200px) {
          width: 14%;
        }
      }
      &:nth-child(6) {
        @include mQ-min(1200px) {
          width: 14%;
        }
      }
      &:nth-child(7) {
        @include mQ-min(1200px) {
        }
      }

      &:nth-child(8) {
        padding-left: 0;
        padding-right: 0;
        @include mQ-min(1200px) {
          width: fit-content;
        }
        p {
          font-size: $step--1;
          color: $yellow-2;
          font-weight: 500;
          padding: 8px;
          margin-bottom: 0;
          background-color: rgba($yellow-2, 0.1);
          text-align: center;
          border-radius: 8px;
          &.error {
            background-color: rgba($red, 0.1);
            color: $red;
          }
        }
      }
      &:nth-child(9) {
        @include mQ-min(1200px) {
          width: 100px;
        }
        a {
          font-weight: normal;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &:nth-child(1) {
        width: 50px;
        svg {
          width: 30px;
          height: auto;
          padding: 5px;
          border-radius: 5px;
          &:hover {
            background-color: rgba($green, 0.2);
          }
        }
        a {
          &:hover {
            background: none;
          }
        }
      }
    }
  }
}

.orders,
.dash-orders {
  position: relative;
  &.settings-card {
    .card-section.last {
      @include mQ(1200px) {
        padding: 0;
        &:has(.spinner-border) {
          background-color: $white;
          padding: 30px 15px;
        }
      }
    }
  }
  .react-bootstrap-table {
    td,
    th {
      //text-align: center;
      &:nth-child(1) {
        width: 50px;
        svg {
          width: 30px;
          height: auto;
          padding: 5px;
          border-radius: 5px;
          &:hover {
            background-color: rgba($green, 0.2);
          }
        }
        a {
          &:hover {
            background: none;
          }
        }
      }
      &:nth-child(2) {
        width: 23ch;
        @include mQ-min(992px) {
          //width: 60px;
          h5 {
            font-size: 16px;
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: clip;
            overflow: hidden;
          }
        }
        img {
          width: 30px;
          height: auto;
          max-height: 50px;
          object-position: center;
          object-fit: contain;
          margin-right: 10px;
        }
        svg {
          stroke: $green;
          min-width: 30px;
          min-height: 30px;
        }
      }
      &:nth-child(3) {
        @include mQ-min(992px) {
          width: 75px;
        }
      }
      &:nth-child(4) {
        @include mQ-min(992px) {
          width: 15ch;
          p {
            font-size: 14px;
            width: 100%;
          }
        }
      }
      &:nth-child(5) {
        text-align: start;
        p {
          width: 100%;
          justify-content: flex-start;
          padding: 0px;
          span,
          &.customer-address {
            font-size: 14px;
            color: $dark-grey;
          }
        }
        @include mQ-min(992px) {
          width: 23ch;
          height: fit-content;
        }
      }
      &:nth-child(6) {
        @include mQ-min(922px) {
          width: 20ch;
        }
      }

      &:nth-child(7) {
        @include mQ-min(992px) {
          width: 9ch;
        }

        p {
          color: $green;
          font-weight: 500;
          font-size: 16px;
        }
      }
      &:nth-child(8) {
        a {
          @include buttonMain;
          background: none;
          border: 1px solid $green;
          color: $green;
          &:hover {
            background-color: rgba($green, 0.1);
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        width: 20ch;
      }
      &:nth-child(9) {
        width: 23ch;
      }
      &:nth-child(10) {
        justify-content: flex-end;
        .table-actions {
          & > div {
            width: 30px;
          }
          a {
            svg {
              width: 30px;
              height: auto;
              stroke: $green;
            }
          }
        }
        width: 120px;
      }
    }

    tr {
      .table-expanded-row {
        .expanded-row-details {
          .expanded-row-payment-details {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 12px;
            .payment-detail-item {
              min-width: fit-content;
              width: calc(20% - 9.6px);
              height: 80px;
              margin-right: 12px;
              margin-bottom: 12px;
              padding: 12px 16px;
              border-radius: 4px;
              background-color: $white;
              &:last-child {
                margin-right: 0;
              }
              p {
                &.info {
                  color: rgba($text-grey, 0.8);
                  font-size: 14px;
                  margin-bottom: 4px;
                }
              }
              img {
                width: 50px;
                height: auto;
              }
              & > *:last-child {
                padding-left: 0;
              }
            }
          }
          a {
            color: $green;
          }
          .expanded-row-details-item {
            margin-top: 12px;
            &.header {
              p {
                font-size: 14px;
                color: $text-grey;
              }
              p {
                &:first-child {
                  width: 100px;
                }
              }
            }
            & > div {
              width: 80%;
              grid-template-columns: auto auto auto auto;
              & > * {
                margin-right: 24px;
                &:nth-child(2) {
                  width: 80px;
                }
                &:nth-child(3) {
                  width: 150px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            img {
              width: 100px;
              height: auto;
              max-height: 130px;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }
    }
  }
}

.ship-label-button {
  @include buttonMain;
  max-width: 18rem;
  color: white;
  background-color: $green;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: $hover-green;
    text-decoration: none;
    color: $white;
  }

  &:active {
    background-color: $active-green;
  }
  &:focus {
    border: none;
    outline: 0;
  }
}

.labels-table {
  &.test-mode {
    a {
      color: $yellow-2;
    }
    svg {
      stroke: $yellow-2;
    }
  }
}
.dash-labels {
  &.settings-card {
    .card-section.head {
      padding: 25px;
    }

    .card-section.last:not(:has(.spinner-border)) {
      @include mQ(1200px) {
        padding: 0;
      }
    }
  }
}
.labels,
.orders-list {
  @include mQ-min(1200px) {
    //min-width: 1150px;
  }

  .popup-modal {
    position: fixed;
    p.info {
      color: $grey;
    }
    .popup-item {
      color: $black;
      svg {
        stroke: $black;
      }
    }
    a {
      all: unset;
    }
  }
  .react-bootstrap-table {
    .table-actions {
      position: relative;
      svg {
        padding: 3px;
        border-radius: 5px;
        &:hover {
          background-color: rgba($green, 0.1);
        }
      }
      .table-actions-container {
        display: block;
        position: absolute;
        background-color: $white;
        box-shadow: 0 0 10px -3px $grey;
        border-radius: 8px;
        padding: 10px;
        right: 5px;
        opacity: 0;
        height: 0;
        overflow: hidden;

        .table-actions-item {
          padding: 8px;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin-bottom: 0;
          }
          svg {
            margin-right: 20px;
            width: 30px;
            height: auto;
          }
          &:hover {
            background-color: rgba($green, 0.1);
            p {
              color: $green;
            }
            svg {
              stroke: $green;
            }
          }
        }
        &.active {
          opacity: 100;
          height: auto;
        }
      }
    }
  }
}

.pagination-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //overflow-x: auto;
  flex-wrap: wrap;
  @include mQ(992px) {
    padding: 15px;
    background-color: $white;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .goto-page-action {
    @include mQ(1200px) {
      margin-bottom: 8px;
    }
    p.info {
      font-size: 12px;
    }
    input {
      @include textBox;
      margin-right: 5px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button,
      &[type="number"] {
        -webkit-appearance: none;
        -moz-appearance: textfield;
      }
    }
    button {
      padding: 5px 10px;
      background: none;
      border: 1px solid $grey;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      &:hover {
        background-color: rgba($green, 0.2);
        border-color: transparent;
        color: $green;
      }
    }
    input,
    button {
      width: 40px;
      height: 35px;
    }
  }

  .page-size {
    width: 50px;
    button {
      width: fit-content;
    }
  }
  .page-list {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
    margin-left: 50px;
    & > a,
    & > span {
      padding: 5px 10px;
      color: $black;
      margin-left: 5px;
      border-radius: 8px;
      border: 1px solid transparent;
    }
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $grey;
      font-weight: 400;
      &.active {
        text-decoration: none;
        color: $white !important;
        background-color: $green;
        border-color: transparent;
      }
      &:hover {
        background-color: rgba($green, 0.2);
        border-color: transparent;
        color: $green;
      }
    }
    .previous-action,
    .next-action {
      padding: 5px 6px;
      margin-left: 5px;
      border-radius: 8px;
      transition: 0.3s all;
    }
    @include mQ(1200px) {
      margin-left: 0;
      margin-bottom: 8px;
      a {
        &:first-child {
          margin: 0;
        }
      }
    }
  }

  svg {
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
    stroke: $green !important;
    width: 20px !important;
    height: auto !important;
  }
}
