@import "variables";
@import "mixins";

.auth {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.box {
  // box-shadow: 0 0 15px 2px $shadow;
  // background-color: $white;
  // border-radius: 7px;
  // margin: 20px auto;
  & > * {
    margin: 10px auto;
  }
}
.btn1 {
}
.btn2 {
  background-color: #fff0;
  border: 2px solid $green;
  color: $green;
  &:hover {
    background-color: $light-blue;
    color: $hover-green;
    border-color: $hover-green;
  }
}

.patch {
  height: 100vh;
  h2 {
    color: $green;
  }
  .header {
    height: max-content;
    text-decoration: none;
    margin-bottom: $space-m-2xl;
    & > div {
      border-radius: 50%;
      background-color: $green;
      width: 50px;
      height: 50px;
      padding: 12px;
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 100%;
        height: auto;
      }
    }

    h2 {
      color: $green;
      font-weight: 600;
      font-size: 24px;
    }
  }
  p.divider {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $grey;
    line-height: 0.1em;
    margin: 50px 0;

    .text {
      background: $white;
      color: $dark-grey;
      padding: 0 10px;
    }
  }
  .checkbox {
    margin: 15px 0px;
    cursor: pointer;
    svg {
      opacity: 0;
      stroke: $white;
      margin: 0px;
      transition: 0.2s all;
    }

    .check {
      width: 20px;
      height: 20px;
      border: 1px solid $grey;
      border-radius: 3px;
      margin: 0px 5px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s all;

      &:hover {
        border-color: $dark_grey;
      }
    }
    color: rgba($dark-grey, 0.5);
    font-size: 14px;
    a {
      color: $green;
      font-size: 14px;
      text-decoration: underline;
      margin: 0px;
      &:hover {
        color: $dark_grey;
      }
    }

    &.active {
      .check {
        background: $green;
      }
      svg {
        opacity: 1;
      }
    }
  }
}

.googlesignin {
  background-color: $light-grey;
  color: $dark-grey;
  font-weight: normal;
  &:hover {
    background-color: rgba($green, 0.3);
  }
}

a.forgotLink {
  text-align: left;
  text-decoration: none;
  color: $dark-grey;
  margin: 0px;
  &:hover {
    color: $green;
  }
}

.switchForm {
  margin-top: 100px;
  p {
    color: $dark-grey;
  }
  a {
    text-align: left;
    text-decoration: none;
    color: $green;
    margin: 0px;
    &:hover {
      color: $dark-grey;
    }
  }
}
form {
  width: 100%;
  svg {
    stroke: $grey;
  }
  & * {
    margin-top: 10px;
    label {
      span {
        margin: 0;
      }
    }
  }
}

.auth .error {
  color: red;
}

.auth,
.account-form {
  form * {
    margin: unset;
  }

  .patch {
    .box {
      .error-block,
      .success-block {
        padding: 25px;
        border-radius: 10px;
        width: 100%;
        svg {
          margin-right: 10px;
        }
      }
      .error-block {
        background-color: rgba($red, 0.1);
        color: $red;
        svg {
          stroke: $red;
        }
      }
      .success-block {
        background-color: rgba($green, 0.1);
        color: $green;
        svg {
          stroke: $green;
        }
      }
    }
  }

  label {
    text-align: left;
    color: $dark-grey;
    font-size: $step-0;
    margin: 0px;
  }

  .password-field {
    position: relative;
    .show-pass-button {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(calc(-50% - 11px));
      margin: 0;
      svg {
        margin: 0;
      }
    }
  }

  input {
    @include textBox;
    margin-top: 4px;
    margin-bottom: 24px;
    font-size: $step-0;
    &::placeholder {
      color: $grey;
    }
  }
  & > div:nth-child(1) {
    width: 100%;
    padding: 70px;
    .header {
      height: max-content;
      text-decoration: none;
      margin-bottom: $space-m-2xl;
      & > div {
        border-radius: 50%;
        background-color: $green;
        width: 50px;
        height: 50px;
        padding: 12px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: auto;
        }
      }

      h2 {
        color: $green;
        font-weight: 600;
        font-size: 24px;
      }
    }

    .why {
      background-color: rgba(darken($bg-grey, 10%), 0.3);
      border-radius: 12px;
      padding: 15px;
      p {
        margin-bottom: 0px;
      }
    }
  }
  &.login,
  &.signup,
  &.verify {
    & > div:nth-child(1) {
      background-color: #1e8b7c;
      * {
        color: $white;
      }
      & > a {
        flex: 0.1 1 auto;
      }
      & > *:nth-child(2) {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
      }
      & > .why {
        flex: 0 1 auto;
      }
      .header {
        & > div {
          background-color: $white;
        }

        h2 {
          color: $white;
        }
      }
      .intro {
        width: 90%;
        .visual,
        svg {
          margin-top: 50px;
          width: 100%;
        }
        p {
          font-size: $step-1;
        }
      }
    }
  }

  &.verify {
    .patch {
      span.email {
        color: $green;
      }
      p.info {
        color: $text-grey;
      }
      a.signin {
        @include buttonBorder;
        background: none;
        width: fit-content;
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        &:hover {
          background-color: rgba($green, 0.2);
        }
      }
      p.change-email {
        color: $text-grey;
        a {
          color: $green;
          &:hover,
          &:active {
            color: $dark-grey;
            text-decoration: none;
          }
        }
      }
    }
  }
}
