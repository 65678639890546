@import "variables";
@import "mixins";

.label-template {
  &.settings-card {
    background-color: $bg-grey;
    .card-section {
      input.template-name {
        border-color: $grey;
        &.error {
          border-color: $red;
        }
      }
      &.head {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
      }
      svg {
        margin-right: 0px !important;
        background: none !important;

        &:hover {
          color: $green;
        }
      }

      div.customs-info {
        padding: 0px;
      }

      .template-fields {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 35px auto;
      }

      .check-template {
        margin-right: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .template-checkbox {
          border-radius: 5px;
          border: 1px solid $grey;
          background: none;
          width: 20px;
          height: 20px;
          cursor: pointer;
          padding: 3px;
          margin: auto;
          &:hover {
            border-color: $green;
          }

          svg {
            display: none;
            width: 20px;
            height: auto;
            padding: 0px;
          }
        }

        &.active {
          .template-checkbox {
            border-radius: 5px;
            border: none;
            background-color: $green;

            svg {
              display: flex;
            }
          }
        }
      }
      .package-details {
        .template-checkbox {
          margin: 0px;
        }
      }
      .customs-items-list {
        .template-checkbox {
          margin: 0px;
        }
        .customs-item-actions {
          svg {
            padding: 0px;
            border-radius: 0px;
          }
        }
      }

      .template-customs-close {
        svg {
          stroke: $grey;
          &:hover {
            stroke: $green;
          }
        }
      }
      .template-customs-add {
        svg {
          stroke: $green;
          &:hover {
            stroke: $dark-grey;
          }
        }
      }

      .template-create-action {
        button {
          width: 49%;
          margin-bottom: 15px;
          @include mQ(992px) {
            width: 100%;
          }
          &.button-secondary {
            background-color: rgba($green, 0.2);
            color: $green;
            &:hover {
              background: rgba($green, 0.6);
            }
          }
        }

        .edit-actions {
          button {
            width: 32%;
            @include mQ(992px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &.test-mode {
    &.settings-card {
      .card-section {
        &.head {
          svg {
            &:hover {
              stroke: $yellow;
            }
          }
        }
        .check-template {
          .template-checkbox {
            &:hover {
              border-color: $yellow-2;
            }
          }
          &.active {
            .template-checkbox {
              background-color: $yellow-2;
              svg {
                stroke: $black;
              }
            }
          }
        }
        .template-customs-add {
          svg {
            stroke: $yellow-2;
          }
        }
        .template-customs-close {
          svg {
            &:hover {
              stroke: $yellow-2;
            }
          }
        }
        .customs-info {
          .customs-items-list {
            button.customs-item {
              border-color: $yellow-2;
              color: $yellow-2;
            }

            .customs-list-item {
              .customs-item-actions {
                svg {
                  &:hover {
                    stroke: $yellow-2;
                  }
                }
              }
            }
          }
        }

        .template-create-action {
          button {
            background-color: $yellow-2;
            color: $black;
            &:hover {
              background-color: rgba($yellow-2, 0.6);
            }
          }
          .button-secondary {
            background-color: rgba($yellow-2, 0.2);
            color: $yellow-2;
          }
        }
      }
    }
  }
}

.modal-content.settings-card.template-list {
  min-height: 40vh;
  background-color: $bg-grey;
  .card-section {
    border-radius: 8px;
    &.head {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      position: sticky;
      a {
        color: $green;
        font-weight: normal;
        &:hover {
          color: $black;
        }
      }
      svg {
        background: none;
        color: $black;
        &:hover {
          stroke: $green;
        }
      }
    }
  }
  .list-content {
    padding: 25px;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    .empty-list {
      padding: 25px;
      width: 100%;
      height: 100%;
      svg {
        margin-bottom: 25px;
      }
      p {
        color: #8f96a4;
      }
      button {
        width: 50%;
        height: fit-content;
        padding: 16px 32px;
        margin: 35px auto;
        font-weight: normal;
      }
    }
    .template-item.card-section {
      padding: 25px;
      margin-bottom: 25px;
      svg {
        background: none;
        stroke: $grey;
        &:hover {
          stroke: $green;
        }
      }
      .title {
        margin-bottom: 15px;
        .template-actions {
          svg {
            margin: 0;
          }
        }
      }
      p {
        font-size: $step--1;
        font-weight: 400;
        &.template-name {
          font-size: $step-0;
          font-weight: 600;
        }
      }
      .template-details {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 1fr;
        .template-item-actions {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          a {
            font-weight: normal;
            color: $green;
            &:hover {
              color: $black;
            }
          }
        }

        p.selected {
          background: rgba($light-grey, 0.5);
          color: $dark-grey;
          padding: 5px;
          border-radius: 5px;
          margin-bottom: 0px;
          font-size: 14px;
        }
      }
    }
  }

  &.test-mode {
    .card-section {
      &.head {
        a {
          color: $yellow-2;
        }
        svg {
          &:hover {
            stroke: $yellow-2;
          }
        }
      }
    }
    .list-content {
      .empty-list {
        button {
          background-color: $yellow-2;
          color: $black;
          &:hover {
            background-color: rgba($yellow-2, 0.6);
          }
        }
      }

      .template-item.card-section {
        svg {
          &:hover {
            stroke: $yellow-2;
          }
        }
        .template-details {
          .template-item-actions {
            a {
              color: $yellow-2;
              &:hover {
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}
