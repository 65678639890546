@import "variables";
@import "mixins";

section.masthead {
  background-color: $white;
  padding-right: $space-m-3xl;
  margin-top: $space-m-2xl;
  img {
    width: 100%;
    height: auto;
  }
  div {
    padding: 0px;
  }

  .content {
    padding-left: $space-m-3xl;
    h1 {
      margin-bottom: $space-s-m;
      font-weight: bold;
    }
    .main {
      margin-bottom: $space-m-2xl;
      @include mQ(768px) {
        margin-bottom: 24px;
      }
    }
    .action {
      width: 100%;
      margin-bottom: $space-s-6xl;
      @include mQ(768px) {
        margin-bottom: 40px;
      }
      a {
        margin-right: 10px;
        width: clamp(127px, 45%, 173px);
      }
      p {
        margin-bottom: 0px;
        &.info {
          font-size: $step--1;
        }
      }
    }
  }

  .review {
    p {
      font-size: $step-0;
    }
    @include mQ(768px) {
      margin-bottom: 24px;
    }
  }

  .image {
    @include mQ-min(992px) {
      padding-left: $space-m-3xl;
      padding-top: $space-s-m;
    }
  }
}
