// Variables

// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

//shipshap colors

$white: #ffffff;
$black: #000;
$green: #1e8b7c;
$hover-green: #157064;
$active-green: #00564b;
$light-green: #3cd8c3;
$dark-green: #2a6160;
$link-active: #00a1c3;
$dark-grey: #5e5e5e;
$grey: #c2c2c2;
$light-grey: #e5e5e5;
$text-grey: #828282;
$light-blue: #eefcff;
$light-blue-2: #eef3ff;
$dark-blue: #2d55b1;
$dark-blue-2: rgb(19, 44, 86);
$blue: #d5ece9;
$shadow: #b4c1c4;
$bg-grey: #edf0f6;
$off-white: #f6fafa;
$green-10: #f2fffd;
$yellow: rgba(255, 217, 118, 1);
$yellow-2: #fab505;
$camouflage: #51513d;
$beige-2: #e3dc95;
$light-beige: #fdf9ec;
$orange: #f95828;
$blue-2: #496abf;
$light-blue-3: #f0f6ff;

//gifts colors

$beige: #f2eee9;
$dark-beige: #e1dace;
$mustard: #d69547;

//intro colors
$intro-blue: #060a2b;
$intro-orange: #ffa542;
$intro-off-white: #f9fbfc;

//fluid scaling
$step--2: clamp(0.63rem, calc(0.62rem + 0.02vw), 0.64rem);
$step--1: clamp(0.74rem, calc(0.72rem + 0.08vw), 0.8rem);
$step-0: clamp(0.88rem, calc(0.84rem + 0.18vw), 1rem);
$step-1: clamp(1.03rem, calc(0.97rem + 0.31vw), 1.25rem);
$step-2: clamp(1.22rem, calc(1.12rem + 0.49vw), 1.56rem);
$step-3: clamp(1.44rem, calc(1.29rem + 0.74vw), 1.95rem);
$step-4: clamp(1.7rem, calc(1.48rem + 1.06vw), 2.44rem);
$step-5: clamp(2rem, calc(1.7rem + 1.5vw), 3.05rem);

$space-2xs-xs: clamp(0.44rem, calc(0.31rem + 0.63vw), 0.88rem);
$space-s-m: clamp(0.88rem, calc(0.73rem + 0.71vw), 1.69rem);
$space-m-2xl: clamp(1.31rem, calc(1.05rem + 1.3vw), 2.81rem);
$space-m-3xl: clamp(1.31rem, calc(0.37rem + 4.73vw), 6.75rem);
$space-m-4xl: clamp(1.31rem, calc(0.79rem + 2.59vw), 3.13rem);
$space-s-6xl: clamp(0.88rem, calc(0.24rem + 3.15vw), 4.5rem);
$space-3xl-4xl: clamp(2.19rem, calc(1.92rem + 1.34vw), 3.13rem);
$space-3xl-7xl: clamp(2.19rem, calc(1.37rem + 4.11vw), 5.06rem);
$space-5xl-9xl: clamp(2.63rem, calc(1.45rem + 5.89vw), 6.75rem);
$space-5xl-7xl: clamp(2.63rem, calc(1.93rem + 3.48vw), 5.06rem);
$space-6xl-11xl: clamp(3.5rem, calc(1.93rem + 7.86vw), 9rem);
