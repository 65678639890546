@import "variables";
@import "mixins";

.terms-of-service {
  position: relative;
  padding: $space-m-3xl;

  .bg-green-block {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: $green;
    height: 90vh;
    z-index: -1;
  }
  .main-content {
    padding: $space-m-2xl;
    padding-top: $space-m-3xl;
    border-radius: 20px;
    background-color: $gray-200;
    max-width: 1200px;
    margin: auto;
    margin-bottom: $space-m-2xl;
    .heading {
      text-align: center;

      h1 {
        color: $dark-blue-2;
        margin-bottom: $space-m-2xl;
      }
      p {
        font-weight: 300;
      }
    }

    p {
      color: rgba($dark-blue-2, 0.8);
      &.info {
        color: grey;
      }
    }

    a {
      color: $dark-blue-2;
    }

    .content {
      padding-top: $space-m-2xl;
      .accordion-item {
        background-color: $white;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 15px;
      }
      button {
        background: none;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;
        font-size: $step-1;
        color: $dark-blue-2;
        max-width: none;
        text-align: left;
        svg {
          stroke: $green;
          transform: rotate(0deg);
          transition: all 100ms linear;
        }

        &.collapsed {
          svg {
            transform: rotate(-90deg);
          }
        }
      }
      p {
        font-size: $step--1;
      }
    }
  }

  .cta {
    padding: 0px;
    border-radius: 20px;
    background-color: $green;
    max-width: 1200px;
    overflow: hidden;
    h1 {
      color: white;
      font-weight: bold;
      font-size: $step-5;
      line-height: 120%;
      margin-bottom: $space-m-2xl;
    }

    a.button {
      @include buttonMain;
      color: $green;
      background-color: $white;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 320px;
      width: 50%;

      @include mQ(768px) {
        width: 90%;
        max-width: none;
      }

      &:hover,
      &:active {
        text-decoration: none;
        background-color: rgba($white, 0.4);
      }
    }

    .content {
      padding: $space-m-3xl $space-m-2xl;
    }

    .image {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;
      padding: 0px;
      img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: auto;
        @include mQ(992px) {
          position: relative;
        }
      }
    }
  }
}
