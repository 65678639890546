@import "variables";
@import "mixins";

.dashboard {
  margin: auto;
  padding: 0px;
  padding-bottom: 25px;
  h3 {
  }

  .modal {
    .modal-content.welcome-tour {
      padding: 0;
      overflow: hidden;
      max-width: unset;
      width: 70%;
      border-radius: 12px;

      @include mQ(992px) {
        width: 90%;
      }
    }
  }

  .dash-profile-completion {
    position: relative;
    background-color: rgba($green, 0.1);
    padding: 50px 32px 32px;
    border-radius: 12px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 0.15fr 0.85fr;
    @include mQ(992px) {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
      .progress-bar-track {
        background-color: rgba($green, 0.2);
        position: relative;
        border-radius: 25px;
        width: 100%;
        height: 8px;
        margin-bottom: 20px;
        .progress-bar {
          position: absolute;
          background-color: $green;
          top: 0;
          left: 0;
          border-radius: 25px;
          height: 8px;
        }
      }
      h2.percent-value {
        color: $black;
        font-weight: bold;
      }
    }
    h3 {
      font-weight: 500;
    }
    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        color: $dark-grey;
      }

      button {
        max-width: 170px;
      }
      a {
        font-weight: normal;
        color: $green;
        &:hover {
          color: $black;
          text-decoration: none;
        }
      }
    }
    .completion-content {
      height: 100%;
      margin-right: 90px;
      .warning {
        margin: 0 5px;
      }
      .completion-percentage {
        padding: 0 25px;
        margin-bottom: 5px;
        .svg-pi-track {
          stroke: rgba($green, 0.2);
        }
        .svg-pi-indicator {
          stroke: rgba($green, 1);
        }
        .svg-pi-label__progress {
          color: $black;
        }
      }
      .completion-percentage + div {
        min-width: fit-content;
        p {
          white-space: nowrap;
        }
        svg {
          width: 14px;
          height: 14px;
        }
      }
      * {
        color: $green;
        stroke: $green;
      }
    }
    .close-profile-completion {
      position: absolute;
      top: 10px;
      right: 10px;
      svg {
        stroke: $green;
        &:hover {
          stroke: $black;
        }
      }
    }
  }

  .dash-create-label-banner {
    padding: 0px !important;
    overflow: hidden;
    height: fit-content;
    min-height: 300px;
    div {
      &:nth-child(1) {
        padding: 35px;
        display: grid;
        grid-template-rows: 0.4fr 0.6fr;
        gap: 20px;
        grid-template-columns: auto;
        p.welcome-message {
          color: $dark-grey;
          font-size: $step-1;
          font-weight: 500;
        }
        h2 {
          font-weight: 600;
          margin-bottom: 15px;
        }
        p {
          //margin-bottom: 15px;
        }
      }
      &:nth-child(2) {
        max-height: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
      }
      img {
        width: auto;
        height: 100%;
        position: absolute;
      }
    }
  }

  .dash-stats {
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 25px;

    padding: 0;
    .stat-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg {
        fill: $green;
        margin-right: 15px;
        background-color: $off-white;
        border-radius: 9px;
        padding: 3px;
        width: auto;
        height: 80%;
      }
      p {
        color: $grey;
      }
      h4 {
        margin: 0px;
        font-weight: 700;
        font-size: 32px;
        color: $dark-grey;
      }

      &:nth-child(1) {
        border-right: 1px solid $bg_grey;
      }
    }
    .v-divider {
      width: 1px;
      height: 100%;
      border-right: 1px solid $bg-grey;
      min-height: 80px;
    }

    @include mQ(768px) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(1, 1fr);
      background: none !important;
      padding: 0px !important;
      box-shadow: none !important;
      .stat-item {
        border-radius: 16px;
        box-shadow: 0px 0px 15px 0px rgba($black, 0.1);
        padding: 20px;
        background-color: $white;
        align-items: flex-start;
        justify-content: space-between;
        &:nth-child(1) {
          border: none;
        }

        h4 {
          margin: 0px;
        }
      }
    }
  }

  .dash-graphs {
    .settings-card {
      width: 100%;
      min-height: 400px;
      @include mQ(992px) {
        width: 100%;
        min-height: unset;
      }

      .card-section.head {
        margin-bottom: 5px !important;
      }

      .card-section.last {
        min-height: calc(100% - 110px);
        max-height: 400px;
        .empty {
          width: 100%;
          min-height: 350px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            text-align: center;
            color: rgba($dark-grey, 0.7);
            max-width: 388px;
            white-space: break-spaces;
          }
        }
      }
    }
    .btn-primary {
      border: none;
      background-color: rgba($green, 0.1);
      color: $green;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      width: 140px;
      border-radius: 9px;
      &:focus {
        box-shadow: none;
        border: 1px solid $green;
      }
      &:hover {
        background-color: rgba($green, 0.3);
      }
    }
  }
  .dash-labels {
    &.settings-card {
      .card-section {
        &.head {
          margin-bottom: 5px !important;
        }
        &.last {
          .empty {
            min-height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            a {
              width: 100%;
              max-width: 400px;
              white-space: nowrap;
            }
            p {
              color: $grey;
            }
            & > * {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
    .more-button {
      a {
        color: $green;
        font-weight: 400;
        transition: 0.3s all;
        &:hover {
          color: $black;
          text-decoration: none;
        }
      }
    }
  }

  .dash-stores {
    a {
      font-weight: normal;
      color: $green;
      cursor: pointer;
    }
    .store-item {
      margin: 25px;
      margin-left: 0px;
      border: 1px solid $light-grey;
      padding: 20px 25px;
      border-radius: 8px;
      width: calc(calc(83.3vw - 4rem - 60px) / 3 - 28px);
      @include mQ(1200px) {
        width: calc(calc(100vw - 4rem - 60px) / 3 - 28px);
      }
      &.empty {
        padding: 25px;
        padding-top: 35px;
        transition: all 200ms;
        div {
          background-color: $green;
          border-radius: 50%;
          padding: 8px;
          svg {
            stroke: $white;
          }
        }
        &:hover {
          border-color: $green;
          border-width: 2px;
          p {
            font-weight: normal;
          }
        }
      }
      .head {
        height: fit-content;
        .dropdown-toggle {
          color: $green;
          padding: 0px 5px;
          background: none;
          border-radius: 5px;
          font-weight: normal;
          display: flex;
          align-items: center;
          transition: all 200ms;
          &::after {
            margin-left: 10px;
          }
          &:hover {
            text-decoration: none;
            padding: 2px 6px;
            background-color: rgba($green, 0.25);
            color: $dark-green;
          }
        }
        svg {
          padding: 3px;
          border-radius: 5px;
          transition: all 200ms;
          &:hover {
            background-color: rgba($green, 0.2);
            padding: 5px;
          }
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 8px;
          margin-right: 5px;
        }
      }
      p {
        color: rgba($dark-grey, 0.6);
        font-weight: 200;
      }
    }
  }
  .dash-orders {
    &.settings-card {
      .card-section {
        &.head {
          margin-bottom: 5px !important;
        }
        &.last {
          .empty {
            min-height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            a {
              width: 100%;
              max-width: 400px;
              white-space: nowrap;
            }
            p {
              color: $grey;
            }
            & > * {
              margin-bottom: 24px;
            }
          }
        }
      }
    }
    .more-button {
      a {
        color: $green;
        transition: 0.3s all;
        &:hover {
          color: $black;
          text-decoration: none;
        }
      }
    }
  }
  .dash-others {
    a {
      color: $green;
      font-weight: normal;
      cursor: pointer;
    }
    .videos,
    .help {
      width: 49%;
      margin: 25px 0px;
      border: 1px solid $light-grey;
      padding: 20px 25px;
      border-radius: 8px;
      position: relative;
      padding-bottom: 50px;
      .title svg {
        margin-right: 15px;
        background-color: $off-white;
        border-radius: 9px;
        padding: 3px;
        width: 35px;
        height: auto;
      }

      .more {
        position: absolute;
        bottom: 25px;
      }
    }

    .videos {
      .video-item {
        margin-bottom: 25px;
        padding: 8px 0px;
        border-radius: 8px;
        transition: all 200ms;
        .thumbnail {
          width: 80px;
          height: 80px;
          border-radius: 5px;
          background-color: $grey;
          margin-right: 25px;
        }
        .category,
        .length {
          font-size: 12px;
          color: $grey;
          margin-bottom: 0px;
        }
        .title {
          font-size: 16px;
        }
        &:hover {
          background-color: rgba($green, 0.1);
          padding-left: 8px;
        }
      }
    }
    .help {
      .help-item {
        margin-bottom: 15px;
        .category {
          color: $grey;
          font-size: 12px;
          margin-bottom: 0px;
        }
        .title {
          font-size: 16px;
        }
        svg {
          color: $grey;
        }
      }
    }
  }
  // max-width: 1440px;
  // h1 {
  //   font-weight: normal;
  //   font-size: 28px;
  // }

  // hr {
  //   border-color: $light-grey;
  //   margin: 25px auto;
  // }

  // //min-width: 1366px;
  // .labelStats {
  //   .dropdown {
  //     width: 200px;

  //     button {
  //       border-radius: 8px;
  //       background-color: #0000;
  //       color: $dark-grey;
  //       border: 1px solid $dark-grey;
  //       display: flex;
  //       justify-content: space-between;
  //       align-items: center;
  //     }

  //     button:hover,
  //     button:active {
  //       background-color: $green;
  //       color: $white;
  //     }

  //     .dropdown-menu {
  //       border-radius: 10px;
  //     }

  //     .dropdown-item {
  //       font-weight: 500;

  //       &.active,
  //       &:active {
  //         background-color: $green;
  //       }

  //       &:hover {
  //         background-color: $light-blue;
  //         color: $green;
  //         font-weight: 500;
  //       }
  //     }
  //   }

  //   .text-bubble {
  //     border: 2px solid $green;
  //     border-radius: 8px;
  //     color: $green;
  //     padding: 15px 50px;

  //     //padding: 5rem;
  //     //width: 100%;
  //     //height: 100%;
  //     p {
  //       font-size: 40px;
  //       font-weight: 500;
  //     }

  //     .labels-outline {
  //       font-size: 18px;
  //       font-weight: 400;
  //     }

  //     @include mQ-min(1200px) {
  //       p {
  //         font-size: 60px;
  //         font-weight: 500;
  //       }

  //       .labels-outline {
  //         font-size: 24px;

  //       }
  //     }
  //   }
  // }

  .search-bar {
    position: relative;

    .search-icon {
      position: absolute;
      margin: 16px 25px;
      height: 20px;
      top: 0;
      left: 0;
      width: auto;
      align-self: center;
      transition: opacity 300ms linear;
    }

    .form-control {
      padding-left: 65px;

      &::placeholder {
        color: $grey;
        font-size: 16px;
      }
    }

    .form-control:focus {
      & + .search-icon {
        opacity: 0;
      }

      & {
        padding: 15px;
      }
    }
  }

  &.test-mode {
    .dash-stats {
      svg {
        fill: $yellow;
      }
    }

    .dash-profile-completion {
      background-color: rgba($yellow-2, 0.1);
      @include mQ(992px) {
        .progress-bar-track {
          background-color: rgba($yellow-2, 0.2);
          .progress-bar {
            background-color: $yellow-2;
          }
        }
        h2.percent-value {
          color: $black;
        }
      }
      & > div:nth-child(2) {
        button {
        }
        a {
          color: $yellow-2;
          &:hover {
            color: $black;
            text-decoration: none;
          }
        }
      }
      .completion-content {
        .completion-percentage {
          .svg-pi-track {
            stroke: rgba($yellow-2, 0.2);
          }
          .svg-pi-indicator {
            stroke: rgba($yellow-2, 1);
          }
          .svg-pi-label__progress {
            color: $black;
          }
        }
        * {
          color: $yellow-2;
          stroke: $yellow-2;
        }
      }
      .close-profile-completion {
        svg {
          stroke: $yellow-2;
          &:hover {
            stroke: $black;
          }
        }
      }
    }

    .dash-create-label-banner {
      div {
        &:nth-child(1) {
          a.ship-label-button {
            background-color: $yellow-2;
            &:hover {
              background-color: rgba($yellow-2, 0.1);
              color: $black;
            }
          }
        }
      }
    }

    .dash-graphs {
      .btn-primary {
        background-color: rgba($yellow-2, 0.1);
        color: $yellow-2;
        &:focus {
          border: 1px solid $yellow;
        }
        &:hover {
          background-color: rgba($yellow, 0.3);
        }
      }
    }

    .dash-labels,
    .dash-orders {
      .ship-label-button {
        background-color: $yellow-2;
        color: $white;
      }
      .more-button {
        a {
          color: $yellow-2;
          background: none;
          transition: 0.3s all;
          &:hover {
            color: $black;
            text-decoration: none;
          }
        }
      }
    }
  }
}
