@import "variables";
@import "mixins";

.orders {
  & > div {
    padding: 25px;
    @include mQ(1200px) {
      &.list:not(:has(.spinner-border)) {
        padding: 0;
      }
    }
  }
  .head {
    svg {
      width: 25px;
      height: 25px;
    }
  }
  .orders-filter-section {
    & > div {
      margin: 5px 0;
    }

    .dropdown {
      margin-right: 12px;
      .dropdown-toggle {
        width: fit-content;
        min-width: 200px;
        border: 1px solid $grey;
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &.placeholder {
          color: $grey;
        }
        &::after {
          margin-left: 24px;
        }
      }
      .dropdown-menu.show {
        background-color: $white;
        .dropdown-item {
          color: $black;
        }
      }
    }
  }
}

.orders,
.dash-orders {
  position: relative;
  & > div {
    background-color: $white;
    @include mQ(1200px) {
      &.list:not(:has(.spinner-border)) {
        background: none;
      }
    }
  }

  .head {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    a {
      margin-right: 10px;
      color: $grey;
      position: relative;
      padding-bottom: 5px;
      font-weight: normal;
      &.active {
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          bottom: 0px;
          left: 0px;
          height: 0px;
          border: 2px solid $hover-green;
          border-radius: 5px;
        }
      }
    }

    button,
    a.button {
      padding: 0px 25px;
      width: fit-content;
      background: none;
      color: $green;
      &:hover {
        background-color: rgba($green, 0.2);
      }
    }
  }
  .orders-list {
    .dropdown {
      .btn-secondary {
        color: $white;
      }
    }
    .react-bootstrap-table {
      button {
        font-size: 14px;
      }
      .button-secondary {
        @include buttonBorder;
      }
      tr {
        td {
          svg {
            margin: 0 !important;
          }
        }
      }
    }
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    p {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
    }
    .no-label,
    .unknown {
      background-color: rgba($light-grey, 0.3);
      color: $grey;
      border-radius: 5px;
      border: 1px dashed;
    }
    .returned,
    .temporary_failure {
      background-color: rgba($red, 0.1);
      color: $red;
      border-radius: 5px;
      border: 1px dashed;
    }

    .fulfilled,
    .delivered {
      background-color: rgba($green, 0.1);
      color: $green;
      border-radius: 5px;
      border: 1px dashed;
    }
    .transit,
    .pre-transit {
      background-color: rgba($yellow-2, 0.1);
      color: $yellow-2;
      border-radius: 5px;
      border: 1px dashed;
    }

    .line-items-list {
      display: flex;
      flex-direction: column;
      .item-images {
        position: absolute;
        z-index: 1000;
        width: fit-content;
        max-width: 350px;
        height: 0;
        flex-wrap: wrap;
        flex-grow: 1;
        opacity: 0;
        background-color: $white;
        box-shadow: 0 3px 5px -3px rgba($bg-grey, 0.3);
        padding: 15px;
        transition: 0.3s opacity;
        overflow: hidden;
      }
      img.item-image {
        width: 150px;
        height: auto;
        margin: 3px;
      }
      &:hover {
        .item-images {
          display: flex;
          opacity: 0.9;
          height: auto;
        }
      }
    }

    &.test-mode {
      .react-bootstrap-table {
        td,
        th {
          button {
            background-color: $yellow-2;
            color: $black;
            &:hover {
              background-color: rgba($yellow-2, 0.2);
            }
          }
          a {
            border-color: $yellow-2;
            color: $yellow-2;
            &:hover {
              background-color: rgba($yellow-2, 0.2);
            }
          }
        }
      }
    }
  }
}

.orders,
.dash-orders {
  .orders-list {
    @include mQ(1200px) {
      background: none;
      .more-button {
        width: 100%;
        padding: 15px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        background: $white;
      }
    }
    .table-list {
      .table-item {
        margin-bottom: 5px;
        padding: 15px;
        border-bottom: 1px solid $light-grey;
        background-color: $white;
        .item-fields {
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: 20px;
          row-gap: 15px;
          .status-item {
            &.circle {
              &:not(.background):not(.bordered) {
                padding-left: 0;
              }
            }
          }
          .items-count {
            color: $green;
            font-weight: 500;
            font-size: 16px;
          }

          h5 {
            margin-bottom: 0;
          }
          p {
            width: fit-content;
            display: inline-block;
            padding: 0;
          }
          p.field-name {
            font-weight: 500;
            color: rgba($dark-grey, 0.8);
          }
          & > * {
            &:nth-child(10) {
              padding: 8px;
              text-align: center;
            }
            &:nth-child(13) {
            }
            &:nth-child(14) {
            }
          }
          .expanded-row-details {
            height: 0;
            overflow: hidden;
            opacity: 0;
          }
          a {
            @include buttonMain;
            background: none;
            width: fit-content;
            padding: 3px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $green;
            svg {
              width: 25px;
              height: auto;
            }
            &:hover {
              background-color: rgba($green, 0.1);
            }
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
      }
    }
    .lineitem-details {
      p {
        padding: 0;
      }
      .lineitem-detail-image {
        img {
          width: 100%;
          height: auto;
        }
      }
      .lineitem-detail-content {
        & > * {
          margin-bottom: 25px;
        }
        h3,
        p {
          text-align: start;
        }
        .lineitem-sku {
          a {
            margin-left: 15px;
            padding: 5px;
            border-radius: 8px;
            &:hover {
              background-color: rgba($green, 0.2);
            }
            svg {
              &.copied {
                stroke: $green;
              }
            }
          }
        }
        .lineitem-metadata {
          background-color: $bg-grey;
          padding: $space-s-m;
          border-radius: 12px;
          display: grid;
          grid-template-columns: auto auto;
          gap: 10px;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: left;
        }
      }
    }
    svg.copied {
      stroke: $green;
    }
  }
}
