@import "variables";
@import "mixins";

.labels {
  padding: 0px;
  padding-bottom: 25px;

  .dropdown {
    .btn-secondary {
      color: $white;
    }
  }
  &.test-mode {
    .dropdown {
      button {
        background-color: rgba($yellow-2, 0.1);
        color: $yellow-2;
        &:focus {
          border: 1px solid $yellow;
        }
        &:hover {
          background-color: rgba($yellow, 0.3);
        }
      }
    }

    .ship-label-button {
      background-color: $yellow-2;
      color: $white;
    }
  }
}

.labels,
.dash-labels {
  .settings-card {
    .card-section.head {
      padding: 25px;
    }
    .card-section.last:not(:has(.spinner-border)) {
      @include mQ(1200px) {
        background: none;
        padding: 0;
      }
    }
  }
  .labels-table {
    @include mQ(1200px) {
      background: none;
      .more-button {
        width: 100%;
        padding: 15px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        background: $white;
      }
    }

    .table-list {
      .table-item {
        overflow-x: auto;
        margin-bottom: 5px;
        background-color: $white;
        padding: 15px;
        .item-fields {
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: 20px;
          row-gap: 15px;
          h5 {
            margin-bottom: 0;
          }
          a {
            &.tracking-number {
              color: $green;
            }
            &.table-actions-item {
              @include buttonMain;
              margin-bottom: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &.download-icon {
              width: 100%;
              color: $green;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                stroke: $green;
              }
            }
            &.download-button {
              @include buttonMain;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          p {
            width: fit-content;
            display: inline-block;
            padding: 0;
          }
          p.field-name {
            font-weight: 500;
            color: rgba($dark-grey, 0.8);
          }
          p:nth-child(12) {
            color: $yellow-2;
            font-weight: 500;
            padding: 8px;
            margin-bottom: 0;
            background-color: rgba($yellow-2, 0.1);
            text-align: center;
            border-radius: 8px;
            &.error {
              background-color: rgba($red, 0.1);
              color: $red;
            }
          }
          & > * {
            &:nth-child(14) {
            }
          }
          .expanded-row-details {
            height: 0;
            overflow: hidden;
            opacity: 0;
          }
        }
      }
    }
  }
}

.labelMain {
  //max-height: 80vh;
  position: relative;
  margin: auto;
  border-radius: 8px;
  max-width: 90vw;

  .settings-card {
    position: relative;
    width: 47%;
    @include mQ(992px) {
      width: 100%;
    }
    .card-section {
      width: 100%;
      padding: 30px;
      background-color: $white;
      margin-bottom: 5px;

      p {
        margin-bottom: 0px;
        &.title {
          margin-bottom: 5px;
        }
      }

      &.head {
        border-radius: 16px;
        align-items: center;
        p,
        h3,
        h4 {
          margin-bottom: 0px;
        }
        h4 {
          /* Body1 */

          font-family: "Public Sans", "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: center;
          text-align: center;

          color: #000000;

          /* Inside auto layout */

          flex: none;
          order: 1;
        }
        h3 {
          font-size: 20px;
        }
        a.action {
          color: $green;
          font-weight: normal;
          padding: 5px 8px;
          border-radius: 5px;
          transition: background-color 200ms;

          cursor: pointer;
          &:hover {
            background-color: rgba($green, 0.1);
            text-decoration: none;
          }
        }
      }
      &.last {
        border-radius: 16px;
        margin-bottom: 0px;
        &:has(.package-details) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-bottom: 5px;
        }
      }
      svg {
        margin-right: 15px;
        background-color: $off_white;
        border-radius: 9px;
        padding: 8px;
        width: 35px;
        height: auto;
      }
      .h-1 {
        color: $dark_grey;
      }
      .info {
        color: $grey;
      }

      button {
        &:disabled,
        &[disabled] {
          background-color: $green_10 !important;
          color: $green;
          padding: 5px 15px;
        }
      }
    }
  }
  h4 {
    font-weight: normal;
    font-size: 16px;
  }

  .dropdown {
    button {
      @include textBox;
      background: none;
      width: 100%;
    }
  }

  button {
    &:hover {
      background-color: $hover-green;
    }
  }

  .dropdown-toggle {
    &:focus,
    &:hover {
      background-color: rgba($green, 0.3);
      color: $black;
      border: none;
      box-shadow: none;
    }
  }
  .dropdown-item {
    &:hover {
      background-color: rgba($green, 0.3);
      color: $black;
    }
  }
  .dropdown.show {
    .btn-primary.dropdown-toggle {
      background-color: rgba($green, 0.3);
      color: $black;
    }
  }

  .address-form {
    a {
      cursor: pointer;
      &:hover {
        color: $green;
      }
    }
  }

  .address-list {
    //.list-head {
    //    margin-top: 50px;
    //
    //    background-color: $gray-100;
    //    border-top-left-radius: 8px;
    //    border-top-right-radius: 8px;
    //    padding: 30px 50px;
    //
    //    h3 {
    //        font-weight: normal;
    //        font-size: 20px;
    //        margin-bottom: 0px;
    //    }
    //}

    .card-section.head {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-section.last {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .refresh-btn {
      color: $green;
      text-decoration: none;
    }

    a {
      cursor: pointer;

      &:hover {
        color: $green;
      }
    }

    //.list-content {
    //
    //    margin: 25px 25px;
    //    padding: 0px 25px;
    //    overflow-y: auto;
    //}

    .address-list-item-container {
      .address-list-item {
        // background-color: red;
        // height: 150px;
        //  border-radius: 8px;
        padding: 15px;
        margin: 25px 0;
        cursor: pointer;
        width: auto;

        h3 {
          font-weight: normal;
          font-size: 18px;
          margin-bottom: 0px;
        }

        p {
          color: $dark-grey;
          font-size: 14px;
          margin: 0px;
        }
      }
      .address-list-item-actions {
        a {
          color: $grey;
          &:hover {
            svg {
              stroke: $green;
            }
            color: $green;
          }

          margin-left: 5px;
        }
        .delete-address-confirmations {
          .deny {
            &:hover {
              svg {
                stroke: $red;
              }
            }
          }
        }
      }
      &:hover {
        .address-list-item {
          * {
            color: $green;
          }
        }
      }
    }
  }

  .customs-items {
    background-color: $bg-grey;
    .card-section.head {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .customs-item-form {
      padding: 25px;
      .form-content {
        border-radius: 5px;
        padding: 25px;
        background-color: $white;
        margin-bottom: 25px;
        .quantity {
          label {
            width: 32%;
          }
        }
        .value {
          display: flex;
          label:first-child {
            width: fit-content;
          }
          label:last-child {
            flex-grow: 0.95;
          }
        }
      }
      label,
      span {
        font-size: 12px;
        color: darken($grey, 20%);
        *:not(p, span) {
          font-size: $step-0;
        }
      }
      &.error {
        border-color: $red;
      }

      .text-danger {
        display: none;
        font-size: 14px;
        margin-bottom: 5px;

        &.error {
          display: flex;
        }
      }
      .origin-country {
        position: relative;
        .search-results {
          display: none;
          position: absolute;
          max-height: 150px;
          overflow-y: auto;
          top: calc(100% + 10px);
          z-index: 2500;
          background: $white;
          border-radius: 8px;
          width: 100%;
          box-shadow: 0 2px 8px $light-grey;
          a {
            display: unset;
            color: $black;
            padding: 10px 20px;
            &:hover {
              background-color: rgba($green, 0.1);
            }
          }
        }
        & > input:focus + .search-results,
        .search-results:focus,
        .search-results *:focus,
        .search-results:active {
          display: flex;
          flex-direction: column;
        }
      }
      .value {
        label {
          height: fit-content;
        }
      }
    }

    input {
      @include textBox;
    }

    div.active {
      display: flex;
    }

    div.inactive {
      display: none;
    }

    .collapsed {
      div.active {
        display: none;
      }

      div.inactive {
        display: flex;
      }
    }

    .country-list {
      overflow-y: auto;
      height: 300px;
      box-shadow: 3px 3px 30px -10px $grey;
    }

    .country-toggle {
      @include textBox;
      background: none;
    }
  }

  .modal-content.save-template-prompt {
    padding: 25px;
    .prompt-icon {
      padding: 20px;
      background-color: rgba($light-green, 0.1);
      border-radius: 50%;
      margin-bottom: 30px;
      svg {
        width: 50px;
        height: auto;
      }
    }
    p {
      color: $dark-grey;
      margin-bottom: 30px;
    }
    button {
    }
    button.no-bg {
      margin-top: 15px;
      background: none;
      color: $green;
    }
  }
  .modal-content.overlay-prompt {
    padding: 25px;
    .prompt-icon {
      padding: 20px;
      background-color: rgba($light-green, 0.1);
      border-radius: 50%;
      margin-bottom: 30px;
      svg {
        margin: 0;
        background: none;
        padding: 5px;
        width: 50px;
        height: auto;
        stroke: $green;
      }
    }
    p {
      color: $dark-grey;
      margin-bottom: 10px !important;
      &.prompt-description {
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        padding: 8px;
        background-color: rgba($bg-grey, 0.5);
        border-radius: 5px;
        margin-bottom: 10px;
      }
    }
    button {
    }
    button.no-bg {
      margin-top: 15px;
      background: none;
      color: $green;
      &:hover {
        background-color: rgba($green, 0.1);
      }
    }
  }

  .modal-content.verify-details-prompt {
    padding: 25px;
    .prompt-icon {
      padding: 20px;
      background-color: rgba($light-green, 0.1);
      border-radius: 50%;
      margin-bottom: 30px;
      svg {
        width: 50px;
        height: auto;
        stroke: $green;
      }
    }
    p {
      color: $dark-grey;
      margin-bottom: 30px;
    }
    button {
    }
    button.no-bg {
      margin-top: 15px;
      background: none;
      color: $green;
    }
  }

  .label-create,
  .label-template {
    overflow-y: auto;
    border-radius: 12px;
    border: $bg-grey;
    margin-bottom: 30px;

    & > *:nth-last-child(2) {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    .create-title {
      font-size: $step-1;
      margin-bottom: 0;
      font-weight: 500;
    }

    h3 {
      font-weight: normal;
      font-size: 20px;
    }

    .address-selection {
      padding-bottom: 50px;
      &.top {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        //margin-top: 25px;
      }

      &:has(.label-create-address.show-error) {
        border: 1px solid $red;
        border-radius: 8px;
      }

      .label-create-address {
        .address-actions {
          * {
            display: inline;
            height: fit-content;
          }
          a {
            color: $green;
            &:hover {
              color: $hover-green;
            }
          }
          p {
            margin: auto 10px;
            color: $grey;
          }
        }
      }
      .address-item {
        border: 0 $grey;
        border-radius: 0;
        margin-bottom: 30px;
        background-color: rgba($bg-grey, 0.4);
        padding: 15px;

        .address-header {
          h3 {
            margin-bottom: 25px;
          }
        }

        .address-info {
          // background-color: red;
          // height: 150px;
          h3 {
            font-weight: normal;
            font-size: 18px;
          }

          p {
            color: $dark-grey;
            font-size: 14px;
            margin: 0px;
          }
        }

        a {
          margin: 5px;
          font-size: 16px;
          cursor: pointer;
          color: $dark-grey;
          text-decoration: underline;
          font-weight: normal;
          &:hover {
            color: $green;
          }
        }
      }
    }

    .package-details {
      position: relative;
      input {
        @include textBox;
      }

      .package-prompt {
        font-size: 14px;
        color: #828282;
      }

      .button-group.switch {
        display: none;
        button {
          height: 100%;
          padding: 15px 8px;
          background-color: rgba($green, 0.3) !important;
          border: none;
          color: $green;
          &:hover {
            background-color: rgba($dark-green, 1) !important;
            border: none;
            color: $white;
          }

          &.active {
            background-color: $green !important;
            color: $white;
          }

          &:disabled,
          &[disabled] {
            background-color: rgba($green, 1);
            border: none;
            color: $white;
            &:hover {
              background-color: rgba($green, 1);
              border: none;
              color: $white;
            }
          }
        }

        &.active {
          display: grid;
          grid-template-rows: auto;
          grid-template-columns: repeat(2, 0.8fr);
        }
      }
      .divider {
        border-bottom: 1px $light-grey solid;
        margin-bottom: 50px;
        margin-top: 25px;
      }

      label.show-error {
        input,
        textarea {
          border-color: $red;
        }
      }
      .dropdown.template-selector.show-error {
        .dropdown-toggle {
          border-color: $red;
        }
      }

      .custom-inputs {
        label {
          width: 23%;
          p {
            font-size: $step--1;
            color: $dark-grey;
          }

          input {
            border-width: 1px;
          }
        }
      }

      .weight-inputs {
        & > * {
          width: 48%;
        }

        input {
          border-width: 1px;
        }

        button {
          border-width: 1px;
        }
      }

      .multi-piece-quantity {
        label {
          div {
            position: relative;
            width: 150px;
            button {
              position: absolute;
              width: 40px;
              height: 40px;
              background: none;
              padding: 3px;
              svg {
                width: 100%;
                height: 100%;
                stroke: $black;
                background: none;
              }
              &:last-child {
                right: 0;
              }
            }
            input {
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              &[type="number"] {
                -moz-appearance: textfield;
              }
              padding: 8px 50px;
              width: 100%;
            }
          }
        }
      }

      .template-actions {
        max-width: fit-content;
        a {
          color: $green;
        }
        p {
          margin: auto 10px;
          color: $grey;
        }
      }

      .selected-template {
        background: rgba($bg-grey, 0.5);
        border-radius: 8px;
        padding: 10px 15px;
        margin: 25px 0px;
        svg {
          margin-right: 0px;
          &:hover {
            stroke: $green;
          }
        }
        p {
          color: $grey;
          font-size: 14px;
        }
        .template-name {
          color: $black;
          font-size: 15px;
        }
      }

      .template-help {
        position: fixed;
        margin: auto;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        border-radius: 8px;
        background-color: #3333;

        //display: none;
        z-index: 2000;
        .template-actions {
          box-sizing: content-box;
          position: relative;
          background-color: $white;
          max-width: fit-content;
          padding: 20px;
          border-radius: 10px;
          p {
            margin: auto 10px;
            color: $grey;
          }
          p.actions {
            margin: 0px;
            color: $green;
            font-weight: 600;
          }
        }
        .template-intro-message {
          position: absolute;
          left: 105%;
          background-color: $green;
          padding: 25px;
          border-radius: 10px;
          min-width: 380px;
          z-index: 4000;
          & > div {
            margin-bottom: 15px;
            svg {
              background: none;
              stroke-width: 2px;
              stroke: $white;
              width: 25px;
              height: 25px;
              padding: 0px;
              &:hover {
                stroke: $black;
              }
            }
            h3 {
              color: $white;
              font-weight: 600;
            }
          }
          p {
            color: $white;
            margin: 0px;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .point {
          position: absolute;
          width: 40px;
          left: 99%;
          height: 38px;
          z-index: 3000;
          background-color: $green;
          border-radius: 7px;
          transform: rotate(45deg);
        }
        @include mQ(768px) {
          .template-intro-message {
            position: absolute;
            left: 0%;
            bottom: 105%;
            background-color: $green;
            padding: 25px;
            border-radius: 10px;
            min-width: 380px;
            z-index: 4000;
            & > div {
              margin-bottom: 15px;
              svg {
                background: none;
                stroke-width: 2px;
                stroke: $white;
                width: 25px;
                height: 25px;
                padding: 0px;
                &:hover {
                  stroke: $black;
                }
              }
              h3 {
                color: $white;
                font-weight: 600;
              }
            }
            p {
              color: $white;
              margin: 0px;
              font-size: 14px;
              font-weight: 400;
            }
          }
          .point {
            position: absolute;
            width: 35px;
            left: 50%;
            bottom: 95%;
            height: 35px;
            z-index: 3000;
            background-color: $green;
            border-radius: 7px;
            transform: rotate(45deg);
          }
        }
      }
      .parcel-actions {
        button {
          width: 48%;
          height: 55px;
          margin-top: 25px;
          @include mQ(992px) {
            width: 100%;
          }

          &.button-secondary {
            background: none;
            color: $green;
            border: 2px solid $green;
            &:hover {
              background-color: rgba($green, 0.1);
            }
          }
        }
      }
      .parcels-list {
        .parcel-item {
          & > div {
            width: 100%;
            background-color: rgba($bg-grey, 0.8);
            padding: 24px;
            border-radius: 8px;
            margin-bottom: 8px;
          }
          svg {
            background: none;
            //padding: 0;
            margin: 0;
            //width: 20px;
            height: auto;
            stroke: $dark-grey;
            &:hover {
              stroke: $green;
              &.delete-action {
                stroke: $red;
              }
            }
          }
          &.active {
            & > div {
              border: 1px solid $green;
              background-color: rgba($green, 0.2);
            }
          }
          .multi-piece-quantity {
            background-color: rgba($green, 0.1);
            color: $green;
            padding: 8px 11px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            width: fit-content;
            margin-bottom: 5px;
          }
        }
      }
      a.add-package-action {
        color: $green;
        padding: 8px;
        border-radius: 8px;
        margin-top: 10px;
        svg {
          background: none !important;
        }
        &:hover {
          background-color: rgba($green, 0.1);
        }
      }
    }

    .customs-info {
      margin-bottom: 5px;
      padding: 25px;
      background: white;
      .template-fields {
        p.text-danger {
          display: none;
        }
        &.show-error {
          p.text-danger {
            display: block;
          }
          input,
          button,
          .check,
          textarea {
            border-color: $red;
          }
        }
      }

      & > .info {
        background-color: rgba($grey, 0.1);
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 25px;
        svg {
          width: 30px !important;
          margin-right: 15px;
        }
        p {
          color: $dark-grey;
        }
      }

      & > p.customs-reason {
        font-size: 14px;
        font-weight: 500;
      }

      .customs-item {
        background: white;
        color: $green;
        border: 2px dashed $grey;
        width: 100% !important;
        border-radius: 10px;
        &:hover {
          background-color: rgba($green, 0.15);
        }
      }

      .customs-list-item {
        background-color: rgba($grey, 0.2);
        padding: 20px;
        border-radius: 12px;
        margin-bottom: 10px;
        p {
          color: $dark_grey;
        }

        .customs-item-actions {
          svg {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            stroke: $grey;
            &:hover {
              stroke: $green;
            }
          }
        }
        .item-image {
          height: 100%;
          width: auto;
          max-width: 150px;
          border-radius: 5px;
        }
        .item-details-container {
          flex-grow: 0.9;
        }

        .item-details {
          margin: 20px 0px;
          .item-description {
            width: 100%;
            margin-bottom: 10px;
            @include mQ-min(992px) {
              padding-right: 15px;
              margin-bottom: 0;
              width: 33%;
            }
          }
        }
      }

      .form-checkbox {
        margin: 15px 0px;
      }
      .terms-text {
        font-family: "Public Sans", "Roboto", sans-serif;
        color: #393939;
        font-size: 16px;
        line-height: 24px;
      }

      button {
        width: 100% !important;
        margin-bottom: 15px;
        border-width: 1px;

        &.button-secondary {
          @include buttonBorder;
          max-width: 100% !important;
          background: none;
        }
      }

      input:not([type="checkbox"]) {
        @include textBox;
        border-width: 1px;
      }

      .items-count {
        color: $green;
        background: none;
        border-radius: 25%;
        padding: 0px 5px;
        margin-left: 10px;
        display: inline-flex;
      }

      label {
        font-size: 14px;
      }

      .dropdown {
        //height: fit-content;
      }
      textarea {
        height: 75px;
      }
    }

    .button-area {
      button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    @include mQ(768px) {
      .package-details,
      .customs-info {
        padding: 10px;
        margin: 0px;
        margin-bottom: 15px;
        // border: none;
      }

      .address-selection {
        padding: 0px;
        margin-bottom: 15px;
      }

      // .button-area{
      //     margin-bottom: 15px;
      // }
      .address-item {
        a {
          font-family: "Public Sans", "Roboto", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
        p {
          font-family: "Public Sans", "Roboto", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #0f6674;
        }
        // border: none;
      }
    }

    .customs-info,
    .package-details {
      & > div:not(.selected-template) {
        width: 100%;
        & > *:not(.check-template) {
          width: 100%;
        }
      }
    }
    .form-checkbox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .check {
        width: 18px;
        height: 18px;
        background: none;
        border: 1px solid grey;
        border-radius: 3px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          background: none;
          stroke: $white;
          width: 18px;
          display: none;
          padding: 0px;
          margin: 0px;
        }
        &:hover {
          border-color: $green;
        }
        &.active {
          background-color: $green;
          border: none;
          svg {
            display: flex;
          }
        }
      }

      p {
        padding: 8px 15px;
        background-color: rgba($bg-grey, 0.6);
        border-radius: 8px;
        width: 100%;
      }
    }
  }

  .rates-selection {
    transition: all 300ms linear;
    border-radius: 12px;

    .rates-container {
      @include mQ-min(992px) {
        position: fixed;
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        width: calc(47% - 2rem);
      }
      @include mQ-min(1200px) {
        width: calc(calc(100vw * 10 / 12) * 0.47 - 2rem);
      }
    }

    @include mQ(992px) {
      width: auto;
    }

    .purchase-button {
      /* Button/Primary */

      /* Auto layout */

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 32px;
      gap: 8px;

      width: 352px;
      height: 54px;

      /* Text/4 */

      border-radius: 12px;

      /* Inside auto layout */

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    .rates-title {
      width: 80%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      .text-title {
        width: 55px;
        height: 24px;

        font-family: "Public Sans", "Roboto", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height */

        display: flex;
        align-items: center;
        text-align: center;

        color: #000000;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }

      @include mQ(768px) {
        margin: 10px;
      }
      @include mQ(992px) {
        margin: auto;
      }
    }

    &.show {
      width: 40vw;

      @include mQ(1200px) {
        width: 50vw;
      }

      @include mQ(992px) {
        width: 80vw;
      }

      @include mQ(768px) {
        width: 90vw;
      }
    }

    .rates-list {
      display: flex;
      align-items: center;
      justify-content: center;

      @include mQ(992px) {
        margin: auto;
      }

      .info {
        justify-content: center;
        font-family: "Public Sans", "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;

        color: #000000 !important;
      }

      .empty-list {
        p {
          color: $grey;
        }
        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 32px;
          gap: 8px;

          width: 183px;
          height: 54px;

          /* Text/4 */

          border-radius: 12px;

          /* Inside auto layout */

          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }

      .list-container {
        margin: 25px;
        overflow-y: auto;
        max-height: 40vh;
      }

      .rate-item {
        padding: 10px;
        max-width: 95%;

        margin-bottom: 8px;
        border-radius: 8px;
        background: rgba($light-grey, 0.15);

        img.active {
          display: none;
        }

        h4 {
          font-weight: 500;
        }

        h5 {
          font-family: "Public Sans", "Roboto", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: center;

          /* Inside auto layout */

          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
        h4 {
          font-size: 22px;
          margin-bottom: 5px;
        }

        .rate-icon {
          width: auto;
          height: 50%;
          margin-right: 10px;
          max-width: 50px;
          margin-top: auto;
          margin-bottom: auto;
        }

        .price {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
        }

        .rate-features {
          p {
            font-family: "Public Sans", "Roboto", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            display: flex;
            align-items: center;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
          }
        }

        &:hover,
        &.active {
          background-color: $green;

          * {
            color: $white;
          }

          img.inactive {
            display: none;
          }
          img.active {
            display: flex;
          }
        }
      }
    }

    button {
    }
  }

  .labelsummary {
    .card-section.sender {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .card-section.package {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    .details-btn {
      /* Body1 */

      font-family: "Public Sans", "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      text-align: right;

      /* NewGreen/Primary */

      color: $green;

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;
    }
    .summary-title {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      @include mQ(768px) {
        margin: 10px;
      }
    }

    .summary-content {
      padding: 25px;

      .summary-item {
        padding-top: 50px;
        border-bottom: 1px solid $grey;
        width: 100%;

        h4 {
          color: $dark-grey;
          letter-spacing: 2px;
          font-size: 14px;
        }

        h5 {
          font-size: 20px;
        }

        p {
          font-size: 16px;
          color: $dark-grey;
          margin-bottom: 0px;
        }

        a {
          margin: 5px;
          font-size: 16px;
          cursor: pointer;
          color: $green;
          text-decoration: underline;
          text-align: right;

          &:hover {
            font-weight: bold;
            color: $hover-green;
          }
        }
      }
    }
  }

  .purchase-label {
    a {
      margin: 5px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: $green;
      }
    }

    .email {
      border: 1px solid $grey;
      border-radius: 8px;
      margin: 0;
      padding: 10px;
      font-weight: bold;
    }

    .collection-amount {
      input {
        @include textBox;
        margin-right: 10px;
      }
    }

    .stripe-card-form {
      .card-field {
        margin-bottom: 10px;
        border: 1px solid $green;
        border-radius: 8px;
        padding: 10px;
      }

      button {
        width: 50%;
        margin: auto;
        height: 40px;
        // display: flex;
      }
    }
    .paystack-card-form {
      .react-tel-input {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        input {
          @include textBox;
          border-color: $green;
          width: 84%;
          @include mQ(992px) {
            width: 81%;
          }
          margin-bottom: 10px !important;
          font-size: $step-0;
          &::placeholder {
            color: $grey;
          }
        }
        .flag-dropdown {
          @include textBox;
          margin-bottom: 10px;
          position: relative;
          width: 15%;
          background-color: $white;
          border-color: $green;
          @include mQ(992px) {
            width: 18%;
          }

          div.selected-flag {
            padding: 16px;
            background: none;
            width: 100%;
            .flag {
              scale: 1.5;
              .arrow {
                scale: 0.75;
              }
            }
          }

          &.open {
            border-radius: 7px;
          }

          .country-list {
            position: fixed;
          }
        }
      }
    }

    .card-selection {
      a {
        text-decoration: none;
        color: $green;

        width: 100%;
        &.dropdown-item {
        }
        &:not(.dropdown-toggle) {
          &:hover {
            color: $black;
          }
        }
      }

      .card-number {
        color: $green;
        font-weight: bold;
      }

      .card-icon {
        width: 70px;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }

      .card-list-item {
        a.dropdown-toggle {
          p {
            width: 100%;
            word-break: break-all;
            white-space: normal;
          }
        }
        .info {
          margin-bottom: 0px;
          color: $grey;
        }

        .dropdown-toggle {
          padding: 0px 10px;
        }
      }

      .card-list {
        background-color: $white;
        height: 350px;
        overflow-y: auto;
        padding: 25px;
        margin: 10px;
        box-shadow: 0px 0px 20px 0px $dark-grey;
        border-radius: 8px;

        .card-list-item {
          border: 1px solid $green;
          // color: $green;
          border-radius: 8px;
          margin: 10px 15px 10px 15px;
          padding: 15px;

          .card-icon {
            width: 70px;
            height: auto;
            img {
              width: 100%;
              height: auto;
            }
          }

          &.selectable:hover {
            background-color: $green;
            color: $white;

            .card-number {
              color: $dark-grey;
              font-weight: bold;
            }

            a {
              margin: 5px;
              font-size: 16px;
              cursor: pointer;
              &:hover {
                color: $dark-grey;
              }
            }
          }
        }
      }
    }

    .label-summary {
      img {
        width: 50px;
        height: auto;
        margin-right: 10px;
      }

      .provider {
      }

      .price-box {
        color: $green;
      }

      .address {
        h5 {
          color: $dark-blue;
        }

        .address-tag {
          color: $dark-grey;
        }
      }

      .address-info {
        .map-pin {
          position: relative;
          padding: 10px;

          &::after {
            position: absolute;
            border: 1px solid $grey;
            width: 0;
            height: 100%;
            display: block;
            content: "";
            left: 50%;
            // z-index: 1;
            // top: -50px;
            margin-left: -1px;
          }

          svg {
            // position: relative;
            fill: $dark-blue;
            width: 15px;
            height: auto;
            // margin-right: 10px;
            // &::after{
            //     content: "";
            //     display: inline-flex;
            //     margin: auto;
            //     border: 0px;
            //     border-left: 1px solid $grey;
            //     position: absolute;
            //     top: 0px;
            //     left: 50%;
            // }
          }
        }

        div:last-child {
          .map-pin {
            &::after {
              display: none;
            }
          }
        }
      }

      .info {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
      }

      svg.calender {
        // background-color: red;
        // color: $green;
        fill: $green;
        max-width: 15px;
        height: auto;
        margin: 3px;
        margin-right: 10px;
      }
    }
  }

  &.test-mode {
    .modal-content.address-form {
      button.create-address-button {
        background-color: $yellow-2;
        color: $black;
        &:hover {
          background-color: rgba($yellow-2, 0.3);
        }
      }
    }

    .address-list-item {
      .default-tag {
        color: $yellow-2;
      }
      &:hover {
        background-color: $yellow;
        * {
          color: $black;
        }
      }
    }
    .address-item {
      .default-tag {
        color: $yellow-2;
      }
    }

    .customs-items {
      .add-item,
      .remove-item {
        color: $yellow-2;
        cursor: pointer;
        display: block;

        &:hover {
          color: $black;
        }
      }
      button:not(.dropdown-toggle) {
        background-color: $yellow-2;
        color: $black;
        &:hover {
          background-color: rgba($yellow-2, 0.3);
        }
      }
    }
    .dropdown-toggle {
      &:focus,
      &:hover {
        background-color: rgba($yellow-2, 0.3);
        color: $black;
        box-shadow: none;
      }
    }
    .dropdown-item {
      &:hover {
        background-color: rgba($yellow-2, 0.3);
        color: $black;
      }
    }
    .dropdown.show {
      .btn-primary.dropdown-toggle {
        background-color: rgba($yellow-2, 0.3);
        color: $black;
      }
    }

    .label-create {
      .label-create-address {
        .address-actions {
          * {
            display: inline;
            height: fit-content;
          }
          a {
            color: $yellow-2;
            &:hover {
              color: $black;
            }
          }
          p {
            margin: auto 10px;
            color: $grey;
          }
        }
      }
      .address-item {
        a svg {
          &:hover {
            stroke: $yellow-2;
          }
        }
      }

      .package-details {
        .template-actions {
          a,
          p.actions {
            color: $yellow-2;
          }
        }
        .template-help {
          .template-intro-message,
          .point {
            background-color: $yellow-2;
            * {
              color: $black;
            }
            svg {
              stroke: $black;
              &:hover {
                stroke: rgba($black, 0.2);
              }
            }
          }
        }
        .selected-template {
          svg {
            &:hover {
              stroke: $yellow-2;
            }
          }
        }

        .parcel-actions {
          button {
            &.button-secondary {
              background: none;
              color: $yellow-2;
              border: 2px solid $yellow-2;
              &:hover {
                background-color: rgba($yellow-2, 0.15);
              }
            }
          }
        }
        .parcels-list {
          .parcel-item {
          }
        }
      }
      .button-group.switch {
        button {
          background-color: rgba($yellow-2, 0.15) !important;
          color: $yellow-2;
          &:hover {
            background-color: rgba($yellow-2, 0.6) !important;
            border: none;
            color: $yellow-2;
          }

          &:disabled,
          &[disabled] {
            background-color: rgba($yellow-2, 1) !important;
            border: none;
            color: $black;
            &:hover {
              background-color: rgba($yellow-2, 1) !important;
              border: none;
              color: $black;
            }
          }
        }
      }

      .customs-info {
        .customs-item {
          color: $yellow-2;
          border-color: $yellow-2;
          &:hover {
            background-color: rgba($yellow-2, 0.15);
          }
          .items-count {
            color: $yellow-2;
          }
        }

        button:not(.dropdown-toggle, .customs-item) {
          background-color: $yellow-2;
          color: $black;
          &:hover {
            background-color: rgba($yellow-2, 0.3);
          }

          &.button-secondary {
            background: none;
            border-color: $yellow-2;
            color: $yellow-2;
            &:hover {
              background-color: rgba($yellow-2, 0.3);
            }
          }
        }
      }

      button.get-rates,
      .button-area button {
        background-color: $yellow-2;
        color: $black;
        &:hover {
          background-color: rgba($yellow-2, 0.3);
        }
      }
    }
    .form-checkbox {
      .check {
        &:hover {
          border-color: $yellow-2;
        }
        &.active {
          background-color: $yellow-2;
          svg {
            stroke: $black;
          }
        }
      }
    }

    .settings-card.rates-selection {
      .rate-item {
        &:hover {
          background-color: rgba($yellow-2, 0.2);
          * {
            color: $black;
          }
        }
        &.active {
          background-color: $yellow-2;
          * {
            color: $black;
          }
        }
      }
      button {
        background-color: $yellow-2;
        color: $black;
        &:hover {
          background-color: rgba($yellow-2, 0.2);
        }
        &:disabled,
        &[disabled] {
          background-color: $light-grey !important;
          color: $black !important;
        }
      }
    }

    .purchase-label {
      .label-summary {
        svg {
          fill: $yellow-2;
        }
      }
      .price-box {
        color: $yellow-2;
      }

      .card-selection {
        .dropdown-toggle {
          padding: 0px 10px;
          background-color: rgba($yellow-2, 0.2);
          color: $yellow-2;

          &:hover {
            background-color: rgba($yellow-2, 0.4);
          }
        }
        a {
          color: $yellow-2;
          &:hover {
            color: $yellow-2;
          }
          &:not(.dropdown-toggle) {
            &:hover {
              color: $black;
            }
          }
        }
        .card-list {
          .card-list-item {
            border-color: $yellow-2;
            &.selectable:hover {
              background-color: $yellow-2;
              color: $black;

              .card-number {
                color: $black;
                font-weight: bold;
              }
              p.info {
                color: $black;
              }
            }
          }
        }

        .stripe-card-form {
          .card-field {
            border-color: $yellow-2;
          }
          button {
            background-color: $yellow-2;
            color: $black;
            &:hover {
              background-color: rgba($yellow-2, 0.2);
            }
          }
        }
        .paystack-card-form {
          .react-tel-input {
            input {
              border-color: $yellow-2;
            }
            .flag-dropdown {
              border-color: $yellow-2;
            }
          }
        }
      }

      .pay-with-card {
        background-color: $yellow-2;
        color: $black;
        &:hover {
          background-color: rgba($yellow-2, 0.2);
        }
      }
    }
  }
}
