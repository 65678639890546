// Mixins
// Background Cover Mixin
@import "variables";

@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Font Mixins
@mixin body-font {
  font-family: "Roboto", "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin mQ($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

@mixin mQ-min($arg) {
  @media screen and (min-width: $arg) {
    @content;
  }
}

@mixin clearFix {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin buttonMain {
  font-weight: 600;
  // color: $white;
  font-size: 16px;

  @include mQ(992px) {
    font-size: 14px;
  }

  background-color: $green;
  padding: 5px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 0;
  color: $white;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: $hover-green;
  }

  &:active {
    background-color: $active-green;
  }

  &:disabled,
  &[disabled] {
    background-color: $grey !important;
  }
}

@mixin buttonBorder {
  // background-color: $white;
  padding: 5px;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  border: 2px solid;
  color: $green;
  transition: 0.3s ease-in-out;
  background: none;

  &:hover {
    border-color: $hover-green;
    color: $hover-green;
    background-color: rgba($green, 0.1);
  }

  &:active {
    color: $active-green;
    background-color: rgba($green, 0.25);
  }

  &:disabled,
  &[disabled] {
    background-color: $grey !important;
    color: $grey !important;
    border-color: $grey !important;
  }
}

@mixin textBox {
  // background-color: $white;
  border-radius: 7px;
  color: $black;
  border: 1px solid $grey;
  width: 100%;
  height: 50px;
  padding: 8px;
  transition: 0.3s ease-in-out;

  &:hover {
    border: 2px solid $black;
  }

  &:focus {
    border: 2px solid $black;
    outline: none;
  }
}

@mixin link {
  font-weight: 600;
  color: $black;
  text-decoration: none;
  font-size: 16px;

  @include mQ(992px) {
    font-size: 14px;
  }

  &:hover,
  &:active {
    color: $link-active;
  }
}
