@import "variables";
@import "mixins";

.integrations {
  section {
    margin-bottom: $space-m-4xl;
    overflow: hidden;
    &.shops {
      .content {
        p {
          width: 80%;
        }
      }
    }
  }

  .buttons {
    margin-top: $space-5xl-9xl;
    width: 100%;
    background: none;
    a {
      width: 48%;
      img {
        width: 100%;
        height: auto;
      }

      // @include mQ(992px){
      //     margin: 0px;
      //     width: 175px;
      //     height: auto;
      //     margin-bottom: 15px;

      // }
    }
  }
  a.button {
    display: flex;
    justify-content: center;
    align-items: center;
    @include buttonMain;
    width: clamp(9.38rem, calc(6.75rem + 13.13vw), 18.56rem);
    @include mQ(992px) {
      width: 100%;
    }
    &:hover {
      text-decoration: none;
    }
  }

  .sign-up {
    text-align: start;
    padding: 0px $space-m-3xl;
    padding-top: $space-3xl-7xl;
    img {
      width: 100%;
      height: auto;
      margin-bottom: 28px;
    }
    .content {
      height: 100%;
      h1 {
        margin-bottom: 38px;
      }
    }

    .buttons {
      width: 100%;
    }
  }
  a.button {
    display: flex;
    justify-content: center;
    align-items: center;
    @include buttonMain;
    width: clamp(9.38rem, calc(6.75rem + 13.13vw), 18.56rem);
    @include mQ(992px) {
      width: 100%;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

.shop.integrations {
  background-color: $white;

  .shops {
    .content {
      text-align: start !important;
    }
    img {
      width: 90%;
      margin: auto;
    }
    @include mQ(992px) {
      img {
        width: 125%;
        margin-bottom: $space-3xl-7xl;
      }
    }
  }

  .integration-cta {
    p.info {
      @include mQ-min(992px) {
        width: 50%;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.integrations.api {
  .shops {
    .content {
      p {
        @include mQ(992px) {
          width: 100%;
        }
      }
    }
  }
  .api-steps {
    h1 {
      text-align: center;
      width: 100%;
      margin-bottom: $space-m-4xl;
      @include mQ(992px) {
        margin-bottom: 32px;
      }
    }

    .steps {
      width: 100%;

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;

      @include mQ(1200px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mQ(768px) {
        grid-template-columns: repeat(1, 1fr);
      }

      .card {
        margin-bottom: 16px;
        border: none;
        background-color: rgba($light-grey, 0.3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 32px;
        border-radius: 20px;
        min-height: fit-content;
        height: clamp(fit-content, auto, 400px);

        h4 {
          font-size: $step-2;
          font-weight: 500;
        }

        h4,
        p {
          margin-bottom: 32px;
        }
        p {
          color: rgba($dark-blue-2, 0.7);
        }

        a {
          color: $orange;
          width: fit-content;
          padding: 5px 10px;
          border-radius: 6px;
          &:hover {
            text-decoration: none;
            background-color: rgba($orange, 0.1);
          }
        }
      }
    }
  }
}

.integrations.shopify {
  .masthead {
    padding: 0px;
    margin: 0px;

    .action {
      .icon {
        margin-right: 15px;
        width: 40px;
        height: 40px;
        padding: 10px 6px 6px 10px;
        border-radius: 50%;
        background-color: $off-white;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: auto;
        }
      }
      button {
        background-color: #1e8b7c;
      }
    }

    .content {
      .main {
        margin-bottom: 32px;
      }
      h1 {
        margin-bottom: 32px;
      }
    }

    @include mQ(992px) {
      .image {
        margin-bottom: $space-m-2xl;
      }
    }
  }

  .shops {
    padding-top: 0px;
    .content {
      h1 {
        margin-bottom: 40px;
      }
      p {
        width: 80%;
      }
    }
  }
}
