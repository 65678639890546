@import "variables";
@import "mixins";

.help-center {
  .menu-container {
    width: 20%;
    height: fit-content;
    position: fixed;
    padding: 25px;
    background-color: $white;
    border-radius: 16px;
    h4 {
      margin-bottom: 20px;
      font-size: 22px;
    }
    a {
      text-decoration: none;
      color: $grey;
      font-weight: 400;
      margin-bottom: 15px;
      transition: color 300ms;
      &:hover,
      &.active {
        color: $green;
      }
    }

    @include mQ(992px) {
      width: 100%;
      position: relative;
    }
    span {
    }
    .tooltip-block {
      background-color: blue !important;
      & > .tooltip-inner {
        background-color: red !important;
      }
    }
    #business-details-missing-tooltip > .tooltip-inner {
      background: red !important;
    }
    svg.warning {
      stroke: $red;
      width: 25px;
      height: auto;
      padding: 5px;
      background-color: rgba($red, 0.1);
      border-radius: 8px;
      stroke-width: 2px;
      margin-left: 15px;
    }
  }
  .help-center-content {
    width: 71%;
    @include mQ(992px) {
      width: 100%;
    }
  }
}
