@import "variables";
@import "mixins";

.contact-page {
  .content {
    padding: $space-3xl-7xl $space-m-3xl;

    h1 {
      font-weight: 700;
    }

    .contact-form {
      margin-top: 64px;

      @include mQ(992px) {
        margin-top: 24px;
      }
      p {
        margin-bottom: 0px;
        opacity: 0;
        &.show {
          opacity: 1;
        }
      }
      * > div {
        width: 100%;
      }

      input,
      textarea {
        border: none;
        outline: none;
        background: none;
        border-bottom: 1px solid rgba($dark-blue-2, 0.48);
        color: $dark-blue-2;
        border-radius: 0px;
        padding: 5px 15px;
        padding-left: 0px;
        margin-top: $space-m-2xl;
        &::placeholder {
          color: rgba($dark-blue-2, 0.5);
        }
      }

      .names,
      .email-phone {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 70px 30px;
        gap: 25px;
        align-content: flex-start;

        @include mQ(992px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 0px;
        }
        & > div {
        }
      }
      textarea {
        height: 100px;
      }

      button {
        width: 100%;
        max-width: none;
        margin-top: 24px;
        div {
          width: 30px;
          height: 30px;
        }
      }

      .error {
        * {
          color: $red;
          border-color: $red;
        }
      }
    }
  }
  .success {
    text-align: center;

    h4 {
      margin-bottom: 18px;
    }

    svg,
    p {
      margin-bottom: $space-m-2xl;
    }
    p {
      width: 40%;
    }
  }
  .image {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
}
