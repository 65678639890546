@import "variables";
@import "mixins";
.account {
  padding-top: 25px;
  .accountMain {
    width: 71%;
    @include mQ(992px) {
      width: 100%;
    }
    .modal-content.confirmation {
      width: 900px;
      padding: 35px;
      height: auto;
      align-items: center;
      border-radius: 16px;
      border: none;
      outline: none;
      box-shadow: none;
      & > div {
        text-align: center;
        width: 80%;
        button {
          width: 49%;
        }
      }
      svg {
        background-color: rgba($green, 0.1);
        width: 100px;
        height: 100px;
        padding: 20px;
        border-radius: 50%;
        margin-bottom: 30px;
      }

      .delete-button {
        background: none;
        color: $red;
        transition: background-color 200ms;
        &:hover {
          background-color: rgba($red, 0.1);
        }
      }

      @include mQ(1200px) {
        width: 40vw;
        & > div {
          width: 80%;
          text-align: center;
          button {
            width: 100%;
          }
        }
      }
      @include mQ(768px) {
        width: 90vw;
        padding: 25px;
        & > div {
          width: 90%;
          text-align: center;
          button {
            width: 100%;
          }
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 10px 0;
      text-decoration: none;
      &:hover {
        background-color: $dark-green;
      }
    }

    svg {
      &.stroke-danger {
        &:hover {
          stroke: $red !important;
        }
      }
    }

    .address-content {
      a {
        color: $grey;
        font-size: 16px;
        cursor: pointer;
        h4 {
          font-weight: normal;
        }
        &.active {
          text-decoration: none;
        }
        &:hover {
          color: $green;
          text-decoration: none;
        }
      }

      .content-head {
      }

      .modal-content.confirmation {
        svg {
          padding: 28px 0px;
        }
      }
      .address-list {
        max-height: 70vh;
        overflow-y: auto;
        .address-item {
          background-color: $white;
          svg {
            background: none;
            width: auto;
            margin: auto;
            padding: 0;
          }
          .address-info {
            h3 {
              font-weight: 600;
              font-size: 18px;
            }
            p {
              color: $dark-grey;
              font-size: 14px;
              margin: 0px;
            }
            .address {
              color: $dark-grey;
            }
          }
          a {
            margin: 5px;
            font-size: 16px;
            cursor: pointer;
            display: inline;
            height: fit-content;
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .cards-content {
    .cards-list {
      .card-list-item {
        position: relative;
        @include mQ(992px) {
          padding-bottom: 75px;
        }
        .card-icon {
          width: 70px;
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
        .card-number {
          color: $green;
          font-weight: bold;
        }

        button {
          border: 2px solid $green;
          color: $green;
          background: none;
          height: 40px;
          margin-right: 15px;
          &:disabled,
          &[disabled] {
            background-color: $green-10 !important;
            color: $green;
            border: none;
          }
        }

        .settings-actions {
          position: inherit;
          button {
            @include mQ(992px) {
              position: absolute;
              bottom: 15px;
              width: calc(100% - 60px);
              margin: auto;
            }
          }
        }

        a {
          svg {
            background: none;
            stroke: $grey;
            width: 25px;
            margin: 0px;
          }
          &:hover {
            svg {
              stroke: $red;
            }
          }
        }

        &.selectable:hover {
          background-color: $green;
          color: $white;
          .card-number {
            color: $dark-grey;
            font-weight: bold;
          }
          a {
            margin: 5px;
            font-size: 16px;
            cursor: pointer;
            &:hover {
              color: $dark-grey;
            }
          }
        }
      }
    }
    label {
      text-align: left;
      color: $dark-grey;
      font-size: $step-0;
      margin: 0px;
    }
    .stripe-card-form {
      .card-field {
        margin-bottom: 10px;
        border: 1px solid $green;
        border-radius: 8px;
        padding: 10px;
      }
    }
    .paystack-card-form {
      .react-tel-input {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        input {
          @include textBox;
          border-color: $green;
          width: 84%;
          @include mQ(992px) {
            width: 81%;
          }
          margin-bottom: 10px !important;
          font-size: $step-0;
          &::placeholder {
            color: $grey;
          }
        }
        .flag-dropdown {
          @include textBox;
          margin-bottom: 10px;
          position: relative;
          width: 15%;
          background-color: $white;
          border-color: $green;
          @include mQ(992px) {
            width: 18%;
          }

          div.selected-flag {
            padding: 16px;
            background: none;
            width: 100%;
            .flag {
              scale: 1.5;
              .arrow {
                scale: 0.75;
              }
            }
          }

          &.open {
            border-radius: 7px;
          }

          .country-list {
            position: fixed;
          }
        }
      }
    }

    .create-card {
      .card-section {
        &.head {
          a {
            color: $green;
            &:hover {
              color: $black;
            }
          }
        }

        .type-switch {
          margin-bottom: 25px;
          .dropdown-toggle {
            border: none;
            background-color: rgba($green, 0.1);
            color: $green;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 45px;
            width: 100%;
            border-radius: 9px;
            &:focus {
              box-shadow: none;
              border: 1px solid $green;
            }
            &:hover {
              background-color: rgba($green, 0.3);
            }
          }
          .dropdown-item {
            &:hover {
              background-color: rgba($green, 0.3);
              color: $black;
            }
          }
        }
      }
    }
  }

  .api {
    .head {
      a {
        background: none;
        color: $green;
        width: fit-content;
        text-align: end;
        &:hover {
          color: $black;
        }
      }
    }
    .token-list {
      padding-top: 25px;
      button {
        margin: 25px 0px;
        width: fit-content;
        padding: 25px;
      }
    }
    .token-item {
      background-color: rgba($bg-grey, 0.4);
      padding: 15px;
      border-radius: 10px;
      margin-bottom: 20px;
      * {
        font-size: 14px;
      }
      .info {
        margin: 0px 15px;
        &.date {
          font-size: 12px;
        }
      }

      svg.trash {
        cursor: pointer;
        background: none;
        stroke: $dark_grey;
        &:hover {
          stroke: $red;
        }
      }
    }
    span.caps {
      color: $green;
    }
    .generate {
      input {
        @include textBox;
      }
      .success {
        background-color: rgba($green, 0.1);
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 25px;
        color: $green;
      }
      .token-container {
        margin-bottom: 25px;
      }
      button {
        margin: 25px 0px;
        width: fit-content;
        padding: 25px;
      }

      .copy {
        background: none;
        border-radius: 10px;
        padding: 10px 5px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: rgba($green, 0.1);
        }
        svg {
          background: none;
          padding: 10px 5px;
          margin: 0px;
          border-radius: 0px;
          fill: $green;
          width: 100%;
          height: auto;
          &.success {
            padding: 0px;
            fill: none;
            stroke: $green;
            animation: rotate 250ms ease-in;
          }
          &.fail {
            padding: 0px;
            fill: none;
            stroke: $red;
            animation: rotate 250ms ease-in;
          }
          @keyframes rotate {
            from {
              opacity: 0;
              transform: rotate(180deg);
            }
            to {
              opacity: 1;
              transform: rotate(0deg);
            }
          }
        }
      }
    }

    .modal-content {
      background-color: $bg_grey;
      padding: 0px;
      border-radius: 16px;
      &.generate {
        max-width: 800px;
      }
      .settings-card {
        margin-bottom: 0px;
      }
    }
    .modal-content.confirmation {
      svg {
        padding: 23px 0px;
      }
    }

    .testing {
      button {
        &.live {
          background-color: $yellow;
          color: $black;
          &:hover {
            background-color: rgba($yellow, 0.5);
          }
        }

        &.test {
          background-color: $green;
          color: $white;
          &:hover {
            background-color: rgba($green, 0.5);
          }
        }
      }
    }
  }

  .stores {
    .modal-content.store-name {
      background-color: $bg-grey;
      .last {
        margin-bottom: 0px;
      }
    }
    input {
      @include textBox;
    }
    .card-section.head {
      a {
        color: $green;
        text-align: end;
        &:hover {
          color: $black;
        }
      }
    }
    .card-section.store-item {
      .store-icon {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 50px;
        img {
          width: 100%;
          height: auto;
        }
        svg {
          stroke: $green;
          min-width: 40px;
          min-height: 40px;
        }
      }
      .store-name {
        margin-bottom: 0px;
        font-weight: 500;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      button {
        background-color: $white;
        border: $green 1px solid;
        color: $green;
        max-width: none !important;
        width: fit-content;
        padding: 8px 9px;
        &:hover {
          background-color: rgba($green, 0.2);
        }
        &.active {
          color: $red;
          border-color: $red;
          &:hover {
            background-color: rgba($red, 0.2);
          }
        }
      }
    }

    .modal-content.store-list {
      background-color: $bg_grey;
      border-radius: 16px;
      .card-section.last {
        margin-bottom: 0px;
        button {
          margin: 0px 0px 0px auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .account-details {
    .account-detail-item {
      display: grid;
      grid-template-columns: 0.5fr 0.5fr;
      grid-template-rows: auto;
      //margin-bottom: 25px;
      @include mQ(922px) {
        grid-template-columns: auto;
        grid-template-rows: auto auto;
      }
      & > div {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        row-gap: 10px;
        width: fit-content;
        margin-bottom: 25px;
        align-items: center;

        svg {
          stroke: $green;

          &.fill {
            fill: $green;
            stroke: none;
          }
        }
      }
    }
    .card-section {
      svg {
        width: 25px;
        height: auto;
        margin-right: 15px;
      }
      .warning {
        padding: 25px;
        background-color: rgba($green, 0.1);
        border-radius: 10px;
        margin-bottom: 25px;

        svg {
          width: 30px;
          stroke-width: 2px;
          stroke: $red;
          padding: 0px;
          background: none;
        }
        h4 {
          font-weight: 500;
        }

        button {
          width: fit-content;
          padding: 15px 20px;
          white-space: nowrap;
        }
      }
    }
    .address-redirect,
    .account-details-redirect {
      a {
        @include buttonMain;

        padding: 13px 20px;

        &:hover {
          text-decoration: none;
          color: $white;
        }
      }
    }

    .business-details {
      .business-info-section {
        @include mQ(922px) {
          grid-template-columns: auto;
          grid-template-rows: auto auto;
        }
        & > div {
          display: grid;
          grid-template-columns: 50px calc(100% - 50px);
          grid-template-rows: auto auto;
          row-gap: 10px;
          width: 100%;
          margin-bottom: 25px;
          align-items: center;
          svg {
            stroke: $green;
            &.fill {
              fill: $green;
              stroke: none;
            }
          }
          .bank-info {
            width: fit-content;
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto auto auto;
            row-gap: 5px;
            p {
              &.info {
                margin-right: 24px;
              }
            }
          }
          .business-document {
            padding: 20px;
            background-color: rgba($green, 0.1);
            border-radius: 12px;
            min-width: clamp(200px, 25%, 800px);
            width: fit-content;
            max-width: 100%;
            cursor: pointer;
            transition: 0.3s all;
            & > div {
              width: 80%;

              p {
                width: 100%;
                color: $green;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                &.info {
                  color: rgba($dark-grey, 0.6);
                }
              }
            }
            svg.download-icon {
              width: 25px;
              padding: 0px;
              height: auto;
              background: none;
              margin: 0px;
            }
            &:hover {
              background-color: rgba($green, 0.25);
            }

            .spinner-border {
              width: 25px;
              height: 25px;
              color: $green;
            }
          }
        }
      }
      .divider {
        margin: 25px;
        border-top: 1px solid $light-grey;
      }
    }
  }

  &.test-mode {
    .menu-container {
      // a{
      //     &:hover,&.active{

      //         color:$yellow-2
      //     }
      // }
    }

    .settings-card {
      .card-section.head {
        // a,a.action{
        //     color:$yellow-2 !important;
        // }
      }

      &.cards-content {
        .card-list-item {
          button {
            border-color: $yellow-2;
            color: $yellow-2;
            &:disabled,
            &[disabled] {
              background-color: rgba($yellow-2, 0.2) !important;
            }
            &:hover {
              background-color: rgba($yellow-2, 0.3);
            }
          }
        }

        .stripe-card-form,
        .paystack-card-form {
          .card-field {
            border-color: $yellow-2;
          }
          button {
            background-color: $yellow-2;
            &:hover {
              background-color: ($yellow);
            }
          }
          .react-tel-input {
            input {
              border-color: $yellow-2;
            }
            .flag-dropdown {
              border-color: $yellow-2;
            }
          }
        }
        .modal-content.confirmation {
        }

        .create-card {
          .card-section {
            &.head {
              a {
                color: $yellow-2;
                &:hover {
                  color: $black;
                }
              }
            }
            .type-switch {
              button {
                background-color: $yellow-2;
                &:hover {
                  background-color: $yellow;
                }
              }
              .dropdown-toggle {
                border: none;
                background-color: rgba($yellow-2, 0.1);
                color: $yellow-2;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 45px;
                width: 100%;
                border-radius: 9px;
                &:focus {
                  box-shadow: none;
                  border: 1px solid $yellow-2;
                }
                &:hover {
                  background-color: rgba($yellow-2, 0.3);
                }
              }
              .dropdown-item {
                &:hover {
                  background-color: rgba($yellow-2, 0.3);
                  color: $black;
                }
              }
            }
          }
        }
      }
      &.address-content {
        .address-list {
          .address-item {
            a {
              &:hover {
                color: $yellow-2;
                svg {
                  stroke: $yellow-2;
                }
              }
            }
          }
        }
      }
      .modal-content.confirmation {
        button:not(.delete-button) {
          background-color: $yellow-2;
          &:hover {
            background-color: $yellow;
          }
        }
      }
    }

    .api {
      .modal-content {
        button {
          background-color: $yellow-2;
          &:hover {
            background-color: $yellow;
          }
        }
      }
    }

    .stores {
      .settings-card {
        & > .card-section {
          & > button {
            background-color: $yellow-2;
            color: $black;

            &:hover {
              background-color: rgba($yellow-2, 0.2);
            }
          }
        }
      }
      .store-item {
        button:not(.active) {
          border-color: $yellow-2;
          color: $yellow-2;
          background: none;
          &:hover {
            background-color: rgba($yellow-2, 0.2);
          }
        }
      }
    }

    .account-details {
      .account-detail-item {
        & > div {
          svg {
            stroke: $yellow-2;

            &.fill {
              fill: $yellow-2;
              stroke: none;
            }
          }
        }
      }
      .card-section {
        .warning {
          background-color: rgba($yellow-2, 0.1);
          button {
            background-color: $yellow-2;
            &:hover {
              background-color: rgba($yellow-2, 0.2);
              color: $yellow-2;
            }
          }
        }
      }

      .business-details {
        .business-info-section {
          & > div {
            svg {
              stroke: $yellow-2;
              &.fill {
                fill: $yellow-2;
                stroke: none;
              }
            }
            .business-document {
              background-color: rgba($yellow-2, 0.1);
              border: none;
              border-color: $yellow;
              & > div {
                p {
                  color: $yellow-2;
                  &.info {
                    color: rgba($dark-grey, 0.6);
                  }
                }
              }
              &:hover {
                background-color: rgba($yellow, 0.25);
                border: 1px solid $yellow;
              }
            }
          }
        }
      }

      .address-redirect,
      .account-details-redirect {
        a {
          background-color: $yellow-2;

          &:hover {
            background-color: rgba($yellow-2, 0.2);
            color: $black;
          }
        }
      }
    }
  }
}
.address-form {
  &.settings-card.modal-content {
    background-color: $bg-grey;
    .card-section.head {
      a {
        svg {
          &:hover {
            stroke: $green;
          }
        }
      }
    }
    .card-section.last {
      margin-bottom: 0px;
      .row-fields {
        & > * {
          width: 48%;
          @include mQ(992px) {
            width: 100%;
          }
        }
      }

      .country-select {
        position: relative;
        .search-results {
          display: none;
          position: absolute;
          max-height: 500px;
          overflow-y: auto;
          top: calc(100% + 10px);
          z-index: 2500;
          background: $white;
          border-radius: 8px;
          width: 100%;
          box-shadow: 0 2px 8px $light-grey;
          a {
            display: unset;
            color: $black;
            padding: 10px 20px;
            &:hover {
              background-color: rgba($green, 0.1);
            }
          }
        }
        & > input:focus + .search-results,
        .search-results:focus,
        .search-results *:focus,
        .search-results:active {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .form-check-label {
      .default-address-checkbox {
        border-radius: 5px;
        border: 1px solid $grey;
        background: none;
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          border-color: $green;
        }

        svg {
          display: none;
          width: 20px;
          height: auto;
          padding: 0;
          margin: 0;
          background: none;
          stroke: $white;
        }

        &.active {
          border-radius: 5px;
          border: none;
          background-color: $green;

          svg {
            display: flex;
          }
        }
      }
    }
  }
  label:not(.form-check-label) {
    color: rgba($dark-grey, 0.7);
    margin-bottom: 20px;
    position: relative;
    span.required {
      color: $red;
      margin-left: 3px;
    }
  }

  input[type="text"],
  select {
    @include textBox;
    // border: 1.5px solid $dark-grey;
    // border-radius: 8px;
    // color: $dark-grey;
    // padding: 8px 5px;
    border-color: $dark-grey;
  }
  label p {
    display: none;
  }
  input,
  .dropdown button {
    border-color: $dark-grey;
  }

  .country-list {
    overflow-y: auto;
    height: 300px;
    box-shadow: 3px 3px 30px -10px $grey;
  }
  .country-toggle {
    @include textBox;
    background: none;
  }

  a {
    cursor: pointer;
    &:hover {
      color: $green;
    }
  }
  .save-address {
    margin-top: 20px;
    button {
      width: 100%;
    }
  }

  .react-tel-input {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    input {
      @include textBox;
      border-color: $dark-grey;
      width: 84%;
      @include mQ(992px) {
        width: 81%;
      }
      margin-bottom: 10px;
      font-size: $step-0;
      &::placeholder {
        color: $grey;
      }
    }
    .flag-dropdown {
      @include textBox;
      border-color: $dark-grey;
      position: relative;
      width: 15%;
      margin-bottom: 10px;
      background-color: $white;
      padding: 0;
      @include mQ(992px) {
        width: 18%;
      }

      div.selected-flag {
        padding: 16px;
        background: none;
        width: 100%;
        .flag {
          scale: 1.5;
          .arrow {
            scale: 0.75;
          }
        }
      }

      &.open {
        border-radius: 7px;
      }
    }
  }

  .phone-field {
    .dropdown {
      width: 10%;
      button > div {
        border-radius: 50%;
      }
    }
    input {
      width: 89%;
    }
  }

  .error {
    input,
    select {
      border-color: $red;
    }
    p.error {
      display: inline-block;
      color: $red;
    }
  }
}

.create-address-button {
  @include buttonMain;
  width: 13rem;
  height: 48px;
  color: white;
  background-color: $green;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: #178b79;
  }

  &:active {
    background-color: #168675;
  }

  &:focus {
    border: none;
    outline: 0;
  }
  &.error {
    background-color: $red;
  }
}
