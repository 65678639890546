@import "variables";
@import "mixins";

.account-form-header {
  width: 100%;
  height: 7.5vh;
  position: relative;
  p {
    color: $green;
  }
  svg {
    stroke: $green;
  }

  .progress-item {
    width: calc(100% / 3);
    height: 100%;
    display: flex;

    @include mQ(992px) {
      display: none;
      width: 100%;
      padding: 25px;
    }

    svg {
      display: none;
    }

    .progress-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $grey;
      margin-right: 5px;
    }

    p {
      color: $grey;
    }
  }

  .progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    border-bottom: 4px solid $green;
  }

  &.edit {
    .progress-item {
      display: none;
    }
  }

  .progress-item.active {
    display: flex;

    .progress-circle {
      border-color: $dark-grey;
    }
    p.progress-title {
      color: $dark-grey;
    }
  }
  .progress-item.done {
    svg {
      display: flex;
    }

    .progress-circle {
      display: none;
    }

    p.progress-title {
      color: $green;
    }
  }
}

.account-form {
  position: relative;

  .next-step-prompt {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: 5000;
    .prompt-content {
      margin: auto;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > * {
        &:nth-child(1) {
          margin-bottom: 50px;
          height: 35%;
          min-height: 250px;
          width: auto;
        }
        &:nth-child(2) {
          margin-bottom: 25px;
          h3 {
            margin-bottom: 50px;
          }
        }
        &:nth-child(3) {
          button {
            margin-bottom: 25px;
            &:nth-child(1) {
              background: none;
              border: 1px solid $green;
              color: $green;
              &:hover {
                background-color: rgba($green, 0.2);
              }
            }
          }
        }
      }
    }
  }

  .business-prompt-complete {
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: 5000;
    .prompt-content {
      margin: auto;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > * {
        &:nth-child(1) {
          margin-bottom: 50px;
          height: 35%;
          min-height: 250px;
          width: auto;
        }
        &:nth-child(2) {
          margin-bottom: 25px;
          h3 {
            margin-bottom: 50px;
          }
        }
      }
      button {
        background-color: $green;
        color: $white;
        border: none;
        width: fit-content;
        padding: 5px 15px;
        min-width: 300px;
      }
    }
  }

  & > div:nth-child(1) {
    width: 100%;
    padding: 70px;
    .header {
      height: max-content;
      text-decoration: none;
      margin-bottom: $space-m-2xl;
      & > div {
        border-radius: 50%;
        background-color: $green;
        width: 50px;
        height: 50px;
        padding: 12px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: auto;
        }
      }

      h2 {
        color: $green;
        font-weight: 600;
        font-size: 24px;
      }
    }

    .why {
      background-color: rgba(darken($bg-grey, 10%), 0.3);
      border-radius: 12px;
      padding: 15px;
      p {
        margin-bottom: 0px;
        width: calc(100% - 40px);
      }
      svg {
        width: 20px;
        height: auto;
        margin-top: 8px;
        margin-right: 15px;
      }
    }
  }
  &.login,
  &.signup,
  &.verify {
    & > div:nth-child(1) {
      background-color: #1e8b7c;
      * {
        color: $white;
      }
      .header {
        & > div {
          background-color: $white;
        }

        h2 {
          color: $white;
        }
      }
      .intro {
        width: 90%;
        .visual,
        svg {
          margin-top: 50px;
          width: 100%;
        }
        p {
          font-size: $step-1;
        }
      }
    }
  }

  &.verify {
    .patch {
      span.email {
        color: $green;
      }
      a.signin {
        @include buttonBorder;
        background: none;
        width: fit-content;
        padding: 5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        &:hover {
          background-color: rgba($green, 0.2);
        }
      }
    }
  }
}

.account-form {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  min-height: 92.5vh;
  @include mQ(992px) {
    grid-template-columns: 1fr;
  }

  label {
    margin-bottom: 15px;
  }

  & > div {
    padding: 25px;
    h3 {
      color: $dark_grey;
      font-weight: 600;
    }
  }

  & > div:nth-child(1) {
    background-color: $bg-grey;

    & > a {
      flex: 0.1 1 auto;
    }
    & > *:nth-child(2) {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: center;
    }
    & > .why {
      flex: 0 1 auto;
    }

    ul {
      margin-left: 55px;
      li {
        list-style: disc;
        color: $dark-grey;
        font-size: $step-1;
        font-weight: 300;
        margin-bottom: 7px;
      }
    }
  }
  & > div:nth-child(2) {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;
      height: fit-content;
      .error-block {
        width: 100%;
        height: fit-content;
        overflow: hidden;
        background-color: rgba($red, 0.05);
        padding: 15px;
        border-radius: 10px;
        text-align: start;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 25px;
        p {
          color: $red;
          height: fit-content;
          word-break: break-all;
          white-space: normal;
          margin: 0px;
        }
        svg {
          stroke: $red;
          min-width: 25px;
          height: auto;
          margin-right: 20px;
        }
      }

      & > div.form {
        flex: 1 1 auto;
        & > * {
          flex: unset;
        }

        .country-select {
          position: relative;
          .search-results {
            display: none;
            position: absolute;
            max-height: 500px;
            overflow-y: auto;
            top: calc(100% + 10px);
            z-index: 2500;
            background: $white;
            border-radius: 8px;
            width: 100%;
            padding-top: 20px;
            box-shadow: 0 2px 8px $light-grey;
            a {
              padding: 10px 20px;
              margin-bottom: 0;
              &:hover {
                background-color: rgba($green, 0.1);
              }
            }
          }
          & > input:focus + .search-results,
          .search-results:focus,
          .search-results *:focus,
          .search-results:active {
            display: flex;
            flex-direction: column;
          }
        }

        label {
          text-align: left;
          color: $dark-grey;
          font-size: $step-0;
          p.error {
            display: none;
            color: $red;
          }
          &.error {
            input {
              border-color: $red;
            }
            p.error {
              display: flex;
            }
          }

          input {
            @include textBox;
            margin-bottom: 10px;
            font-size: $step-0;
            &::placeholder {
              color: $grey;
            }
          }
        }
        .dropdown-toggle {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .file-upload {
          width: 100%;
          min-width: fit-content;
          height: fit-content;
          padding: 15px;
          border-radius: 8px;
          border: 1px $grey solid;
          transition: 0.4s all;
          &.error {
            border-color: $red;
          }
          * {
            font-family: "Work Sans", "Public Sans", "Roboto", sans-serif;
            font-weight: 400;
          }
          input {
            display: none;
          }

          .content {
            &.empty,
            &.drag {
              .icon {
                background-color: rgba($grey, 0.1);
                padding: 8px;
                border-radius: 50%;
                margin-bottom: 15px;
                svg {
                  padding: 8px;
                  background-color: rgba($grey, 0.3);
                  border-radius: 50%;
                  transition: 0.4s all;
                }
              }
              p {
                span {
                  color: $green;
                  font-weight: 500;
                }
              }
            }
            &.drag {
              .icon {
                background-color: rgba($grey, 0.3);
                svg {
                  background-color: rgba($grey, 0.5);
                }
              }
            }
            &.file {
              display: grid;
              grid-template-columns: 0.1fr auto;
              grid-template-rows: auto;
              gap: 10px;
              & > * {
              }
              & > *:nth-child(2) {
                p {
                  margin-bottom: 0px;
                  &.doc-name {
                    width: calc((((100vw * 0.6) * 7 / 12) - 80px) * 0.8 - 25px);
                    text-overflow: ellipsis;
                    overflow: hidden;

                    font-weight: 500;
                    @include mQ(992px) {
                      width: calc(((100vw) - 80px) * 0.8 - 25px);
                    }
                  }
                  &.doc-size {
                    font-weight: 400;
                    color: rgba($dark-grey, 0.6);
                  }
                }
              }
              svg {
                width: 35px;
                height: auto;
                padding: 8px;
                background-color: rgba($light-blue, 1);
                stroke: $green;
                border-radius: 50%;
                &.uploaded {
                  margin-top: 5px;
                  width: 16px;
                  height: 16px;
                  background-color: $green;
                  stroke: $white;
                  padding: 2.5px;
                  stroke-width: 4px;
                }
              }
              .spinner-border {
                margin-top: 5px;
                width: 16px;
                height: 16px;
                border: 0.15em solid $green;
                border-right-color: transparent;
              }
              .progress-bar {
                background-color: rgba($green, 0.2);
                height: 10px;
                width: 100%;
                margin-right: 20px;
                border-radius: 5px;
                .progress-bar-filler {
                  transition: all 0.5s;
                  background-color: $green;
                  border-radius: 5px;
                  height: 10px;
                }
              }
            }
          }

          &:hover {
            border-color: $green;
            .content.empty {
              svg {
                width: 48px;
                height: 48px;
              }
            }
          }

          &.active {
            background-color: rgba($grey, 0.2);
            border-width: 2px;
          }
        }

        .react-tel-input {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          input {
            @include textBox;
            width: 84%;
            @include mQ(992px) {
              width: 81%;
            }
            margin-bottom: 10px;
            font-size: $step-0;
            &::placeholder {
              color: $grey;
            }
          }
          .flag-dropdown {
            @include textBox;
            position: relative;
            width: 15%;
            margin-bottom: 10px;
            background-color: $white;
            @include mQ(992px) {
              width: 18%;
            }
          }
        }

        .phone-field {
          .dropdown {
            width: 10%;
            button > div {
              border-radius: 50%;
            }
          }
          input {
            width: 89%;
          }
        }

        .dropdown {
          width: 100%;
          margin-bottom: 10px;
          p.placeholder {
            color: $grey;
            margin-bottom: 0px;
          }
          button {
            @include textBox;
            background: none;
            width: 100%;
          }
        }

        .dropdown-toggle {
          &:focus,
          &:hover {
            background: none;
            color: $black;
            border: none;
            box-shadow: none;
          }
        }
        .dropdown-item {
          &:hover {
            background-color: rgba($green, 0.3);
            color: $black;
          }
        }
        .dropdown.show {
          .btn-primary.dropdown-toggle {
            background-color: rgba($green, 0.3);
            color: $black;
          }
        }
      }

      & > h3 {
        flex: 0.2 1 auto;
      }

      & > div.buttons {
        flex: 0.05 1 auto;
      }

      a.skip {
        color: $green;
        margin-top: 50px;
        &:hover {
          color: $dark-grey;
        }
      }

      a.logoutButton {
        color: $dark-grey;
        margin-top: 50px;
        &:hover {
          color: $green;
        }
      }
    }
    a.back-button {
      position: absolute;
      top: 5%;
      left: 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      color: $grey;
      svg {
        width: 20px;
        height: auto;
        stroke: $grey;
      }
      &:hover {
        color: $green;
        svg {
          stroke: $green;
        }
      }
    }
  }
}
