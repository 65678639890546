@import "variables";
@import "mixins";

.footer {
  padding: $space-3xl-7xl $space-m-3xl;
  padding-bottom: 0px;
  background-color: $white;
  z-index: 1500;
  // position:absolute;
  bottom: 0px;

  * {
    color: $dark-grey;
    button {
      color: $white;
    }
  }

  p,
  li {
    font-weight: 300;
  }

  a {
    color: $dark-blue-2;
    &:hover {
      color: $green;
    }
  }

  .content {
    margin-bottom: $space-3xl-7xl;
    .main-content {
      & > p {
        margin-bottom: $space-m-2xl;
      }
      & > div {
        margin-bottom: $space-m-2xl;
      }
    }
    .header {
      height: max-content;
      text-decoration: none;
      margin-bottom: $space-m-2xl;
      & > div {
        border-radius: 50%;
        background-color: $green;
        width: 50px;
        height: 50px;
        padding: 12px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 100%;
          height: auto;
        }
      }

      h2 {
        color: $green;
        font-weight: 600;
        font-size: 24px;
      }
    }
    h5 {
      margin-bottom: $space-m-2xl;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
    }
    span.soon {
      background-color: $green;
      color: $white;
      border-radius: 20px;
      padding: 1px 10px;
      font-size: 11px;
      margin-left: 10px;
    }

    & > div {
      @include mQ-min(992px) {
        width: 15%;
      }
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
    & > div:nth-child(1) {
      @include mQ-min(992px) {
        width: 35%;
      }
    }
    a {
      font-weight: normal;
      color: $dark-grey;
      &:hover {
        color: $green;
        text-decoration: none;
      }

      &.button {
        @include buttonMain;
        margin-bottom: $space-s-m;
        justify-content: center;
        align-items: center;
        width: 126px;
      }
    }
  }

  .bottom-banner {
    @include mQ-min(992px) {
      border-top: 1px $dark-blue-2 solid;
    }
    padding-top: $space-s-m;
    flex-direction: column-reverse;
    margin-bottom: $space-m-2xl;

    & > * {
      width: fit-content;
      @include mQ(992px) {
        margin-bottom: 25px;
      }
    }

    a:hover {
      text-decoration: none;
    }
    .socials {
      a {
        margin-left: 10px;
      }
      svg {
        background-color: $green;
        width: 35px;
        height: 35px;
        padding: 5px 8px;
        border-radius: 50%;
        stroke: $white;
        stroke-width: 1.5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      a:nth-child(4) {
        svg {
          fill: $white;
          stroke: none;
        }
      }
      svg:hover {
        background-color: darken($green, 5%);
        padding: 3px 7px;
      }
      @include mQ(992px) {
        width: 100%;
        justify-content: space-between;
        a {
          margin-left: 0px;
        }
      }
    }

    .links {
      & > * {
        margin: 0px 5px;
        font-weight: normal;
      }
    }
  }
}
