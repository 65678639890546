@import "mixins";
@import "variables";

section.how-it-works {
  .content {
    & > * {
      margin: 50px auto;
    }
  }
  .items {
    div {
      h4 {
        font-size: 18px;
        margin: 30px 0 15px 0;
        @include mQ-min(1200px) {
          font-size: 20px;
        }
      }
      p {
        text-align: center;
        font-size: 16px;
        @include mQ-min(1200px) {
          font-size: 18px;
        }
      }
    }
  }
}

section.smart-shiping {
  // display: flex;
  position: relative;
  // justify-content: center;
  // align-items: center;
  .smart-ship-line {
    position: absolute;
    display: flex;
    top: 0px;
    // left: 0;
    width: 100%;
    height: 100%;
    // z-index: 10;
    img {
      width: 75%;
      // height: 100px;
    }
  }
  @include mQ(768px) {
    padding-top: 100px;
  }
  h1 {
    // font-size: 72px;
    @include mQ-min(1200px) {
      font-size: 72px;
    }
  }
  background-color: $light-blue;
  .green {
    color: $green;
  }
}

section.shipshap-for {
  h3 {
    font-size: 24px;
  }
  .toggleBar {
    position: relative;
    background-color: $light-blue;
    border-radius: 20px;
    margin: 40px auto;
    z-index: 100;
    h4 {
      font-size: 18px;
      @include mQ-min(1200px) {
        font-size: 22px;
      }
    }
    .toggleItem {
      padding: 5px 8px;
      border-radius: 20px;
      z-index: 500;
      color: $green;
      transition: color 200ms linear;
      height: 60px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      &.active {
        color: $white;
      }
      &:hover:not(.active) {
        color: $active-green;
      }
      @include mQ-min(1200px) {
        height: 72px;
      }
    }
    .highlight {
      background-color: $green;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      transition: left 200ms linear;
      border-radius: 20px;
      &.right {
        left: 50%;
      }
    }
    @include mQ-min(1200px) {
      margin: 75px auto;
    }
  }
  .toggleContent {
    // display: flex;
    flex-direction: row;
    position: relative;
    #content1,
    #content2 {
      // display: none;
      width: 0px;
      height: 0px;
      overflow: hidden;
      transition: width 200ms linear;
    }
    #content1.active,
    #content2.active {
      width: 100%;
      height: fit-content;
      display: flex;
      left: 0px;
    }
    ul {
      li {
        display: flex;
        flex-direction: row;
        margin: 50px 0;
        height: 50px;
        img {
          margin-right: 20px;
          height: 75%;
          width: auto;
        }
        div {
          // height: 100%;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-around;
          * {
            // margin: 0;
          }
          h5 {
            font-size: 22px;
          }
        }
      }
    }
  }
}
section.smartest-way {
  @include mQ(768px) {
    padding-top: 100px;
  }
  h1 {
    @include mQ-min(1200px) {
      font-size: 72px;
    }
  }
  background-color: $light-blue;
  .green {
    color: $green;
  }
}
section.mission {
  background-color: $green;
  color: $white;
  .content {
    font-weight: 300;
    font-size: 18px;
    @include mQ-min(1200px) {
      font-size: 28px;
    }
  }
  .names {
    font-size: 20px;
    font-weight: bold;
  }
  .status {
    font-weight: 100;
  }
}
