.tracking-list li {
  list-style: circle;
}

.tracking-list li h6 {
  margin: 0;
}

.vl {
  border-left: 3px dotted #1a9b89;
  margin-right: 13px;
  padding-top: 5px;
}

.vl1 {
  margin-top: 25px;
  height: 50px;
}

.transit {
  color: #118677;
}
