@import "variables";
@import "mixins";

$h2-small: clamp(1.9597rem, 1.8678rem + 0.4596vw, 2.4883rem);
$h3: clamp(2.3027rem, 2.1839rem + 0.5942vw, 2.986rem);
$h3-big: clamp(2.7057rem, 2.553rem + 0.7631vw, 3.5832rem);
$h2: clamp(3.1791rem, 2.9843rem + 0.9745vw, 4.2998rem);
$h2-big: clamp(3.7355rem, 3.4878rem + 1.2385vw, 5.1598rem);
$h1-big: clamp(4.3892rem, 4.0757rem + 1.5674vw, 6.1917rem);
@font-face {
  font-family: "Celebri";
  src: url("../assets/Celebri.ttf") format("truetype");
  font-weight: normal;
}
.kq-landing {
  * {
    font-family: "Roboto", sans-serif !important;
  }

  p,
  button,
  a,
  b {
    font-family: "Celebri", "Public Sans", "Roboto", sans-serif !important;
  }

  section {
    width: 100%;
  }

  .masthead {
    background:
      linear-gradient(90deg, #000, #fff0, #000),
      url("../assets/kq-masthead.png") no-repeat center;
    background-size: cover;
    margin: 0;
    position: relative;

    .content {
      padding: $space-6xl-11xl $space-s-6xl;
      color: $white;
      @include mQ-min(992px) {
        min-height: 900px;
      }
      h1 {
        font-size: $h1-big;
        font-weight: 900;
        margin-bottom: 0;
      }
      h2 {
        font-size: $h2-small;
        letter-spacing: 3px;
        margin-bottom: 0;
      }
      p {
        max-width: 800px;
        font-size: $step-3;
        font-weight: 400;
      }
      & > div {
        gap: 25px;
        margin: 25px 0;
        button {
          height: 50px;
          border-radius: 6px;
          font-size: $step-0;
          padding: 12px 20px;
          width: fit-content;
        }
        a:last-child {
          button {
            @include buttonBorder;
            padding: 0 30px;
            background-color: $white;
            &:hover {
              background-color: $bg-grey;
            }
          }
        }
      }
    }
  }
  .economy-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: $space-6xl-11xl $space-s-6xl;
    h1 {
      font-size: $h3;
      color: $green;
      font-weight: 600;
    }
    p {
      font-size: $step-2;
      width: 80%;
      margin-bottom: $space-m-3xl;
    }
    video {
      width: 80%;
      height: auto;
      background: black;
    }
  }
  div.image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    img {
      width: 100%;
      height: auto;
    }
  }
  .designed-for {
    padding: $space-6xl-11xl $space-6xl-11xl;
    background: $green;
    * {
      color: $white;
    }

    .content {
      h2 {
        font-size: $h2;
        font-weight: 600;
        width: 80%;
        margin-bottom: 70px;
      }
      ul {
        list-style: disc;
        margin-left: $h2;
        width: 70%;
        @include mQ(992px) {
          width: 100%;
        }
      }
      p {
        font-size: $step-3;
        font-weight: normal;
      }
      li {
        font-size: $step-2;
        font-weight: normal;
        line-height: $h2-small;
      }
    }
    .image {
      img {
        width: 100%;
        height: auto;
      }
    }
    button {
      background-color: #f09235;
      padding: 15px 30px;
      width: fit-content;
      height: 60px;
      border-radius: 6px;
      margin: auto;
      font-size: $step-1;
      &:hover {
        background-color: #d38638;
      }
    }
  }
  .get-started,
  .simplify-shipping {
    padding: $space-6xl-11xl $space-6xl-11xl;
    .content {
      h2 {
        font-size: $h2;
        font-weight: 600;
        width: 80%;
        margin-bottom: 70px;
        color: $green;
      }
      ul {
        list-style: disc;
        margin-left: 150px;
        width: 70%;
      }
      p,
      b {
        font-size: $step-2;
        font-weight: normal;
      }
      b {
        font-weight: 600;
      }
    }
    .image {
      img {
        width: 100%;
        height: auto;
      }
    }
    button {
      background-color: #f09235;
      padding: 15px 30px;
      width: fit-content;
      height: 60px;
      border-radius: 6px;
      margin: auto;
      font-size: $step-1;
      &:hover {
        background-color: #d38638;
      }
    }
  }
  .everything-you-need {
    padding: $space-6xl-11xl $space-6xl-11xl;
    text-align: center;
    background-color: #cde5e1;
    h2 {
      font-size: $h3-big;
      font-weight: 600;
      width: 80%;
      margin-bottom: 70px;
      color: #413e3c;
    }
    button {
      background-color: #f09235;
      padding: 15px 30px;
      width: fit-content;
      height: 60px;
      border-radius: 6px;
      margin: auto;
      font-size: $step-1;
      &:hover {
        background-color: #d38638;
      }
    }
    .feature-cards {
      margin-bottom: 150px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      gap: $space-5xl-7xl;
      @include mQ(992px) {
        grid-template-columns: auto;
        grid-template-rows: 1fr 1fr 1fr;
      }
      & > div {
        border-radius: 25px;
        padding: 50px 30px;
        background-color: $white;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg,
        img {
          width: 250px;
          height: auto;
          margin-bottom: 25px;
        }

        h3 {
          font-size: $step-4;
          font-weight: 500;
          margin-bottom: 35px;
          width: 75%;
        }
        p {
          font-size: $step-2;
        }

        &.active {
          background-color: $green;
          * {
            color: $white;
          }
        }
      }
    }
  }
  .faq {
    padding: $space-6xl-11xl $space-6xl-11xl;
    text-align: center;
    h2 {
      font-size: $h3-big;
      font-weight: 600;
      width: 80%;
      color: #413e3c;
    }
    p {
      font-size: $step-2;
    }
    .accordion {
      margin: 100px 0;
      width: 100%;
    }
    .accordion-body {
      text-align: start;
      color: #413e3c;
      padding: 15px 0;
      p {
        font-size: $step-0;
      }
    }
    .accordion-item {
      border: 2px #413e3c solid;
      border-radius: 10px;
      background-color: #dfeae7;
      padding: 20px 25px;
      margin-bottom: 25px;
      transition: background-color ease-in-out 500ms;
      button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #413e3c;
        font-size: $step-1;
        font-weight: 600;
        background: none;
        padding: 0;
        text-align: start;
        line-height: $step-2;
        .minus {
          display: none;
        }
        &:not(.collapsed) {
          border-bottom: 1px solid rgba($black, 0.1);
          .plus {
            display: none;
          }
          .minus {
            display: block;
          }
        }
        &:focus {
          box-shadow: none;
          outline: none;
          border: none;
        }
      }

      &:hover {
        background-color: #ced9d6;
      }
    }
  }
  .event-details {
    background:
      linear-gradient(#fff0 50%, #000), url("../assets/kq-masthead.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: $space-6xl-11xl $space-6xl-11xl;
    .content {
      * {
        color: $white;
      }
      h2 {
        font-size: $h2;
        font-weight: 700;
        margin-bottom: 75px;
      }

      p,
      b {
        font-size: 24px;
      }
    }
    .event-cta {
      .cta-card {
        background-color: $white;
        border-radius: 10px;
        padding: 75px;
        text-align: center;
        h3 {
          color: #413e3c;
          font-size: $h3;
          font-weight: 700;
        }
        p {
          font-size: $step-2;
          margin: 25px;
          font-weight: 500;
        }
        button {
          height: 70px;
          //background: $green;
          font-size: $step-1;
          width: fit-content;
          padding: 0 45px;
        }
      }
    }
  }
  .simplify-shipping {
    .content {
      h2 {
        font-size: $h2;
        font-weight: 600;
      }
      p {
        font-size: $step-2;
      }
    }
  }
  .footer {
    padding: $space-5xl-7xl $space-6xl-11xl;
    background-color: #413e3c;
    text-align: center;
    * {
      color: $white;
    }
    .logo {
      height: max-content;
      text-decoration: none;
      margin: 100px;
      div {
        border-radius: 50%;
        background-color: $green;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        padding: 12px;
        margin-right: 15px;

        svg {
          width: 100%;
          height: auto;
        }
      }

      h2 {
        color: $green;
        font-weight: 600;
        font-size: $step-3;
      }
    }
    p {
      margin-bottom: 35px;
    }
    p,
    a {
      font-size: $step-0;
      font-weight: 600;
      &:hover {
        text-decoration: none;
      }
    }
    .links {
      @include mQ-min(768px) {
        gap: 10px;
        div {
          height: 25px;
          width: 0;
          border-left: 2px $white solid;
        }
      }
    }
  }
}
