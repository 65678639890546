@import "variables";
@import "mixins";

.wallets {
  .wallet-accounts {
    gap: 10px;
    margin-bottom: 24px;
    .account-item {
      //padding-right: 80px;
      display: flex;
      flex-direction: column;
      flex: 1 0;
      box-shadow: none;
      width: fit-content;
      margin-bottom: 0;
      p {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 24px;
      }
      h4 {
        font-size: 16px;
      }

      &.active {
        border: 2px solid $green;
      }

      &.disabled {
        * {
          color: $grey;
          border-color: $grey;
        }
      }
    }
  }

  h3 {
    margin-bottom: 15px !important;
  }

  .wallets-filter-section {
    & > div {
      margin: 5px 0;
    }
    button {
      padding: 0px 25px;
      width: fit-content;
      background: none;
      color: $green;
      &:hover {
        background-color: rgba($green, 0.2);
      }
    }

    .dropdown {
      margin-right: 12px;
      .dropdown-toggle {
        width: fit-content;
        min-width: 200px;
        border: 1px solid $grey;
        padding: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: none;
        &.placeholder {
          color: $grey;
        }
        &::after {
          margin-left: 24px;
        }
        &:hover {
          background-color: rgba($green, 0.2);
        }
      }
      .dropdown-menu.show {
        background-color: $white;
        .dropdown-item {
          color: $black;
        }
      }
    }
  }

  .list {
    min-width: 992px;
    overflow-y: auto;
    .transactions-list {
    }

    .react-bootstrap-table {
      thead {
        display: none;
      }
      tr:not(:has(.table-expanded-row)) {
        padding: 0 5px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background-color: rgba($light-grey, 0.4);
        }
      }
      td {
        border-top: none;
        &:nth-child(1) {
          width: 65px;
          padding-left: 15px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          svg {
            width: 100%;
            height: auto;
          }

          .transaction-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;

            padding: 10px;
            border-radius: 50%;
            &.credits {
              background-color: rgba($green, 0.1);
            }
            &.debits {
              background-color: rgba($red, 0.1);
              svg {
                fill: $red;
              }
            }
          }
        }
        &:nth-child(2) {
          p {
            color: $black;
            font-size: 14px;
            font-weight: 500;
            &.info {
              font-size: 12px;
              font-weight: 400;
              color: $text-grey;
            }
          }
        }
        &:nth-child(3) {
          width: 15%;
          padding-right: 15px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          .transaction-amount {
            p.amount {
              font-size: 16px;
              font-weight: 600;
              color: $black;
            }
            .status-item {
              min-width: 90px;
              justify-content: center !important;
              p {
                font-size: 11px;
                font-weight: 600;
              }
            }
          }
        }
      }

      .table-expanded-row {
        & > div {
          padding-bottom: 25px;
        }
        .expanded-row-payment-details {
          margin-bottom: 15px;
          p.info,
          .status-item p {
            font-size: 12px;
          }
        }
        .payment-related-details {
          & > div {
            background-color: $white;
            padding: 15px;
            border-radius: 8px;
            display: flex;
            flex: 1 0;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            p.info {
              font-size: 14px;
              margin-bottom: 5px;
            }

            &.expanded-row-order-details {
              & > div {
                width: 100%;
                &:first-child {
                  margin-bottom: 10px;
                  img {
                    width: 50px;
                    height: 50px;
                    object-position: center;
                    object-fit: contain;
                    margin-right: 20px;
                  }
                }
              }
              div.items-list {
                & > div {
                  * {
                    width: fit-content;
                  }
                }
                .expanded-row-details-item {
                  img {
                    width: 70px;
                    height: 70px;
                    object-position: center;
                    object-fit: contain;
                    margin-right: 20px;
                  }
                }
              }
            }
            &.expanded-row-label-details {
              & > div {
                width: 100%;
                &:first-child {
                  margin-bottom: 10px;
                  display: grid;
                  grid-template-columns: auto auto;
                  *:nth-child(2n) {
                    text-align: right;
                  }
                }
              }
              div.items-list {
                display: grid;
                grid-template-columns: auto auto;
                *:nth-child(2n) {
                  text-align: right;
                }
                a {
                  color: $green;
                  &:hover {
                    text-decoration: none;
                    font-weight: 300;
                    color: $hover_green;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
