@import "mixins";
@import "variables";
section.testimonials {
  background-color: $white;
  padding: 0px;
  padding-top: $space-6xl-11xl;
  text-align: center;
  overflow: hidden;

  & > h1 {
    margin-bottom: $space-s-m;
  }

  & > p {
    margin-bottom: 60px;
    width: clamp(18.38rem, calc(15.91rem + 12.32vw), 27rem);
  }

  .testimonial-item {
    box-shadow: 0px 15px 30px rgba($light-grey, 0.6);
    background-color: $white;
    text-align: start;
    width: 100%;
    max-width: 360px;
    padding: 32px 24px;
    margin: 12px;
    border-radius: 8px;
    height: fit-content;
    .review {
      margin-bottom: 15px;
      .info {
        display: none;
      }
    }
    .title {
      font-weight: bold;
    }
    .comment {
      margin-bottom: 24px;
    }
    .profile {
      h5 {
        font-size: 14px;
        margin-bottom: 0px;
      }
      p {
        margin-bottom: 0px;
      }

      .profile-img {
        background-color: rgba($green, 0.3);
        border-radius: 50%;
        padding: 5px;
        color: $green;
        border: $green 1px solid;
      }
    }
  }

  .carousel {
    width: 100%;
    height: fit-content;
    position: relative;
    overflow: hidden;
    .carousel-list {
      // display: grid;
      // grid-auto-flow: column;
      // grid-template-columns: 28% 40% 28%;
      // grid-template-rows: auto 1fr;
      // grid-auto-rows: min-content;
      // column-gap: 2%;
      height: 1000px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .testimonial-item {
        width: 30%;
        max-width: none;
        box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.06);
      }
      .testimonial-item:nth-child(3),
      .testimonial-item:nth-child(4) {
        width: 40%;
      }
    }
  }

  .carousel-mob {
    width: 100%;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    .carousel-list {
      padding-bottom: 25px;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      flex-wrap: nowrap;
      scroll-snap-type: x mandatory;
      position: relative;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .testimonial-item {
        scroll-snap-align: center;
        scroll-snap-stop: always;
        &.show {
        }
      }
    }
  }
}
