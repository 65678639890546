@import "variables";
@import "mixins";

* {
  scroll-behavior: smooth !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family:
    "Public Sans", "Roboto", "Lato", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 100%;
  word-wrap: normal !important;
  word-break: normal !important;

  scroll-margin: 35px;

  ::-webkit-scrollbar {
    width: 6px;
    height: 100%;
  }

  ::-webkit-scrollbar-track {
    background-color: $blue;
    transition: background-color linear 300ms;
    border-radius: 5px;

    &:hover {
      background-color: $dark-grey;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: $green;
    transition: background-color linear 300ms;
    border-radius: 5px;
  }
}

body {
  min-width: 320px;
  margin: auto;
  //overflow: hidden;
  position: relative;
}

h1 {
  font-weight: bold;
  font-size: $step-5;
}
h2 {
  font-weight: bold;
  font-size: $step-4;
}
h1,
h2 {
  @include mQ(992px) {
    font-weight: 800;
  }
}
h3 {
  font-weight: bold;
  font-size: $step-3;
}
h4 {
  font-weight: 500;
  font-size: $step-2;
}
h5 {
  font-weight: 500;
  font-size: $step-1;
}
p {
  font-size: $step-0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family:
    "Public Sans", "Roboto", "Lato", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 100%;
  font-size: 16px;

  @include mQ(992px) {
    font-size: 14px;
  }
}

h1 {
  font-size: 40px;

  @include mQ(992px) {
    font-size: 27px;
  }
}

h2 {
  font-size: 32px;

  @include mQ(992px) {
    font-size: 24px;
  }
}

h3 {
  font-size: 28px;

  @include mQ(992px) {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;

  @include mQ(992px) {
    font-size: 18px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 120%;
  font-weight: normal;
}

h1,
h2 {
  font-weight: normal;
}

p,
section,
article {
  font-size: 16px;
  line-height: 150%;

  @include mQ(992px) {
    font-size: 14px;
  }
}

a {
  @include link;
  cursor: pointer;
}

button {
  @include buttonMain;

  &:hover {
    outline: none;
  }

  &:disabled,
  &[disabled] {
    background-color: $grey !important;
  }

  &.anchor {
    background: none;
    border: none;
    color: $green;
    font-weight: normal;
    height: unset !important;
    &:hover {
      background-color: rgba($green, 0.1);
      border-radius: 8px;
    }

    &:disabled,
    &[disabled] {
      color: $grey !important;
    }
  }

  &.secondary {
    @include buttonBorder;
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="combobox"],
input[type="date"],
select,
input.form-control,
textarea {
  @include textBox;
}

input[type="checkbox"] {
  display: flex;
  flex-direction: row-reverse;
  width: 20px;
  height: 20px;
}

ul {
  list-style: none;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  @include mQ(992px) {
    font-size: 14px;
  }
}

.svg-pi-wrapper {
  position: relative;
}

.svg-pi {
  transform: rotate(-90deg); /* Fix the orientation */
}

/* Animated spinner version */
.svg-pi-indicator--spinner {
  animation: spinner 0.75s linear infinite;
  transform-origin: center;
}

.svg-pi-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  * {
    transition: 0.3s all;
  }
}

.svg-pi-label__loading {
  opacity: 0.5;
  font-size: 0.75em;
}

.svg-pi-label__progress {
  font-size: 1.5em;
  font-weight: bold;
}

.svg-pi-label__loading,
.svg-pi-label__progress {
  display: block;
}

/* Spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

span.tooltip-icon {
  margin-top: 0 !important;
  margin-bottom: 0;
  svg {
    margin-left: 5px;
    stroke: $text-grey !important;
  }
}

.search-action {
  background-color: $bg-grey;
  border-radius: 10px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 15px;

  input {
    background: none;
    border: none;
    display: flex;
    order: 2;
    transition: width 200ms;
    margin-bottom: 0 !important;

    &::placeholder {
      color: $grey;
    }

    &:focus {
      border: none !important;
    }
  }

  svg {
    stroke: $dark-grey;
    background: none;
    opacity: 0.5;
    display: flex;
    transition: width 200ms;
    cursor: pointer;
    z-index: 1050;
    padding: 0;
    width: 25px;
    &.search-icon {
      order: 1;
    }
    &.clear-icon {
      order: 3;
      width: 0;
      opacity: 0;
      &.active {
        width: auto;
        opacity: 0.5;
      }
      &:hover {
        background-color: rgba($dark-grey, 0.1);
        border-radius: 50%;
        padding: 3px;
      }
    }
  }

  input:focus ~ svg {
    &.search-icon {
      width: 0;
      margin: 0;
      opacity: 0;
    }
  }
}

.checkbox {
  margin: 15px 0px;
  cursor: pointer;
  svg {
    opacity: 0;
    stroke: $white;
    margin: 0px;
    transition: 0.2s all;
  }
  p {
    font-weight: 400;
  }

  .check {
    width: 20px;
    height: 20px;
    border: 1px solid $grey;
    border-radius: 3px;
    margin: 0px 5px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all;

    &:hover {
      border-color: $dark_grey;
    }
  }

  &.active {
    .check {
      background: $green;
      border: none;
    }
    svg {
      opacity: 1;
    }
  }

  &.disabled {
    .check {
      background-color: $light-grey;
      border: 1px solid $grey;
      svg {
        display: none;
      }
    }
    p {
      color: $grey;
    }
  }
}

.radio-item {
  .check {
    min-width: 20px;
    min-height: 20px;
    margin-top: 3px;
    border-radius: 50%;
    border: 1px solid $light-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
  &.active {
    .check {
      background-color: $green;
      div {
        background-color: $white;
      }
    }
  }
}

.status-item {
  width: fit-content;
  height: fit-content;
  padding: 3px 10px;
  border-radius: 80px;
  color: $black;
  p {
    padding: 0 !important;
    margin-bottom: 4px;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
  }
  .circle {
    display: none;
    border-radius: 50%;
    margin-right: 10px;
  }

  &.bordered {
    color: $text-grey;
    border-width: 1px;
    border-style: solid;
    border-color: $grey;

    &.circle {
      .circle {
        display: block;
        width: 0.6em;
        height: 0.6em;
      }
    }
  }

  &.circle {
    .circle {
      background-color: $grey;
      display: block;
      width: 0.65em;
      height: 0.65em;
    }
  }

  &.background {
    color: $text-grey;
    background-color: rgba($grey, 0.2);
  }

  &.success {
    &.background {
      color: #2b673e;
      background-color: #f2f9f2;
    }
    &.bordered {
      color: $green;
      border-color: $green;
    }
    .circle {
      background-color: $green;
    }
  }

  &.warning {
    &.background {
      color: #947017;
      background-color: #fff5e0;
    }
    &.bordered {
      color: $yellow-2;
      border-color: $yellow-2;
    }
    .circle {
      background-color: $yellow-2;
    }
  }

  &.error {
    &.background {
      color: $red;
      background-color: rgba($red, 0.1);
    }
    &.bordered {
      color: $red;
      border-color: $red;
    }
    .circle {
      background-color: $red;
    }
  }

  &.blue {
    &.background {
      color: $blue-2;
      background-color: $light-blue-3;
    }
    &.bordered {
      color: $blue-2;
      border-color: $blue-2;
    }
    .circle {
      background-color: $blue-2;
    }
  }

  &.orange {
    &.background {
      color: rgba(152, 88, 28, 1);
      background-color: rgba(255, 243, 226, 1);
    }
    &.bordered {
      color: rgba(152, 88, 28, 1);
      border-color: rgba(152, 88, 28, 1);
    }
    .circle {
      background-color: rgba(152, 88, 28, 1);
    }
  }

  &.bordered.circle,
  &.background.circle {
    p {
      font-size: 14px;
    }
  }
}
