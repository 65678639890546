// Variables
$primary-color: #3b82f6;
$primary-hover: #2563eb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-800: #1f2937;
$white: #fff;

.chat-container {
  display: flex;
  height: 80vh;
  max-height: 100vh;
  background-color: $gray-100;

  // Conversations sidebar
  .chatsidebar {
    width: 33.333%;
    border-right: 1px solid $gray-200;
    background-color: $white;

    &__header {
      height: 10%;
      padding: 1rem;
      border-bottom: 1px solid $gray-200;

      h2 {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }

    &__list {
      height: 90%;
      overflow-y: scroll;
    }
  }

  .conversation-item {
    padding: 1rem;
    border-bottom: 1px solid $gray-200;
    cursor: pointer;

    &:hover {
      background-color: lighten($gray-100, 2%);
    }

    &--active {
      background-color: lighten($primary-color, 35%);
    }

    &__content {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      svg {
        width: 2rem;
        height: 2rem;
        color: $gray-400;
      }
    }

    &__details {
      flex: 1;

      p {
        font-size: 0.875rem;
        color: $gray-500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__time {
      font-size: 0.75rem;
      color: $gray-400;
    }
  }

  // Main chat area
  .chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__header {
      padding: 1rem;
      border-bottom: 1px solid $gray-200;
      background-color: $white;

      h2 {
        font-size: 1.25rem;
        font-weight: 600;
      }
    }

    &__messages {
      flex: 1;
      overflow-y: auto;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__empty {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-500;
    }

    &__input-container {
      padding: 1rem;
      border-top: 1px solid $gray-200;
      background-color: $white;

      form {
        display: flex;
        gap: 0.5rem;
      }

      input {
        flex: 1;
        padding: 0.5rem;
        border: 1px solid $gray-200;
        border-radius: 0.5rem;

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
        }
      }

      button {
        padding: 0.5rem;
        background-color: $primary-color;
        color: $white;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $primary-hover;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  // Message bubbles
  .message {
    display: flex;

    &--sent {
      justify-content: flex-end;
    }

    &--received {
      justify-content: flex-start;
    }

    &__bubble {
      max-width: 70%;
      padding: 0.75rem;
      border-radius: 0.5rem;

      &--sent {
        background-color: $primary-color;
        color: $white;
        border-bottom-right-radius: 0;

        .message__time {
          color: rgba($white, 0.7);
        }
      }

      &--received {
        background-color: $gray-200;
        color: $gray-800;
        border-bottom-left-radius: 0;

        .message__time {
          color: $gray-500;
        }
      }
    }

    &__time {
      font-size: 0.75rem;
      margin-top: 0.25rem;
    }
  }
}

// Scrollbar styling
.chat-container {
  * {
    scrollbar-width: thin;
    scrollbar-color: $gray-400 transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray-400;
      border-radius: 3px;
    }
  }
}
