@import "variables";
@import "mixins";

.marginals {
  &.head {
    background-color: $beige;

    &.global,
    &.home {
      // position: relative;
      // background-color: $white;
      // padding: 25px 0;
    }
    .account-initials {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 30px;
      min-height: 30px;
      background-color: $light-blue;
      border-radius: 50%;
      border: 2px solid $green;
      color: $green;
      padding: 5px;
      &:hover {
        background-color: $green;
        color: $light-blue;
      }
    }
    &.home a {
      color: rgb(124, 124, 124);
      // font-weight: normal;
    }
    &.global {
      .headerButton {
        padding: 10px 20px;
        background-color: $green;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        text-decoration: none;
        &:hover {
          background-color: $hover-green;
        }
      }
    }
    img {
      max-width: 250px;
    }
    ul {
      & * {
        height: 35px;
        //
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include mQ-min(1200px) {
          height: 40px;
          font-size: clamp(14px, 1vw, 18px);
        }
      }
      li {
        font-weight: normal;
        color: $black;
      }

      a {
        font-weight: lighter;
        text-decoration: none;
        @include mQ(1200px) {
          width: 100%;
          margin: 5px 0;
        }
        &:hover {
          li {
            font-weight: bold;
          }
        }
        // font-size: 16px;
        // @include mQ(992px) {
        // 	font-size: 14px;
        // }
        &:hover,
        &:active {
          color: inherit;
        }
      }
      .headerButton {
        border-radius: 5px;
        font-weight: bold;
        // @include buttonMain;
        @include mQ-min(1200px) {
          width: 10vw;
          max-width: 150px;
        }
        &:hover {
          background-color: $hover-green;
          color: $white;
        }
      }
      .download {
        background-color: $green;
        color: $white;
        li {
          color: $white;
        }
        &:hover {
          background-color: $hover-green;
          color: $white;
        }
      }
      .logIn {
        border: 2px solid $black;

        &:hover {
          background-color: $hover-green;
          border: 0;
          li {
            color: $white;
          }
        }
      }
    }
    .show {
      display: flex;
      width: 50%;
      float: right;
    }
    .collapse {
      // background-color: $green;
      padding: 0px;
      padding-bottom: 50px;
      @include mQ-min(1200px) {
        padding-bottom: 0px;
      }
      // width: auto;
      // @include mQ-min(1200px){
      // 	max-width: 1000px ;
      // }
    }
    button,
    a {
      border-radius: 12px;
      font-weight: normal;
    }
  }

  &.foot {
    * {
      color: white;
    }
    .copyrights {
      font-size: 12px;
    }
    background-color: $green;

    .socials {
      li {
        margin: auto 3%;
        color: red;
        stroke: red;
      }
      img {
        height: 32px;
        width: auto;
        &:hover {
          height: 38;
          background-color: $hover-green;
          border-bottom: 1px;
          border-radius: 25px;
          padding: 3px;
        }
      }
    }
  }

  button {
    height: 40px;
  }
}
